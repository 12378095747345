import { css } from "@emotion/react";
import { Column } from "primereact/column";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { Menu } from "primereact/menu";
import { createContext, useEffect, useRef, useState } from "react";
import { workspace, Filter } from "../../../assets";
import AddWorkspace from "./addWorkspace";
import { DataTableProps } from "primereact/datatable";
import { Button, DataTable, InputText } from "../..";
import WorkspaceService from "../../../services/WorkspaceService";
import { Toast } from "primereact/toast";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setOnSetShouldUpdate } from "../../../redux/slices/flowupdates";
import { AuthContextType, useAuth } from "../../../auth/auth-provider";
import {
  setModeWorkspaces,
  setSelectedWorkspaces
} from "../../../redux/slices/workspaces";
import WorkspaceUserService from "../../../services/workspaceUserService";
import { Container } from "./styles";

type actionType = "create" | "update";
interface IUserWorkspaceContext {
  id: number;
  workspace: string;
  active: boolean;
}
interface IWorkspaceContext {
  id: number;
  name: string;
  toast: Toast;
  users: IUserWorkspaceContext[];
  usersCount: number;
  workspace: string;
  workspaceId: number;
}
export const WorkspaceContext = createContext<IWorkspaceContext>({});

const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);
  return debouncedValue;
};

export default () => {
  const selectedWorksPaces = useAppSelector(
    (state) => state.workspaces.selectedWorkspaces
  );
  const { findAllWorkspace, getWorkspaceById } = WorkspaceService();
  const menu = useRef<any>(null);
  const toast = useRef<Toast>(null);
  const dispatch = useAppDispatch();
  const [hideText, setHideText] = useState(true);
  const onSetShouldUpdate = useAppSelector(
    (state) => state.flowUpdates.onSetShouldUpdate
  );
  const shouldUpdate = useAppSelector(
    (state) => state.flowUpdates.shouldUpdate
  );
  // const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);
  const updateWorkspaces = useAppSelector(
    (state) => state.workspaces.updateWorkspaces
  );
  const { getUserWorkspaces2, getUserWorkspaces } = WorkspaceUserService();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const debouncedText = useDebounce(globalFilterValue, 600);
  const [visibleWorkspace, setVisibleWorkspace] = useState(false);
  const [workData, setWorkData] = useState({});
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 20,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      name: { value: "", matchMode: "contains" }
    }
  });
  const onPage = (event: never) => {
    setLazyParams(event);
  };

  const { user, company } = useAuth() as AuthContextType;

  // Fetching Data from endpoint to list workspace=================>>
  const [tablePropsData, setTablePropsData] = useState<DataTableProps>({});
  const effectRan = useRef(false);
  const fetch = async () => {
    try {
      const data = await findAllWorkspace({
        offset: lazyParams.first,
        limit: lazyParams.rows,
        name: debouncedText || "",
        companyId: user.admin ? company.id : user.companyId
      });
      const tableProps = {
        first: lazyParams.first,
        lazy: true,
        paginator: true,
        rows: lazyParams.rows,
        onPage,
        totalRecords: data.total,
        value: data.result
      };
      setTablePropsData(tableProps);
    } catch (error) {
      toast?.current?.show({
        severity: "error",
        summary: "Error Message",
        detail:
          "Não foi possível buscar os dados de Workspaces neste momento. Tente mais tarde.",
        life: 3000
      });
    }
  };

  useEffect(() => {
    if (effectRan.current) {
      fetch();
    }
    return () => {
      effectRan.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    lazyParams,
    shouldUpdate,
    debouncedText,
    selectedWorksPaces,
    updateWorkspaces
  ]);

  useEffect(() => {
    fetch();
  }, [updateWorkspaces]);

  const modelData = [
    { label: "Visualizar" },
    { label: "Ativo" },
    { label: "Inativo" },
    { label: "Transferir" },
    { label: "Remover" }
  ];

  const getWorkspace = async (id: number) => {
    const workspace = await getWorkspaceById(id);
    workspace.toast = toast;
    setWorkData(workspace);
    if (workspace) {
      setVisibleWorkspace(true);
    } else {
      setVisibleWorkspace(false);
    }
  };

  const editWorkspace = (rowData: { id: number }) => {
    getWorkspace(rowData.id);
    dispatch(setModeWorkspaces("edit"));
  };

  const hideDialog = (status = null) => {
    if (status) {
      // setShouldUpdate((prevCheck) => !prevCheck);
      dispatch(setOnSetShouldUpdate(""));
    }
    setVisibleWorkspace(false);
  };
  useEffect(() => {
    if (onSetShouldUpdate === "success") {
      toast?.current?.show({
        severity: "success",
        summary: "Success Message",
        detail: "Workspace adicionado com sucesso!!",
        life: 3000
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdate]);

  const inputStyles = css`
    display: flex;
    width: 342px;

    @media screen and (max-width: 849px) {
      width: 100%;
    }
  `;

  const styleButtons = css`
    @media screen and (max-width: 849px) {
      width: 342px;
      justify-content: space-between;
    }

    @media screen and (max-width: 494px) {
      justify-content: flex-start;
    }
  `;

  useEffect(() => {
    const screenResolution = () => {
      const widthScreen = window.innerWidth;
      setHideText(widthScreen > 494);
    };
    screenResolution();
    window.addEventListener("resize", screenResolution);

    return () => {
      window.removeEventListener("resize", screenResolution);
    };
  }, []);

  const paginatorCustomTemplate = {
    layout: "RowsPerPageDropdown PrevPageLink PageLinks NextPageLink",
    RowsPerPageDropdown: (options: {
      value: number;
      onChange: ((e: DropdownChangeParams) => void) | undefined;
    }) => {
      const dropdownOptions = [
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: 100, value: 100 }
      ];

      return (
        <div
          style={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <span
            style={{
              minWidth: "fit-content"
            }}
          >
            Por página:
          </span>

          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
            css={css`
              .p-inputtext {
                color: #6e6b7b;
              }
            `}
          />
        </div>
      );
    }
  };

  return (
    <Container>
      <Toast ref={toast} />
      <div className="p-4">
        <div className="align-items-center flex gap-2">
          <img src={workspace} />
          <div className="text-xl font-bold" style={{ color: "#0a043c" }}>
            Workspaces
          </div>
        </div>

        <div className="mt-2">
          Gerencie o seu workspace, realocando equipe, bots ou editando como
          desejar.
        </div>

        <div className="mt-2">
          <div
            style={{
              borderRadius: "10px 10px 0px 0px",
              padding: "8px 12px",
              background: "white"
            }}
          >
            <div className="flex gap-2 " style={{ flexWrap: "wrap" }}>
              <span className="p-input-icon-left" css={inputStyles}>
                <i className="pi pi-search" />

                <InputText
                  value={globalFilterValue}
                  onChange={(e) => setGlobalFilterValue(e.target.value)}
                  id="name"
                  placeholder="Buscar"
                />
              </span>
            </div>
          </div>

          <DataTable
            {...tablePropsData}
            filterDelay={600}
            emptyMessage="Nenhum resultado encontrado"
            paginatorTemplate={paginatorCustomTemplate}
          >
            <Column field="id" header="Id" headerStyle={{ width: "7%" }} />

            <Column field="name" header="Nome" />

            <Column
              field="users"
              header="Equipe"
              align="center"
              alignHeader="center"
              headerStyle={{ width: "10%" }}
              body={(rowData) => (
                <div className="flex justify-content-center">
                  <div
                    className="flex justify-content-center align-items-center w-3rem p-1 text-xs"
                    css={css`
                      background: #eaeaea;
                      border-radius: 50px;
                    `}
                  >
                    {rowData.users}
                  </div>
                </div>
              )}
            />

            <Column
              field="active"
              header="Ativo"
              align="center"
              alignHeader="center"
              headerStyle={{ width: "10%" }}
              body={(rowData) => (
                <div className="flex justify-content-center">
                  {rowData.active ? (
                    <div
                      className="flex justify-content-center text-xs align-items-center p-1 w-3rem"
                      css={css`
                        background: #aee9d1;
                        border-radius: 50px;
                      `}
                    >
                      Ativo
                    </div>
                  ) : (
                    <div
                      className="p-1 text-xs"
                      css={css`
                        background: #fa4d64;
                        border-radius: 50px;
                      `}
                    >
                      Inativo
                    </div>
                  )}
                </div>
              )}
            />

            <Column
              align="center"
              alignHeader="center"
              headerStyle={{ width: "12%" }}
              body={(rowData) => (
                <div
                  css={css`
                    align-items: center;
                    display: flex;
                    gap: 10px;
                    justify-content: center;
                  `}
                >
                  <Button
                    label="Editar"
                    className="p-button-outlined"
                    css={css`
                      font-size: 12px;
                      padding: 4px 12px;
                    `}
                    onClick={() => editWorkspace(rowData)}
                  />

                  <Menu
                    model={modelData}
                    popup={true}
                    ref={menu}
                    id="popup_menu"
                    css={css`
                      font-size: 12px;
                    `}
                  />
                </div>
              )}
            />
          </DataTable>

          <WorkspaceContext.Provider value={workData}>
            <AddWorkspace
              displayWorkspace={visibleWorkspace}
              onHide={(e) => hideDialog(e)}
            />
          </WorkspaceContext.Provider>
        </div>
      </div>
    </Container>
  );
};
