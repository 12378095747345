import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  ButtonContainer,
  ContainerLeftModal,
  ContainerRightModal,
  InputContainer,
  LeftHeaderWhiter
} from "./styles";
import { Dialog } from "primereact/dialog";
import { setModalCreateTemplate } from "../../../../redux/slices/flowInteractions";
import { Text } from "../../../Text";
import { botRed, girl } from "../../../../assets";
import { useEffect, useState } from "react";
import Button from "../../../button";
import TemplatesService from "../../../../services/TemplatesService";
import { useToast } from "../../../toast-provider";
import { TreeSelect, TreeSelectProps } from "primereact/treeselect";
import { AuthContextType, useAuth } from "../../../../auth/auth-provider";
import { Controller, useForm } from "react-hook-form";
import { v } from "../../../../util";
import { zodResolver } from "@hookform/resolvers/zod";
import { InputText } from "primereact/inputtext";
import { useParams } from "react-router-dom";

interface IFormCreateTemplate {
  templateName: string;
  workspaces: number[];
}
interface IModalCreateTemplate {
  updateTemplateList: () => Promise<void>;
}
export const ModalCreateTemplate: React.FC<IModalCreateTemplate> = ({
  updateTemplateList
}) => {
  const [templateName, setTemplateName] = useState("");
  const { createTemplate } = TemplatesService();
  const [selectedWorkspaces, setSelectedWorkspaces] = useState<number[]>([]);
  const dispatch = useAppDispatch();
  const openModalCreateTemplate = useAppSelector(
    (state) => state.flowInteractions.modalCreateTemplate
  );
  const { botId, flowId } = useParams();

  const [nodes, setNodes] = useState<TreeSelectProps | undefined>(undefined);

  const [selectedNodeKeys, setSelectedNodeKeys] = useState<{
    [k: string]: number;
  } | null>(null);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<IFormCreateTemplate>({
    defaultValues: {
      templateName: "",
      workspaces: []
    },
    values: {
      templateName,
      workspaces: selectedWorkspaces
    },
    resolver: zodResolver(
      v.object({
        templateName: v.string().superRefine(v.nonempty()),
        workspaces: v.array(v.number()).min(1)
      })
    )
  });
  const { user } = useAuth() as AuthContextType;
  const toast = useToast();

  useEffect(() => {
    if (user?.workspaces?.workspaces?.length) {
      const selected = user?.workspaces?.workspaces.map(
        (_ws: { id: number }) => {
          // setSelectedNodeKeys(_ws.id)
          return [[_ws.id], { checked: true, partialChecked: false }];
        }
      );
      const objectSelected = Object.fromEntries(selected);
      if (user?.workspaces?.workspacesTree?.length) {
        setNodes(user.workspaces.workspacesTree as unknown as TreeSelectProps);
        const selectedNodeKeys = Array?.from(
          Object?.keys(objectSelected as { [k: string]: any })
        ).map((el) => Number(el));
        setSelectedNodeKeys(objectSelected);
        setSelectedWorkspaces(
          Object.keys(objectSelected).map((el) => Number(el))
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  const handleSalvar = handleSubmit(async (data) => {
    try {
      await createTemplate({
        botFlowId: Number(flowId),
        botId: Number(botId),
        name: data.templateName,
        workspaces: data.workspaces
      });
      reset();
      updateTemplateList();
      dispatch(setModalCreateTemplate(false));
      toast.current?.show({
        life: 5000,
        severity: "success",
        summary: "Sucesso",
        detail: "Template criado com sucesso."
      });
    } catch (error: any) {
      toast?.current?.show({
        severity: "error",
        summary: "Error Message",
        detail: error.message,
        life: 3000
      });
    }
  });
  const handleChange = (e: any) => {
    setSelectedNodeKeys(e.value);
    setSelectedWorkspaces(Object.keys(e.value).map((el) => Number(el)));
  };
  const onChangeTemplateName = (name: string) => setTemplateName(name);

  useEffect(() => {
    if (!openModalCreateTemplate) {
      setTemplateName("");
      setSelectedNodeKeys(null);
    }
  }, [openModalCreateTemplate]);

  return (
    <Dialog
      className="addWspaceDialog"
      style={{ border: "none" }}
      headerStyle={{
        position: "relative",
        borderTopLeftRadius: "1rem",
        borderTopRightRadius: "1rem",
        border: "none",
        height: "3rem",
        backgroundColor: user.plataform.mainColor || "#ff0025"
      }}
      visible={openModalCreateTemplate}
      contentStyle={{
        padding: 0,
        width: "60rem",
        height: "30rem",
        borderBottomLeftRadius: "1rem",
        borderBottomRightRadius: "1rem",
        border: "none",
        overflow: "hidden"
      }}
      onHide={() => dispatch(setModalCreateTemplate(false))}
      closeOnEscape={true}
      resizable={false}
      closable={true}
      keepInViewport={false}
      draggable={false}
      // contentStyle={{ display: "flex", }}
      // headerStyle={headerStyle}
    >
      <LeftHeaderWhiter />
      <div className="flex h-full flex-end">
        <ContainerLeftModal>
          <div className="flex mb-3 mt-2 align-items-center justify-content-center">
            <div className="flex flex-column align-items-center justify-content-center">
              <img
                src={user.plataform.urlLogo ? user.plataform.urlLogo : botRed}
                style={{
                  maxWidth: "65px",
                  maxHeight: "65px",
                  marginBottom: "5px"
                }}
              />
              <div>
                <Text s="15px" lh="20px" weight={500} color="#0A043C">
                  Template
                </Text>
              </div>
            </div>
          </div>
          <div className="ml-4">
            <Text
              style={{ textAlign: "left" }}
              s="12px"
              lh="20px"
              weight={300}
              color="#0A043C"
            >
              Crie scripts de forma eficiente e personalizada, potencializando
              seu processo de automação.
            </Text>
          </div>
          <form onSubmit={handleSalvar}>
            <InputContainer>
              <Controller
                name="templateName"
                control={control}
                render={({ field }) => (
                  <span className="p-float-label">
                    <InputText
                      {...field}
                      id="templateName"
                      value={templateName}
                      onChange={(e) => onChangeTemplateName(e.target.value)}
                      // placeholder="Nome do Template"
                      // css={comunCss.noBorder}
                      style={{
                        width: "100%"
                      }}
                      className="flex"
                    />
                    <label htmlFor="templateName">Nome do Template</label>
                  </span>
                )}
              />
              {errors.templateName?.message ? (
                <div style={{ color: "#AA3939", marginTop: "0.40rem" }}>
                  {errors.templateName.message}
                </div>
              ) : null}
            </InputContainer>
            <div
              style={{
                display: "flex",
                padding: "0 1.8rem",
                width: "100%"
              }}
            >
              <TreeSelect
                style={{
                  width: "100%",
                  maxWidth: "520px"
                }}
                className="flex"
                value={selectedNodeKeys}
                options={nodes as TreeSelectProps}
                selectionMode="checkbox"
                placeholder="Selecione a workspace"
                onChange={(e) => handleChange(e)}
                filter={true}
              />
            </div>
            <div style={{ width: "90%", margin: "0 auto" }}>
              {errors.workspaces?.message ? (
                <div style={{ color: "#AA3939" }}>
                  {errors.workspaces.message}
                </div>
              ) : null}
            </div>
            <div
              className="flex bg-white justify-content-start flex-column p-2 mt-4"
              // style={{
              //   borderTop: "1px solid #EEE"
              // }}
            ></div>
            <ButtonContainer>
              <Button
                type="submit"
                style={{
                  width: "120px",
                  display: "flex",
                  justifyContent: "center",
                  border: "none",
                  background: user.plataform.mainColor
                    ? user.plataform.mainColor
                    : " #046af3"
                }}
              >
                Salvar
              </Button>
            </ButtonContainer>
          </form>
        </ContainerLeftModal>
        <ContainerRightModal
          style={{ backgroundColor: user.plataform.mainColor || "#ff0025" }}
        >
          <div className="flex align-items-center justify-content-center">
            <div className="flex flex-column h-full align-items-center justify-content-start ml-3 gap-4">
              {user.plataform.urlAvatar ? (
                <img
                  width="80px"
                  src={user.plataform.urlAvatar}
                  alt="girl-image"
                />
              ) : (
                <img width="120px" src={girl} alt="girl-image" />
              )}
              <Text s="1rem" lh="20px" weight={500} color="#FFF">
                Dica para Templates
              </Text>
              <Text
                style={{ textAlign: "left" }}
                s="0.75rem"
                lh="20px"
                weight={300}
                color="#FFF"
              >
                Ao criar templates de scripts para robôs, concentre-se na
                modularidade e flexibilidade. Desenvolva modelos que possam ser
                facilmente adaptados a diferentes contextos, permitindo uma
                personalização eficiente.
              </Text>
            </div>
          </div>
        </ContainerRightModal>
      </div>
    </Dialog>
  );
};
