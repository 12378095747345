import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const ContainerLeftModal = styled.div`
  width: 59%;
  position: relative;
  height: 100%;
  top: 0px;
  content: none;
  background-color: white;
  padding: 0 0 1rem 1rem;
`;
export const ContainerRightModal = styled.div`
  width: 41%;
  position: relative;
  height: 100%;
  top: 0px;
  content: none;
  padding: 1rem;
  display: flex;
  align-items: start;
  justify-content: space-between;
`;

interface IButtonReload {
  rotateIcon: boolean;
}
export const ButtonReload = styled.img<IButtonReload>`
  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  -webkit-animation: ${(props) =>
    props.rotateIcon ? "2s linear infinite" : ""};
  -moz-animation: ${(props) =>
    props.rotateIcon ? "rotating 2s linear infinite" : ""};
  -ms-animation: ${(props) =>
    props.rotateIcon ? "rotating 2s linear infinite" : ""};
  -o-animation: ${(props) =>
    props.rotateIcon ? "rotating 2s linear infinite" : ""};
  animation: ${(props) =>
    props.rotateIcon ? "rotating 2s linear infinite" : ""};
  &:hover {
    cursor: pointer;
    transition: all ease-in-out 250ms;
  }
`;

export const ButtonReloadDiv = css`
  &:hover {
    cursor: pointer;
    transition: all ease-in-out 250ms;
  }
`;
