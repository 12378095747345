const map = {
  "app.imbot.com.br": {
    displayName: "imbot",
    urlLogo: "",
    mainColor: ""
  },
  "stg.app.imbot.com.br": {
    displayName: "imbot",
    urlLogo: "",
    mainColor: ""
  },
  "app.spaik.com.br": {
    displayName: "Spaik",
    urlLogo:
      "https://axervkf4lntv.objectstorage.sa-vinhedo-1.oci.customer-oci.com/p/8u3fudnZeR3C0zoo9K3wq65hlx_ooJW3Jb5VIUf6_pg5EuU9M_e47gtJpu47NrxC/n/axervkf4lntv/b/imbot_bucket/o/spaik-logoGroup%20427318219.svg",
    mainColor: "",
    customLogin: true
  },
  "stg.app.spaik.com.br": {
    displayName: "Spaik",
    urlLogo:
      "https://axervkf4lntv.objectstorage.sa-vinhedo-1.oci.customer-oci.com/p/8u3fudnZeR3C0zoo9K3wq65hlx_ooJW3Jb5VIUf6_pg5EuU9M_e47gtJpu47NrxC/n/axervkf4lntv/b/imbot_bucket/o/spaik-logoGroup%20427318219.svg",
    mainColor: "",
    customLogin: true
  }
} as Record<
  string,
  {
    displayName: string;
    urlLogo: string;
    mainColor: string;
    customLogin?: boolean;
  }
>;

const plataform = window.location.href
  .replaceAll(/https?:\/\//gu, "")
  .replaceAll(/\/.*/gu, "");

export default map[plataform] ?? {
  displayName: "imbot",
  urlLogo: "",
  mainColor: "",
  customLogin: false
};
