import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Card = css`
  position: relative;
  width: 342px;
  height: 412px;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: end;
`;

export const ImageIcon = css`
  position: absolute;
  top: 10;
  left: 10;
`;

export const Flex = css`
  display: flex;
  justify-content: space-between;
  margin: 1rem;
`;

export const MainImageDiv = css`
  display: flex;
  align-items: center;
  justity-content: center;
  margin: 0 auto;
  width: 102px;
  height: 102px;
  border-radius: 50%;
  border: 2px solid #cdcfd5;
`;

export const MainImage = css`
  display: flex;
  align-items: center;
  justity-content: center;
  margin: 0 auto;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background-color: #f5f5f5;
`;

export const Title = css`
  margin: 1rem auto;
  margin-bottom: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #0a043c;
`;

export const BotId = css`
  margin: 0 auto;
  margin-bottom: 1rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  align-items: center;
  text-align: center;
  color: #999999;
`;

export const Divisor = css`
  height: 1px;
  width: 100%;
  background: #d9d9d9;
`;

export const FlexSB = css`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const TextSubTitle = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #6e6b7b;
`;

export const TextContent = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #0a043c;
`;

export const DivImgAtualizadoPor = css`
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.14);
  border-radius: 50%;
  width: 27px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  margin-top: 0.3rem;
`;
interface IImgAtualizadoPor {
  imgURL: string;
}
export const ImgAtualizadoPor = styled.div<IImgAtualizadoPor>`
  width: 24.5px;
  height: 24.5px;
  border-radius: 50%;
  background-image: url("${(props) => props.imgURL}");
`;
export const ButtonStyle = css`
  width: 132px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #046af3;
  border-radius: 8px;
  color: #046af3;
`;

export const DropDownArea = styled.div`
  position: absolute;
  width: 100%;
  top: 10px;
  padding: 0 15px;
  display: flex;
  gap: 0.5rem;
  flex-direction: row;
  align-items: center;
  justify-content: end;
`;
export const ButtonThreePoints = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  :hover {
    cursor: pointer;
    background: #e5e5e5;
    transition: 0.2s ease-in-out;
  }
`;
export const SelectThreePoints = styled.div`
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  padding: 0.25rem;
  width: 5rem;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  position: absolute;
  gap: 0.5rem;
  top: 30px;
  right: 15px;
`;

export const ButtonOpenModal = styled.button`
  display: flex;
  width: 100%;
  border: none;
  border-radius: 0.25rem;
  background-color: #fff;
  align-items: flex-end;
  :hover {
    cursor: pointer;
    background: #e5e5e5;
    transition: 0.2s ease-in-out;
  }
`;

export const QrCodeDiv = styled.div`
  display: flex;
`;

export const ContainerLeftModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 59%;
  position: relative;
  height: 100%;
  top: 0px;
  content: none;
  background-color: #fff;
  padding: 1rem;
  padding-top: 0rem;
`;

export const ContainerRightModal = styled.div`
  width: 41%;
  position: relative;
  height: 100%;
  top: 0px;
  content: none;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
`;

export const DivImg = styled.div`
  width: 3.5rem;
  min-width: 3.5rem;
  height: 3.5rem;
  min-height: 3.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d4e2f3;
`;

export const Img = styled.img`
  width: 5rem;
  height: 5rem;
  margin: 0 auto;
  margin-bottom: 1rem;
  max-height: 100%;
  max-width: 100%;
`;
