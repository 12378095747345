import { css } from "@emotion/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { AutoComplete } from "primereact/autocomplete";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AuthContextType, useAuth } from "../../auth/auth-provider";
import { Button } from "../../components";
import useCompany from "../../use-company";
import { v } from "../../util";
import { useNavigate } from "react-router-dom";
// import WorkspaceService from "../services/WorkspaceService";
import { useAppDispatch } from "../../redux/hooks";
import { setRWorkspaces } from "../../redux/slices/workspaces";
import WorkspaceUserService from "../../services/workspaceUserService";
import { Container } from "./styles";

type FormValues = {
  company: {
    id: number;
    name: string;
  };
};

const schema = v.object({
  company: v.object({
    id: v.number(),
    name: v.string()
  })
});

const ChangeCompany = () => {
  const [items, setItems] = useState([]);
  const { company, setCompany } = useAuth() as AuthContextType;
  const redirect = useNavigate();
  const authContext = useAuth() as AuthContextType;
  const dispatch = useAppDispatch();
  const [currentPathname] = useState(window.location.pathname);
  const [mobile, setMobile] = useState(false);
  const { user } = useAuth() as AuthContextType;

  const { findAll } = useCompany();
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>({
    defaultValues: {
      company: undefined
    },
    values: {
      company
    },
    resolver: zodResolver(schema)
  });
  // const { findAllWorkspace } = WorkspaceService();
  const { getUserWorkspaces2 } = WorkspaceUserService();

  useEffect(() => {
    const handleResize = () => {
      let size = window.innerWidth;
      if (size <= 900) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <form
      onSubmit={handleSubmit(async (data) => {
        setCompany(data.company);
        localStorage.setItem("company", JSON.stringify(data.company));
        if (authContext.user.admin) {
          // const workspaces = await findAllWorkspace({limit: 100, offset: 0, companyId: data.company.id});
          const workspaces = await getUserWorkspaces2(data.company.id);
          authContext.setWorkspaces(workspaces);
          dispatch(setRWorkspaces(workspaces.workspaces));
        }
        if (mobile) {
          redirect(`/rooms`);
        } else {
          redirect(`/`);
        }
      })}
      className="flex flex-row gap-2"
      style={{ height: "2.8rem", margin: "0 1rem" }}
    >
      <Container
        buttonColor={
          user.plataform.mainColor ? user.plataform.mainColor : "#046af3"
        }
      >
        <div
          className="p-float-label"
          style={{ display: "flex", height: "100%" }}
        >
          <Controller
            name="company"
            control={control}
            render={({ field }) => (
              <AutoComplete
                id="company"
                value={field.value}
                onChange={field.onChange}
                inputRef={field.ref}
                suggestions={items}
                forceSelection={true}
                dropdown={true}
                field="name"
                delay={1000}
                completeMethod={async (event) => {
                  const { result } = await findAll({
                    limit: 20,
                    offset: 0,
                    name: event.query
                  });

                  setItems(result);
                }}
              />
            )}
          />
          <label htmlFor="organization">Nome da empresa</label>
        </div>
        {errors.company?.message ? (
          <div
            css={css`
              margin-top: 5px;
              font-size: 12px;
              color: #ff4d4f;
            `}
          >
            {errors.company?.message}
          </div>
        ) : null}
      </Container>

      <Button
        style={{
          borderRadius: "6px",
          border: "none",
          background: user.plataform.mainColor
            ? user.plataform.mainColor
            : "#046AF3"
        }}
        label="Salvar"
      />
    </form>
  );
};

export default ChangeCompany;
