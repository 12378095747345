import { zodResolver } from "@hookform/resolvers/zod";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { RefObject, memo, useEffect, useState } from "react";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { z } from "zod";
import {
  BluePencil,
  MinusSign,
  PlayRed,
  PlusSign,
  Webhook
} from "../../../../../assets";
import { useAuth } from "../../../../../auth/auth-provider";
import DialogPadrao from "../../../../../components/DialogPadrao";
import { useToast } from "../../../../../components/toast-provider";
import WebhookServices from "../../../../../services/webhook";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { setModalWebhook } from "../../../../../redux/slices/flowInteractions";
import { v } from "../../../../../util";
import { Text } from "../../../../Text/text";
import {
  ButtonCancelar,
  ButtonSalvar,
  CheckCard,
  ContainerButton,
  ContainerInternalButton,
  ContainerLeftModal,
  ContainerRightModal,
  ErrorMessage,
  HiddenDiv,
  IconButton,
  InputUrl,
  Label,
  LabelCard
} from "./styles";
import { IoMdPlayCircle } from "react-icons/io";

type FormData = {
  url: string;
  closeRoomUrl: string;
  callbackUrl: string;
  transferUrl: string;
  sendMessageUrl: string;
  digitalEmployeeUrl: string;
  digitalEmployeeCallbackUrl: string;
  headers: Array<{
    key: string;
    value: string;
    // Other properties
  }>;
};
interface IChannelName {
  name: string;
  img: string;
  checked: boolean;
  toastRef?: RefObject<Toast>;
}

export const ChannelWebhookModal: React.FC<IChannelName> = memo(
  ({ name, img, checked }) => {
    const {
      control,
      handleSubmit,
      register,
      setValue,
      formState: { errors }
    } = useForm<FormData>({
      resolver: zodResolver(
        z.object({
          url: v.string().superRefine(v.url()),
          transferUrl: v.string().superRefine(v.url()),
          sendMessageUrl: v.string().superRefine(v.url()),
          closeRoomUrl: v.string().superRefine(v.url()),
          callbackUrl: v.string().superRefine(v.url()),
          digitalEmployeeUrl: v.string().superRefine(v.url()),
          digitalEmployeeCallbackUrl: v.string().superRefine(v.url()),
          headers: z.array(
            z.object({
              key: v.string().superRefine(v.nonempty()),
              value: v.string().superRefine(v.nonempty())
            })
          )
        })
      )
    });

    const { user } = useAuth();

    const dispatch = useAppDispatch();
    const modalWebhook = useAppSelector(
      (state) => state.flowInteractions.modalWebhook
    );
    const { getWebhookConfig, postWebhookConfig } = WebhookServices();
    const [loading, setLoading] = useState(false);
    const { botId } = useParams();
    const toast = useToast();
    const { fields, append, remove } = useFieldArray({
      control,
      name: "headers"
    });
    const fetchData = async () => {
      try {
        const data = await getWebhookConfig(Number(botId));
        const dataHeaders = data?.headers?.length
          ? data.headers
          : [
              {
                key: "",
                value: ""
              }
            ];
        setValue("headers", dataHeaders, { shouldValidate: true });
        setValue("url", data.url, { shouldValidate: true });
        setValue("transferUrl", data.transferUrl, { shouldValidate: true });
        setValue("sendMessageUrl", data.sendMessageUrl, {
          shouldValidate: true
        });
        setValue("closeRoomUrl", data.closeRoomUrl, { shouldValidate: true });
        setValue("callbackUrl", data.callbackUrl, { shouldValidate: true });
        setValue("digitalEmployeeUrl", data.digitalEmployeeUrl, {
          shouldValidate: true
        });
        setValue(
          "digitalEmployeeCallbackUrl",
          data.digitalEmployeeCallbackUrl,
          { shouldValidate: true }
        );
      } catch (error) {
        toast?.current?.show({
          life: 3000,
          severity: "error",
          summary: "Erro.",
          detail: `Erro ao buscar Configuração de Webhook. ${
            error?.message as string
          }.`,
          sticky: false
        });
      }
    };
    const onSubmit: SubmitHandler<FormData> = async (data) => {
      // Handle your form submission
      setLoading(true);

      const headerKeys = data.headers.map((header) => header.key);
      const uniqueKeys = new Set(headerKeys);
      if (headerKeys.length !== uniqueKeys.size) {
        toast?.current?.show({
          life: 3000,
          severity: "error",
          summary: "Erro.",
          detail: "Não é possível adicionar Headers com chaves duplicadas.",
          sticky: false
        });
      }
      await postWebhookConfig(Number(botId), {
        url: data.url,
        headers: data.headers,
        transferUrl: data.transferUrl,
        sendMessageUrl: data.sendMessageUrl,
        closeRoomUrl: data.closeRoomUrl,
        callbackUrl: data.callbackUrl,
        digitalEmployeeUrl: data.digitalEmployeeUrl,
        digitalEmployeeCallbackUrl: data.digitalEmployeeCallbackUrl
      });
      setLoading(false);
      dispatch(setModalWebhook(false));
    };

    const handleUrlChange = (fieldName: string, value: string) => {
      setValue(fieldName as keyof FormData, value, { shouldValidate: true });
    };
    useEffect(() => {
      if (modalWebhook) {
        fetchData();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalWebhook]);

    const handleModalClick = (e: React.MouseEvent) => {
      e.stopPropagation();
    };
    return (
      <div>
        <div css={CheckCard} className="relative field-checkbox">
          {checked && (
            <IconButton>
              <img
                width="10px"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(setModalWebhook(true));
                }}
                src={BluePencil}
                alt="Edit Icon"
              />
            </IconButton>
          )}
          <img src={img} alt={`img-${name}`} />
          <LabelCard htmlFor={name}>{name}</LabelCard>
          <HiddenDiv>
            <Checkbox inputId={name} checked={checked} />
          </HiddenDiv>
        </div>
        <DialogPadrao
          displayDialog={modalWebhook}
          contentStyle={{ height: "420px" }}
          onHide={() => dispatch(setModalWebhook(false))}
          headerStyle={{
            backgroundColor: user.plataform.mainColor || "#ff0025"
          }}
          onClick={handleModalClick}
        >
          <ContainerLeftModal>
            <div className="flex gap-2 mb-4 align-items-center justify-content-center m-auto">
              <img src={Webhook} alt="Icon-Play-Video" />
              <Text
                fontSize="15px"
                fontWeight={600}
                lineHeight="20px"
                color="#0A043C"
              >
                Integração do WebHook
              </Text>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <InputUrl>
                <span className="p-float-label">
                  <InputText
                    {...register("url")}
                    id="URL"
                    placeholder="URL"
                    style={{ width: "100%" }}
                    onChange={(e) => handleUrlChange("url", e.target.value)}
                  />
                  <label htmlFor="URL">URL</label>
                </span>
                {errors?.url && (
                  <ErrorMessage>{errors.url?.message}</ErrorMessage>
                )}
                <span className="p-float-label mt-4">
                  <InputText
                    {...register("transferUrl")}
                    id="transferUrl"
                    style={{ width: "100%" }}
                    placeholder="URL de transferência"
                    onChange={(e) =>
                      handleUrlChange("transferUrl", e.target.value)
                    }
                  />
                  <label htmlFor="transferUrl">URL de transferência</label>
                </span>
                {errors?.transferUrl && (
                  <ErrorMessage>{errors.transferUrl?.message}</ErrorMessage>
                )}
                <span className="p-float-label mt-4">
                  <InputText
                    style={{ width: "100%" }}
                    {...register("sendMessageUrl")}
                    id="sendMessageUrl"
                    placeholder="URL de envio de Mensagem"
                    onChange={(e) =>
                      handleUrlChange("sendMessageUrl", e.target.value)
                    }
                  />
                  <label htmlFor="sendMessageUrl">
                    URL de envio de Mensagem
                  </label>
                </span>
                {errors?.sendMessageUrl && (
                  <ErrorMessage>{errors.sendMessageUrl?.message}</ErrorMessage>
                )}
                <span className="p-float-label mt-4">
                  <InputText
                    style={{ width: "100%" }}
                    {...register("closeRoomUrl")}
                    id="closeRoomUrl"
                    placeholder="URL de encerramento de sala"
                    onChange={(e) =>
                      handleUrlChange("closeRoomUrl", e.target.value)
                    }
                  />
                  <label htmlFor="closeRoomUrl">
                    URL de encerramento de sala
                  </label>
                </span>
                {errors?.closeRoomUrl && (
                  <ErrorMessage>{errors.closeRoomUrl?.message}</ErrorMessage>
                )}
                <span className="p-float-label mt-4">
                  <InputText
                    {...register("callbackUrl")}
                    id="callbackUrl"
                    placeholder="URL de Callback"
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleUrlChange("callbackUrl", e.target.value)
                    }
                  />
                  <label htmlFor="callbackUrl">URL de Callback</label>
                </span>
                {errors?.url && (
                  <ErrorMessage>{errors.url?.message}</ErrorMessage>
                )}
                <span className="p-float-label mt-4">
                  <InputText
                    {...register("digitalEmployeeUrl")}
                    id="digitalEmployeeUrl"
                    placeholder="URL de Funcionário digital"
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleUrlChange("digitalEmployeeUrl", e.target.value)
                    }
                  />
                  <label htmlFor="digitalEmployeeUrl">
                    URL de Funcionário digital
                  </label>
                </span>
                {errors?.url && (
                  <ErrorMessage>{errors.url?.message}</ErrorMessage>
                )}
                <span className="p-float-label mt-4">
                  <InputText
                    {...register("digitalEmployeeCallbackUrl")}
                    id="digitalEmployeeCallbackUrl"
                    placeholder="URL de Callback do Funcionário digital"
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleUrlChange(
                        "digitalEmployeeCallbackUrl",
                        e.target.value
                      )
                    }
                  />
                  <label htmlFor="digitalEmployeeCallbackUrl">
                    URL de Callback do Funcionário digital
                  </label>
                </span>
                {errors?.url && (
                  <ErrorMessage>{errors.url?.message}</ErrorMessage>
                )}
              </InputUrl>
              {fields.map((item, index) => (
                <div key={item.id}>
                  <Text
                    style={{ width: "100%" }}
                    fontSize="14px"
                    fontWeight={400}
                    lineHeight="20px"
                    color="#4C5862"
                  >
                    {`HEADERS${index > 0 ? index + 1 : ``}`}
                  </Text>
                  <div>
                    {index === 0 ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.5rem"
                        }}
                      >
                        <ContainerButton
                          onClick={() => append({ key: "", value: "" })}
                          color="blue"
                        >
                          <ContainerInternalButton id="addHeader" color="blue">
                            <img src={PlusSign} alt="plus signal" />
                          </ContainerInternalButton>
                        </ContainerButton>
                        <Label
                          htmlFor="addHeader"
                          color="blue"
                          style={{ marginTop: "0" }}
                        >
                          Adicionar
                        </Label>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.5rem"
                        }}
                      >
                        <ContainerButton
                          onClick={() => remove(index)}
                          color="red"
                        >
                          <ContainerInternalButton
                            id="removeHeader"
                            color="red"
                          >
                            <img src={MinusSign} alt="Minus signal" />
                          </ContainerInternalButton>
                        </ContainerButton>
                        <Label
                          htmlFor="removeHeader"
                          color="red"
                          style={{ marginTop: "0" }}
                        >
                          Remover
                        </Label>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      gap: "0.8rem",
                      padding: "0.5rem 0",
                      marginRight: "0.5rem"
                    }}
                  >
                    <InputText
                      {...register(`headers.${index}.key` as const)}
                      defaultValue={item.key}
                      style={{
                        width: "100%"
                      }}
                      placeholder="Key"
                    />
                    {errors?.headers?.[index]?.key && (
                      <ErrorMessage>
                        {errors?.headers?.[index]?.key?.message}
                      </ErrorMessage>
                    )}
                    <InputText
                      {...register(`headers.${index}.value` as const)}
                      defaultValue={item.value}
                      style={{
                        width: "100%"
                      }}
                      placeholder="Value"
                    />
                    {errors?.headers?.[index]?.value && (
                      <ErrorMessage>
                        {errors?.headers?.[index]?.value?.message}
                      </ErrorMessage>
                    )}
                  </div>
                </div>
              ))}

              <div className="mt-5 flex align-items-center justify-content-center gap-2">
                <ButtonCancelar
                  type="button"
                  onClick={() => dispatch(setModalWebhook(false))}
                >
                  Cancelar
                </ButtonCancelar>
                <ButtonSalvar
                  type="submit"
                  style={{
                    background: user.plataform.mainColor
                      ? user.plataform.mainColor
                      : "#046af3"
                  }}
                >
                  {loading && (
                    <ProgressSpinner
                      style={{
                        width: "16px",
                        height: "16px",
                        margin: "0.2rem"
                      }}
                      strokeWidth="8"
                      fill="var(--surface-ground)"
                      animationDuration=".5s"
                    />
                  )}
                  Salvar
                </ButtonSalvar>
              </div>
            </form>
          </ContainerLeftModal>
          <ContainerRightModal
            style={{ backgroundColor: user.plataform.mainColor || "#ff0025" }}
          >
            <div className="mb-6">
              <Text
                fontSize="14px"
                fontWeight={600}
                lineHeight="20px"
                color="#FFF"
              >
                O que é integração via webhook?
              </Text>
            </div>
            <Text
              fontSize="12px"
              fontWeight={400}
              lineHeight="20px"
              color="#FFF"
            >
              Seu Web service receberá uma solicitação POST do{" "}
              {user.plataform.displayName} na forma de resposta a uma consulta
              de usuário correspondente por intents com Webhook ativado.
            </Text>
            <div className="flex gap-2 align-items-center justify-content-center m-auto">
              <IoMdPlayCircle
                style={{ marginLeft: "0.14rem" }}
                size={25}
                color="white"
              />
              <Text
                fontSize="12px"
                fontWeight={500}
                lineHeight="16px"
                color="#FAFBFC"
                style={{ textAlign: "left" }}
              >
                Confira o vídeo
              </Text>
            </div>
          </ContainerRightModal>
        </DialogPadrao>
      </div>
    );
  }
);
