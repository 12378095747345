import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import {
  bot,
  Bots,
  Chats,
  Config,
  Dashboard,
  Entities,
  HomeIcon,
  Omini,
  Users,
  SairGray,
  Template,
  Contacts,
  Campaign
} from "../../assets";

import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { AuthContextType, useAuth } from "../../auth/auth-provider";
import "../comum_css/rawCss.css";
import { setSidebarChats, setTitleTab } from "../../redux/slices/sidebar";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import ColorSvg from "../ColorSvg";
import { Title } from "./index";

export default () => {
  const [expanded, setExpanded] = useState(false);
  const [openConfigMenu, setOpenConfigMenu] = useState(false);
  const toggleConfigMenu = () => {
    setOpenConfigMenu((previous) => !previous);
  };
  const { user, hasAnyPermission, logout } = useAuth() as AuthContextType;
  const routeName = useLocation().pathname;
  const [screenMobile, setScreenMobile] = useState(true);
  const dispatch = useAppDispatch();
  const sidebarChats = useAppSelector((state) => state.sidebar.sidebarChats);

  const iconUpDown = openConfigMenu ? (
    <MdKeyboardArrowUp />
  ) : (
    <MdKeyboardArrowDown />
  );

  useEffect(() => {
    dispatch(setTitleTab(user.plataform.displayName));
  }, [user]);

  const linkStyle = css`
    color: #544f77;
    display: flex;
    align-items: center;
    gap: 15px;
    text-decoration: none;
    cursor: pointer;
  `;

  let isCurvatureDisabled = false;

  // eslint-disable-next-line func-style
  function isCurrentRoute(iconRoute: string) {
    // eslint-disable-next-line eqeqeq
    if (iconRoute == routeName) {
      return true;
    }
    return null;
  }

  const disableCurvature = () => {
    const linkWhereDisabled: Record<string, boolean> = {
      "/omnichannel": true
    };
    if (linkWhereDisabled[routeName as keyof typeof linkWhereDisabled]) {
      isCurvatureDisabled = true;
    }
  };

  const handleSair = () => {
    logout();
    dispatch(setSidebarChats(false));
  };

  disableCurvature();

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 500) {
        setScreenMobile(true);
      } else {
        setScreenMobile(false);
      }
    }
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {!screenMobile ? (
        <div
          css={css`
            height: 100vh;
            margin-right: 80px;
          `}
        >
          <div
            style={{ zIndex: 10 }}
            className={`${expanded ? "fade-background" : ""}`}
          />

          <div
            style={{ zIndex: 10 }}
            className={`${expanded ? "tamanhoExpanded" : "tamanho"}`}
            onMouseOver={() => {
              setExpanded((previous) => !previous);
            }}
            onMouseOut={() => {
              setExpanded(false);
            }}
          >
            <div
              className="sidebarHeader"
              style={{
                background: user.plataform.mainColor
                  ? user.plataform.mainColor
                  : "#02cdad"
              }}
              css={
                isCurvatureDisabled && !expanded
                  ? ""
                  : css`
                      border-top-right-radius: 20px;
                    `
              }
            >
              {user.plataform.urlLogoSidebarRetracted && !expanded ? (
                <img
                  src={user.plataform.urlLogoSidebarRetracted}
                  style={{
                    maxWidth: "75px",
                    maxHeight: "75px",
                    padding: "10px"
                  }}
                />
              ) : user.plataform.urlLogoSidebar ? (
                <img
                  src={user.plataform.urlLogoSidebar}
                  style={{
                    maxWidth: "200px",
                    maxHeight: "75px",
                    padding: "10px"
                  }}
                />
              ) : (
                <img
                  src={user.plataform.urlLogo ? user.plataform.urlLogo : bot}
                  style={{
                    maxWidth: "75px",
                    maxHeight: "75px",
                    padding: "10px"
                  }}
                />
              )}

              {expanded && !user.plataform.urlLogoSidebar ? (
                <span
                  css={css`
                    color: white;
                    font-size: 40px;
                    font-weight: bold;
                  `}
                >
                  {user.plataform.displayName}
                </span>
              ) : null}
            </div>

            <div
              style={{
                background: user.plataform.mainColor
                  ? user.plataform.mainColor
                  : "#02cdad"
              }}
              css={css`
                height: calc(100% - 65px);
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  height: 100%;
                  border-top: 1px solid #d2d2d2;
                  border-right: 1px solid #d2d2d2;
                  border-top-right-radius: 20px;
                  background-color: #ffffff;
                `}
              >
                <div
                  css={css`
                    align-items: ${expanded ? "flex-start" : "center"};
                    display: flex;
                    flex: 1 1 auto;
                    flex-direction: column;
                    gap: 25px;
                    padding: 20px;
                    overflow-y: auto;
                    overflow-x: hidden;
                  `}
                >
                  <Link className="hoverNavIcon" to="/home" css={linkStyle}>
                    <ColorSvg
                      src={HomeIcon}
                      color={
                        isCurrentRoute("/home") || isCurrentRoute("/")
                          ? user.plataform.mainColor || undefined
                          : "#7B89A3"
                      }
                      size={25}
                    />
                    {expanded ? <Title>Home</Title> : null}
                  </Link>

                  {user.admin || hasAnyPermission("dashboard_view") ? (
                    <Link
                      className="hoverNavIcon"
                      to="/dashboard"
                      css={linkStyle}
                    >
                      <ColorSvg
                        src={Dashboard}
                        color={
                          isCurrentRoute("/dashboard")
                            ? user.plataform.mainColor || undefined
                            : "#7B89A3"
                        }
                        size={25}
                      />
                      {expanded ? <Title>Dashboard</Title> : null}
                    </Link>
                  ) : null}

                  {user.admin || hasAnyPermission("omnichannel_view") ? (
                    <Link
                      className="hoverNavIcon"
                      to="/omnichannel"
                      css={linkStyle}
                    >
                      <ColorSvg
                        src={Omini}
                        color={
                          isCurrentRoute("/omnichannel") ||
                          isCurrentRoute("/omnichannel/")
                            ? user.plataform.mainColor || undefined
                            : "#7B89A3"
                        }
                        size={25}
                      />
                      {expanded ? <Title>Omnichannel</Title> : null}
                    </Link>
                  ) : null}

                  {user.admin || hasAnyPermission("dialog_view") ? (
                    <Link className="hoverNavIcon" to="/rooms/" css={linkStyle}>
                      <ColorSvg
                        src={Chats}
                        color={
                          isCurrentRoute("/rooms/")
                            ? user.plataform.mainColor || undefined
                            : "#7B89A3"
                        }
                        size={25}
                      />
                      {expanded ? <Title>Gestão de salas</Title> : null}
                    </Link>
                  ) : null}

                  {user.admin || hasAnyPermission("entity_view") ? (
                    <Link
                      className="hoverNavIcon"
                      to="/entities/"
                      css={linkStyle}
                    >
                      <ColorSvg
                        src={Entities}
                        color={
                          isCurrentRoute("/entities/")
                            ? user.plataform.mainColor || undefined
                            : "#7B89A3"
                        }
                        size={25}
                      />
                      {expanded ? <Title>Entidades</Title> : null}
                    </Link>
                  ) : null}

                  {user.admin || hasAnyPermission("bot_view") ? (
                    <Link
                      className="hoverNavIcon"
                      to="/chatbots"
                      css={linkStyle}
                    >
                      <ColorSvg
                        src={Bots}
                        color={
                          window.location.pathname.startsWith("/chatbots")
                            ? user.plataform.mainColor || undefined
                            : "#7B89A3"
                        }
                        size={30}
                      />
                      {expanded ? <Title>Meus robôs</Title> : null}
                    </Link>
                  ) : null}

                  {user.admin || hasAnyPermission("template_view") ? (
                    <Link
                      className="hoverNavIcon"
                      to="/templates-list/"
                      css={linkStyle}
                    >
                      <ColorSvg
                        src={Template}
                        color={
                          isCurrentRoute("/templates-list/")
                            ? user.plataform.mainColor || undefined
                            : "#7B89A3"
                        }
                        size={25}
                      />
                      {expanded ? <Title>Templates</Title> : null}
                    </Link>
                  ) : null}

                  {user.admin || hasAnyPermission("user_view") ? (
                    <Link className="hoverNavIcon" to="/users/" css={linkStyle}>
                      <ColorSvg
                        src={Users}
                        color={
                          isCurrentRoute("/users/")
                            ? user.plataform.mainColor || undefined
                            : "#7B89A3"
                        }
                        size={25}
                      />
                      {expanded ? <Title>Usuários</Title> : null}
                    </Link>
                  ) : null}

                  {user.admin || hasAnyPermission("contact_view") ? (
                    <Link
                      className="hoverNavIcon"
                      to="/contacts"
                      css={linkStyle}
                    >
                      <ColorSvg
                        src={Contacts}
                        color={
                          isCurrentRoute("/contacts")
                            ? user.plataform.mainColor || undefined
                            : "#7B89A3"
                        }
                        size={25}
                      />
                      {expanded ? <Title>Contatos</Title> : null}
                    </Link>
                  ) : null}

                  {user.allowCampaignCreation &&
                  hasAnyPermission("campaign_view") ? (
                    <Link
                      className="hoverNavIcon"
                      to="/campaigns"
                      css={linkStyle}
                    >
                      <ColorSvg
                        src={Campaign}
                        color={
                          isCurrentRoute("/campaigns")
                            ? user.plataform.mainColor || undefined
                            : "#7B89A3"
                        }
                        size={25}
                      />
                      {expanded ? <Title>Campanhas</Title> : null}
                    </Link>
                  ) : null}

                  <div
                    css={css`
                      border: 1px solid #d2d2d2;
                      width: 100%;
                      margin-bottom: 0;
                    `}
                  />

                  <a
                    onClick={() => toggleConfigMenu()}
                    css={linkStyle}
                    className="colorWokspaceConfig"
                  >
                    <ColorSvg
                      src={Config}
                      color={
                        isCurrentRoute("/Workspaces/") ||
                        isCurrentRoute("/profiles") ||
                        isCurrentRoute("/gcredentials") ||
                        isCurrentRoute("/change-company") ||
                        isCurrentRoute("/company")
                          ? user.plataform.mainColor || undefined
                          : "#7B89A3"
                      }
                      size={25}
                    />
                    {expanded ? <Title>Configurações</Title> : null}

                    {expanded && iconUpDown}
                  </a>

                  {openConfigMenu && expanded && (
                    <div className="configContainer">
                      <ul
                        style={{
                          marginLeft: "4px",
                          position: "relative",
                          bottom: "10px"
                        }}
                      >
                        <li>
                          {user.admin || hasAnyPermission("workspace_view") ? (
                            <Link
                              to="/Workspaces/"
                              className="colorWokspaceConfig"
                            >
                              Workspace
                            </Link>
                          ) : null}
                        </li>

                        <li className="mt-2">
                          {user.admin || hasAnyPermission("profile_view") ? (
                            <Link
                              to="/profiles"
                              className="colorWokspaceConfig"
                            >
                              Permissionamento
                            </Link>
                          ) : null}
                        </li>

                        <li className="mt-2">
                          {user.admin || hasAnyPermission("credential_view") ? (
                            <Link
                              to="/gcredentials"
                              className="colorWokspaceConfig"
                            >
                              Credenciais
                            </Link>
                          ) : null}
                        </li>

                        {user.admin ? (
                          <li className="mt-2">
                            <Link
                              to="/change-company"
                              className="colorWokspaceConfig"
                            >
                              Trocar de empresa
                            </Link>
                          </li>
                        ) : null}

                        {user.admin ? (
                          <li className="mt-2">
                            <Link to="/company" className="colorWokspaceConfig">
                              Empresas
                            </Link>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  )}

                  <div onClick={handleSair} css={linkStyle}>
                    <img
                      src={SairGray}
                      alt="Person Icon"
                      width={25}
                      height={25}
                    />

                    {expanded ? "Sair" : null}
                  </div>
                </div>

                <div
                  css={css`
                    align-items: center;
                    color: #999999;
                    display: flex;
                    justify-content: center;
                    padding-bottom: 20px;
                  `}
                >
                  © {user.plataform.displayName}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          css={css`
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            width: 100vw;
            padding: 0.7rem;
            background: #ffffff;
            margin-top: 0.5rem;
            z-index: 10;
          `}
        >
          {user.admin || hasAnyPermission("omnichannel_view") ? (
            <Link
              to="/omnichannel"
              css={linkStyle}
              onClick={() => dispatch(setSidebarChats(true))}
            >
              <ColorSvg
                src={Omini}
                color={
                  isCurrentRoute("/omnichannel")
                    ? user.plataform.mainColor || undefined
                    : "#7B89A3"
                }
                size={30}
              />
            </Link>
          ) : null}

          {user.admin || hasAnyPermission("dialog_view") ? (
            <Link
              to="/rooms/"
              css={linkStyle}
              onClick={() => dispatch(setSidebarChats(false))}
            >
              <ColorSvg
                src={Chats}
                color={
                  isCurrentRoute("/rooms/")
                    ? user.plataform.mainColor || undefined
                    : "#7B89A3"
                }
                size={30}
              />
            </Link>
          ) : null}

          <div onClick={handleSair} css={linkStyle}>
            <img src={SairGray} alt="Person Icon" width={30} height={30} />

            {expanded ? "Sair" : null}
          </div>
        </div>
      )}
    </>
  );
};
