import { css } from "@emotion/react";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { createContext, useEffect, useRef, useState } from "react";
import { workspace } from "../../../assets";
import { AdicionarBar } from "../../../components/adicionar-bar";
import { Button, DataTable, InputText } from "../..";
import ProfileService from "../../../services/ProfileService";
import AddProfile from "./add-profile";
import { useAppSelector } from "../../../redux/hooks";
import { Container } from "./styles";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";

const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);
  return debouncedValue;
};

export const ProfileContext = createContext<{ id: number } | null>(null);

export default () => {
  const { findAllProfiles } = ProfileService();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const debouncedText = useDebounce(globalFilterValue, 600);

  const updateProfiles = useAppSelector(
    (state) => state.flowUpdates.updateProfiles
  );

  const [profile, setProfile] = useState<{ id: number } | null>(null);
  const [visible, setVisible] = useState(false);
  const [visibleAdd, setVisibleAdd] = useState(false);
  const shouldUpdate = useAppSelector(
    (state) => state.flowUpdates.shouldUpdate
  );
  const [tablePropsData, setTablePropsData] = useState({});

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 20,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      name: { value: "", matchMode: "contains" }
    }
  });

  const toastRef = useRef<Toast>(null);
  const onPage = (event: never) => {
    setLazyParams(event);
  };
  const fetch = async () => {
    try {
      const data = await findAllProfiles({
        offset: lazyParams.first,
        limit: lazyParams.rows,
        name: debouncedText
      });
      const tableProps = {
        first: lazyParams.first,
        lazy: true,
        onPage,
        paginator: true,
        rows: lazyParams.rows,
        totalRecords: data.total,
        value: data.result
      };
      setTablePropsData(tableProps);
    } catch (error) {
      toastRef?.current?.show({
        severity: "error",
        summary: "Error Message",
        detail:
          "Não foi possível buscar os dados de Perfis neste momento. Tente mais tarde.",
        life: 3000
      });
    }
  };

  const handleChangeProfile = (rowData: { id: number }) => {
    setProfile({ id: rowData.id });
    setVisible(true);
  };

  useEffect(() => {
    setVisible(false);
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdate, lazyParams, debouncedText, updateProfiles]);

  const inputStyles = css`
    display: flex;
    width: 342px;

    @media screen and (max-width: 480px) {
      width: 100%;
    }
  `;

  const paginatorCustomTemplate = {
    layout: "RowsPerPageDropdown PrevPageLink PageLinks NextPageLink",
    RowsPerPageDropdown: (options: {
      value: number;
      onChange: ((e: DropdownChangeParams) => void) | undefined;
    }) => {
      const dropdownOptions = [
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: 100, value: 100 }
      ];

      return (
        <div
          style={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <span
            style={{
              minWidth: "fit-content"
            }}
          >
            Por página:
          </span>

          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
            css={css`
              .p-inputtext {
                color: #6e6b7b;
              }
            `}
          />
        </div>
      );
    }
  };

  return (
    <Container>
      <Toast ref={toastRef} />
      <div>
        <AdicionarBar visible={visibleAdd} hide={() => setVisibleAdd(false)} />
        <div className="p-4">
          <div className="align-items-center flex gap-2">
            <img src={workspace} />
            <div
              className="text-xl font-bold"
              css={css`
                color: #0a043c;
              `}
            >
              Perfis
            </div>
          </div>

          <div
            className="mt-2"
            style={{
              color: "#6e6b7b"
            }}
          >
            Gerencie o seu perfil, editando como desejar.
          </div>

          <div className="mt-2">
            <div
              css={css`
                background-color: white;
                border-radius: 10px 10px 0px 0px;
              `}
            >
              <div
                className="flex gap-2"
                css={css`
                  padding: 8px 12px;
                  display: flex;
                  flex-wrap: wrap;
                `}
              >
                <span className="p-input-icon-left" css={inputStyles}>
                  <i className="pi pi-search" />

                  <InputText
                    value={globalFilterValue}
                    onChange={(e) => setGlobalFilterValue(e.target.value)}
                    id="name"
                    placeholder="Buscar"
                    css={css`
                      width: 100%;
                    `}
                  />
                </span>
              </div>
            </div>

            <DataTable
              {...tablePropsData}
              filterDelay={600}
              emptyMessage="Nenhum resultado encontrado"
              paginatorTemplate={paginatorCustomTemplate}
            >
              <Column field="name" header="Nome" />

              <Column
                align="center"
                alignHeader="center"
                headerStyle={{ width: "12%" }}
                body={(rowData) => (
                  <div
                    css={css`
                      align-items: center;
                      display: flex;
                      gap: 10px;
                      justify-content: center;
                    `}
                  >
                    <Button
                      label="Editar"
                      className="p-button-outlined"
                      css={css`
                        font-size: 12px;
                        padding: 4px 12px;
                      `}
                      onClick={() => handleChangeProfile(rowData)}
                    />
                  </div>
                )}
              />
            </DataTable>

            <ProfileContext.Provider value={profile}>
              <AddProfile
                visible={visible}
                onSave={async () => {
                  await fetch();

                  toastRef?.current?.show?.({
                    severity: "success",
                    detail: "Perfil salvo com sucesso."
                  });

                  setVisible(false);
                }}
                onHide={() => {
                  setVisible(false);
                }}
              />
            </ProfileContext.Provider>
          </div>
        </div>
      </div>
    </Container>
  );
};
