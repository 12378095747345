import styled from "@emotion/styled";
interface iContainer {
  hidden: boolean;
  expanded?: boolean;
}
export const Container = styled.div<iContainer>`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  flex-direction: column;
  position: relative;
  height: 100%;
  width: ${(props) => (props.expanded ? "240px" : "80px")};
  max-height: 100%;
  z-index: 3;
  top: 0;
  overflow: hidden;

  transition: ${(props) =>
    props.expanded ? "width 250ms ease-out" : "width 250ms linear"};
`;

export const Title = styled.div`
  color: #544f77;
  transition: color 0.3s ease;

  &:hover {
    color: #212529;
  }
`;
