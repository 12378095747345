/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { AuthContextType, useAuth } from "../../../auth/auth-provider";
import { useEffect, useRef, useState } from "react";
import DialogResourceServices, {
  iTransferDataRooms
} from "../../../services/dialogResource";
import "./tableStyle.css";
import {
  DataTableFilterMetaData,
  DataTableMultiSortMetaType
} from "primereact/datatable";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Chip, Container, DivIcon, Img, HeaderTable, Options } from "./styles";
import {
  WebChat,
  WhatsAppDataTable,
  InstagramDataTable,
  facebookDataTable,
  Chats
} from "../../../assets";
import { Text } from "../../../components/Text";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { DataTable, useToast } from "../../../components";
import { Column } from "primereact/column";
import { FilterMatchMode, SortOrder } from "primereact/api";
import { MyCalendar } from "../../../components/MyCalendar";
import { startOfMonth } from "date-fns";
import { css } from "@emotion/css";
import { useNavigate } from "react-router";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { LetterCircle } from "../../gestaoBot/components/LetterCircle/index";
import { ModalCloseRooms } from "./modalConfimCloseRooms";
import {
  setCloseRoomsModalOpen,
  setTransferRoomsModalOpen
} from "../../../redux/slices/flowInteractions";
import { ModalTransferRooms } from "./modalConfimTransferRooms";
import {
  setLoadOmnichannel,
  setFilters,
  setValueLoad
} from "../../../redux/slices/workspaces";

interface LazyTableState {
  first: number;
  rows: number;
  page: number;
  sortField?: string;
  sortOrder?: SortOrder;
  multiSortMeta?: DataTableMultiSortMetaType;
  filters: {
    firstMessage: DataTableFilterMetaData;
    lastMessage: DataTableFilterMetaData;
    statusTime: DataTableFilterMetaData;
    botName?: DataTableFilterMetaData;
    transferredTo?: DataTableFilterMetaData;
    id?: DataTableFilterMetaData;
    leadName?: DataTableFilterMetaData;
    phone?: DataTableFilterMetaData;
    objective?: DataTableFilterMetaData;
    plataform?: DataTableFilterMetaData;
    repetitions?: DataTableFilterMetaData;
    status?: DataTableFilterMetaData;
    tag?: DataTableFilterMetaData;
    workspace?: DataTableFilterMetaData;
  };
}

interface Filter {
  value: any;
  matchMode: string;
}
interface Filters {
  botName?: Filter;
  firstMessage?: Filter;
  id?: Filter;
  lastMessage?: Filter;
  leadName?: Filter;
  objective?: Filter;
  phone?: Filter;
  plataform?: Filter;
  repetitions?: Filter;
  status?: Filter;
  statusTime?: Filter;
  tag?: Filter;
  transferredTo?: Filter;
  workspace?: Filter;
}

interface iRooms {
  id: number;
  botName: string;
  phone: string;
  transferredTo: string;
  firstMessage: string;
  closedAt: string;
  lastMessage: string;
  objective: string;
  plataform: string;
  status: string;
  workspace: string;
  repetitions: number;
}

// interface iObject {
//   el: [string, { value: string }];
// }

// const useDebounce = (value: string, delay: number) => {
//   const [debouncedValue, setDebouncedValue] = useState(value);

//   useEffect(() => {
//     const timer = setTimeout(() => setDebouncedValue(value), delay);

//     return () => {
//       clearTimeout(timer);
//     };
//   }, [value, delay]);
//   return debouncedValue;
// };

const defaultFilterValues: LazyTableState = {
  first: 0,
  rows: 20,
  page: 1,

  filters: {
    id: { value: null, matchMode: FilterMatchMode.CONTAINS },
    botName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    transferredTo: { value: null, matchMode: FilterMatchMode.CONTAINS },
    leadName: { value: null, matchMode: FilterMatchMode.EQUALS },
    phone: { value: null, matchMode: FilterMatchMode.EQUALS },
    objective: { value: null, matchMode: FilterMatchMode.EQUALS },
    plataform: { value: null, matchMode: FilterMatchMode.EQUALS },
    repetitions: { value: null, matchMode: FilterMatchMode.CONTAINS },
    status: { value: "Aberto", matchMode: FilterMatchMode.EQUALS },
    tag: { value: null, matchMode: FilterMatchMode.EQUALS },
    workspace: { value: null, matchMode: FilterMatchMode.EQUALS },
    firstMessage: {
      value: [startOfMonth(new Date()), new Date()],
      matchMode: FilterMatchMode.DATE_AFTER
    },
    statusTime: {
      value: null,
      matchMode: FilterMatchMode.DATE_AFTER
    },
    lastMessage: {
      value: [startOfMonth(new Date()), new Date()],
      matchMode: FilterMatchMode.DATE_BEFORE
    }
  }
};

export const RoomsPage: React.FC = () => {
  const { postGetDialogs } = DialogResourceServices();
  const { user, company } = useAuth() as AuthContextType;
  const companyId = company?.id;
  const toast = useToast();

  const companyIdSelected = user.admin ? companyId : user.companyId;
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  // const [lastMessage, setLastMessage] = useState<CalendarValueType>(null);
  const [rooms, setRooms] = useState<iRooms[] | undefined>(undefined);
  // const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [lazyParams, setLazyParams] =
    useState<LazyTableState>(defaultFilterValues);
  const [selectedRooms, setSelectedRooms] = useState<iRooms[]>([]);
  const [optionsActions, setOptionsActions] = useState(0);
  // const debouncedText = useDebounce(globalFilterValue, 500);
  const [tabletMobile, setTabletMobile] = useState(false);
  const navigate = useNavigate();

  const selectedWorkspacesIds = useAppSelector(
    (state) => state.workspaces.selectedWorkspaces
  );

  // const workspaces = useAppSelector((state) => state.workspaces.userWorkspaces);
  const workspaces = user.workspaces?.workspaces;
  const loadOmnichannel = useAppSelector(
    (state) => state.workspaces.loadOmnichannel
  );
  const valueLoad = useAppSelector((state) => state.workspaces.valueLoad);
  const [currentPathname] = useState(window.location.pathname);
  const filters = defaultFilterValues.filters;
  const filterValue = useAppSelector((state) => state.workspaces.filterValue);
  const valueFilters = useAppSelector((state) => state.workspaces.filters);
  const [objectivesOptions] = useState([
    "Atendimento",
    "Suporte",
    "FAQ",
    "Funcionário digital",
    "Campanha",
    "Sem script"
  ]);
  const [statusOptions] = useState(["Todas", "Aberto", "Finalizado"]);
  const effectRan = useRef(false);
  const dropdownActionsOptions = [
    { label: "Fechar salas", value: 1 },
    { label: "Transferir salas", value: 2 }
  ];
  const startAtPeriod = {
    startAt: Date(),
    endAt: Date()
  };
  const { closeDialogsRooms, transferDialogsRooms } = DialogResourceServices();
  const lastMessagePeriod = {
    startAt: Date(),
    endAt: Date()
  };

  const goToRoom = (id: number) => {
    navigate(
      { pathname: `/omnichannel/` },
      {
        state: {
          dialogId: id,
          showSidebar: false
        }
      }
    );
  };

  const loadLazyData = async () => {
    if (
      valueLoad === "firstLoad" ||
      valueLoad === "datatableLoad" ||
      valueLoad === "secondLoad"
    ) {
      setLoading(true);

      Object.entries(lazyParams.filters)
        .map((el): any => {
          if (el[1].value !== null) {
            if (el[0] === "firstMessage") {
              startAtPeriod.startAt = el[1].value[0]
                ?.toISOString()
                .substring(0, 10);
              startAtPeriod.endAt = el[1].value[1]
                ?.toISOString()
                .substring(0, 10);
            } else if (el[0] === "lastMessage") {
              lastMessagePeriod.startAt = el[1].value[0]
                ?.toISOString()
                .substring(0, 10);
              lastMessagePeriod.endAt = el[1].value[1]
                ?.toISOString()
                .substring(0, 10);
            }
          }
        })
        .filter((notUndefineds) => notUndefineds !== undefined);

      const objectRequest = {
        limit: lazyParams.rows,
        offset: lazyParams.first,
        sortField: lazyParams.sortField,
        sortOrder: lazyParams.sortOrder,
        companyId: companyIdSelected,
        workspaces: selectedWorkspacesIds,
        filters: Object.entries(lazyParams.filters)
          .map((el): any => {
            if (el[1].value !== null) {
              if (el[0] === "firstMessage") {
                return;
              }
              if (el[0] === "lastMessage") {
                return;
              }
              if (el[0] === "workspace") {
                if (el[1].value !== null) {
                  return {
                    field: "workspace",
                    value: el[1].value
                  };
                }
                return;
              }
              return {
                field: el[0],
                value: el[1]?.value
              };
            }
          })
          .filter((notUndefineds) => notUndefineds !== undefined),
        startAtPeriod,
        lastMessagePeriod
      };
      let data;

      try {
        data = await postGetDialogs(objectRequest);
      } catch (error: any) {
        toast?.current?.show({
          life: 3000,
          severity: "error",
          summary: "Erro ao gravar Mensagem.",
          detail: error.message,
          sticky: false
        });
      }
      setRooms(data.result);
      setTotalRecords(data.total);
      setTimeout(() => {
        setLoading(false);
      }, 300);
    }
  };

  const onChangeActions = async (e: number) => {
    if (e === 1) {
      if (selectedRooms.length < 1) {
        toast.current?.show({
          life: 3000,
          severity: "error",
          summary: "Salas não selecionadas.",
          detail: "Não existem salas selecionadas para executar o fechamento."
        });
      } else {
        dispatch(setCloseRoomsModalOpen(true));
      }
    }
    if (e === 2) {
      if (selectedRooms.length < 1) {
        toast.current?.show({
          life: 3000,
          severity: "error",
          summary: "Salas não selecionadas.",
          detail: "Não existem salas selecionadas para executar o fechamento."
        });
      } else {
        dispatch(setTransferRoomsModalOpen(true));
      }
    }
  };
  const onTransferRooms = async (data: iTransferDataRooms) => {
    const openedRooms = selectedRooms
      ?.filter((el) => el.transferredTo === "Sem transferência")
      .map((el) => el.id);
    setOptionsActions(3);
    try {
      await transferDialogsRooms({ rooms: openedRooms, transferData: data });
      loadLazyData();
      setOptionsActions(0);
      toast.current?.show({
        life: 3000,
        severity: "success",
        summary: "Salas fechadas.",
        detail: "Salas selecionadas transferidas com sucesso."
      });
      dispatch(setCloseRoomsModalOpen(false));
    } catch (e) {
      toast.current?.show({
        life: 3000,
        severity: "error",
        summary: "Salas não transferidas.",
        detail:
          "Não foi possível transferir as salas selecionadas. Por favor, tente novamente mais tarde."
      });
      setOptionsActions(0);
      dispatch(setCloseRoomsModalOpen(false));
    }
  };
  const onAcceptCloseRooms = async () => {
    const openedRooms = selectedRooms
      ?.filter((el) => el.closedAt === null)
      .map((el) => el.id);
    setOptionsActions(1);
    try {
      await closeDialogsRooms({ rooms: openedRooms });
      loadLazyData();
      setOptionsActions(0);
      toast.current?.show({
        life: 3000,
        severity: "success",
        summary: "Salas fechadas.",
        detail: "Salas selecionadas fechadas com sucesso."
      });
      dispatch(setCloseRoomsModalOpen(false));
    } catch (e) {
      toast.current?.show({
        life: 3000,
        severity: "error",
        summary: "Salas não fechadas.",
        detail:
          "Não foi possível fechar as salas selecionadas. Por favor, tente novamente mais tarde."
      });
      setOptionsActions(0);
      dispatch(setCloseRoomsModalOpen(false));
    }
  };

  useEffect(() => {
    if (
      lazyParams.filters.firstMessage.value !== null &&
      lazyParams.filters.lastMessage.value !== null &&
      effectRan.current === true
    ) {
      loadLazyData();
    }
    return () => {
      effectRan.current = true;
    };
  }, [lazyParams, selectedWorkspacesIds]);

  const applyFilters = (params: any) => {
    if (Object.keys(valueFilters).length > 0) {
      const reconvertedFilters: Filters = Object.keys(valueFilters).reduce(
        (acc, key) => {
          const filter = valueFilters[key as keyof Filters];

          const isDateField = ["firstMessage", "lastMessage"].includes(key);

          if (
            isDateField &&
            typeof filter?.value === "string" &&
            Date.parse(filter.value)
          ) {
            acc[key as keyof Filters] = {
              ...filter,
              value: new Date(filter.value)
            };
          } else if (Array.isArray(filter?.value)) {
            acc[key as keyof Filters] = {
              ...filter,
              value: filter.value.map((v) =>
                isDateField && typeof v === "string" && Date.parse(v)
                  ? new Date(v)
                  : v
              )
            };
          } else {
            acc[key as keyof Filters] = filter;
          }

          return acc;
        },
        {} as Filters
      );

      return {
        ...params,
        filters: reconvertedFilters as any
      };
    }

    return params;
  };

  const onPage = (event: any) => {
    const newParams = applyFilters(event);
    setLazyParams(newParams);
  };

  useEffect(() => {
    if (lazyParams.filters.firstMessage.value === null) {
      setLazyParams((prev) =>
        applyFilters({
          ...prev,
          filters: {
            ...prev.filters,
            firstMessage: {
              ...prev.filters.firstMessage,
              value: startOfMonth(new Date())
            }
          }
        })
      );
    }
    if (lazyParams.filters.lastMessage.value === null) {
      setLazyParams((prev) =>
        applyFilters({
          ...prev,
          filters: {
            ...prev.filters,
            lastMessage: {
              ...prev.filters.lastMessage,
              value: new Date()
            }
          }
        })
      );
    }
  }, [lazyParams.filters.firstMessage, lazyParams.filters.lastMessage]);

  const onSort = (event: any) => {
    event.first = 0;
    const newParams = applyFilters(event);
    setLazyParams(newParams);
  };

  const onFilter = (event: any) => {
    event.first = 0;
    const convertedFilters = Object.keys(event.filters).reduce((acc, key) => {
      const filter = event.filters[key];

      if (filter.value instanceof Date) {
        acc[key] = { ...filter, value: filter.value.toISOString() };
      } else if (Array.isArray(filter.value)) {
        acc[key] = {
          ...filter,
          value: filter.value.map((v) =>
            v instanceof Date ? v.toISOString() : v
          )
        };
      } else {
        acc[key] = filter;
      }

      return acc;
    }, {});

    const formatting = {
      ...convertedFilters,
      phone: {
        ...convertedFilters.phone,
        value: convertedFilters.phone?.value?.replace(/\D/g, "")
      }
    };

    const newParams = applyFilters(event);
    setLazyParams(newParams);
    dispatch(setFilters(formatting));
    dispatch(setValueLoad("datatableLoad"));
  };

  useEffect(() => {
    if (valueLoad !== "firstLoad") {
      setTimeout(() => {
        dispatch(setValueLoad("datatableLoad"));
        const newParams = applyFilters(lazyParams);
        setLazyParams(newParams);
      }, 500);
    }
  }, [valueFilters]);

  const firstCapitalLetter = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const formatDate = (inputDate: string): string => {
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  const formatPhone = (phoneNumber: string): string => {
    const numericOnly = phoneNumber.replace(/\D/gu, "");

    if (numericOnly.length > 10) {
      const countrySplitter = require("country-code-splitter");

      const splittedPhone = countrySplitter.getNumber(`+${numericOnly}`);

      const ddd = splittedPhone.number.substring(0, 2);

      const firstHalf = splittedPhone.number.substring(
        2,
        splittedPhone.number.length - 4
      );

      const secondHalf = splittedPhone.number.substring(
        splittedPhone.number.length - 4
      );

      const formattedNumber = `${splittedPhone.code} (${ddd}) ${firstHalf}-${secondHalf}`;

      return formattedNumber;
    }
    const ddd = numericOnly.substring(0, 2);

    const firstHalf = numericOnly.substring(2, numericOnly.length - 4);

    const secondHalf = numericOnly.substring(numericOnly.length - 4);

    const formattedNumber = `(${ddd}) ${firstHalf}-${secondHalf}`;

    return formattedNumber;
  };

  const handleRowClicked = (e: any) => {
    const target = e.originalEvent.target as HTMLElement;

    const columnIndex = Array.from(
      target.parentElement?.children || []
    ).indexOf(target);

    if (columnIndex != 0) {
      goToRoom(e.data.id);
    }
  };

  const objectiveRowFilterTemplate = (options: any) => (
    <Dropdown
      style={{ width: "120px" }}
      value={options.value}
      options={objectivesOptions}
      onChange={(e) => options.filterApplyCallback(e.value)}
      placeholder="Objetivo"
      className="p-column-filter"
      showClear={false}
    />
  );

  const workspaceRowFilterTemplate = (options: any) => (
    <Dropdown
      style={{ width: "120px" }}
      value={options.value}
      options={workspaces?.map((el) => el.name)}
      onChange={(e) => options.filterApplyCallback(e.value)}
      placeholder="Selecione"
      className="p-column-filter"
      showClear={false}
      filter={true}
    />
  );

  const firstMessageRowFilterTemplate = (options: any) => (
    <MyCalendar
      value={lazyParams.filters.firstMessage.value}
      selectionMode="range"
      onChange={(e) => options.filterApplyCallback(e.value)}
      dateFormat="dd/mm/yy"
      placeholder="Período"
      mask="99/99/9999"
      locale="pt-br"
      readOnlyInput={true}
      inputStyle={{
        padding: "10px",
        borderRadius: "5px",
        backgroundColor: "#ffffff",
        cursor: "pointer"
      }}
    />
  );

  const lastMessageRowFilterTemplate = (options: any) => (
    <MyCalendar
      value={lazyParams.filters.lastMessage.value}
      onChange={(e) => {
        options.filterApplyCallback(e.value);
      }}
      selectionMode="range"
      dateFormat="dd/mm/yy"
      placeholder="Período"
      mask="99/99/9999"
      locale="pt-br"
      readOnlyInput={true}
      inputStyle={{
        padding: "10px",
        borderRadius: "5px",
        backgroundColor: "#ffffff",
        cursor: "pointer"
      }}
    />
  );

  const statusRowFilterTemplate = (options: any) => (
    <Dropdown
      style={{ width: "120px" }}
      value={options.value}
      options={statusOptions}
      onChange={(e) => options.filterApplyCallback(e.value)}
      placeholder="Selecione"
      className="p-column-filter"
      showClear={false}
    />
  );

  const statusBodyTemplate = (rowData: {
    status: string;
    firstMessage: Date;
    lastMessage: Date;
  }) => {
    if (rowData.status === "Aberto") {
      return (
        <Chip
          className="expand-icon-tooltip-time cursor-pointer"
          bgColor="#AEE9D1"
        >
          {rowData.status}
        </Chip>
      );
    }

    if (rowData.status === "Finalizado") {
      return (
        <Chip
          className="expand-icon-tooltip-time cursor-pointer"
          bgColor="#D8D8D8"
        >
          {rowData.status}
        </Chip>
      );
    }
  };
  const paginatorCustomTemplate = {
    layout: "RowsPerPageDropdown PrevPageLink PageLinks NextPageLink",
    RowsPerPageDropdown: (options: {
      value: number;
      onChange: ((e: DropdownChangeParams) => void) | undefined;
    }) => {
      const dropdownOptions = [
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: 100, value: 100 }
      ];

      return (
        <>
          <span>Por página:</span>

          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
            css={css`
              .p-inputtext {
                color: #6e6b7b;
              }
            `}
          />
        </>
      );
    }
  };

  useEffect(() => {
    const handleResize = () => {
      let size = window.innerWidth;
      if (size <= 900) {
        setTabletMobile(true);
      } else {
        setTabletMobile(false);
      }
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Container>
      <ModalCloseRooms confirmar={onAcceptCloseRooms} />
      <ModalTransferRooms confirmar={onTransferRooms} />
      <div
        className="flex align-items-center w-full justify-content-between"
        css={HeaderTable}
      >
        <div className="flex align-items-center justify-content-start gap-2">
          <DivIcon>
            <Img src={Chats} alt="Chave Bot Icon" />
          </DivIcon>

          <Text s="1.25rem" weight={600} lh="1.75rem" color="#0A043C">
            Gestão de salas
          </Text>
        </div>

        <div className="flex" css={Options}>
          <Dropdown
            style={{
              marginRight: "0.2rem"
            }}
            className="my-custom-dropdown"
            value={optionsActions}
            options={dropdownActionsOptions}
            onChange={(e) => onChangeActions(e.value)}
            placeholder="Selecionar Ação"
          />
          <Button
            className="mr-1 room-managment-button tooltip-for-first-button"
            data-pr-position="bottom"
            onClick={() => {
              dispatch(setValueLoad("firstLoad"));
              loadLazyData();
            }}
          >
            <i className="pi pi-refresh room-icon" />
          </Button>

          <Tooltip target=".tooltip-for-first-button"> Atualizar </Tooltip>

          <Button
            className="room-managment-button tooltip-for-fourth-button"
            data-pr-position="bottom"
            onClick={() => setLazyParams(defaultFilterValues)}
          >
            <i className="pi pi-filter-slash room-icon" />
          </Button>

          <Tooltip target=".tooltip-for-fourth-button">Limpar Filtros</Tooltip>
        </div>
      </div>

      <div className="mt-2 w-full">
        {tabletMobile ? (
          <div
            css={css`
              background-color: red;
              border-radius: 10px 10px 0px 0px;
              padding: 8px 12px;
              display: flex;
              gap: 0.5rem;
              flex-wrap: wrap;
              max-width: 500px;
            `}
          >
            <DataTable
              className="table-style custom-table"
              value={rooms}
              lazy={true}
              filterDisplay="row"
              scrollDirection="horizontal"
              dataKey="id"
              paginator={true}
              first={lazyParams.first}
              rows={lazyParams.rows}
              sortField={lazyParams.sortField}
              sortOrder={lazyParams.sortOrder}
              totalRecords={totalRecords}
              onPage={onPage}
              onSort={onSort}
              onFilter={onFilter}
              filterDelay={600}
              paginatorTemplate={paginatorCustomTemplate}
              rowsPerPageOptions={[20, 50, 100]}
              // multiSortMeta={lazyParams.multiSortMeta}
              filters={lazyParams.filters}
              loading={loading}
              removableSort={true}
              sortMode="single"
              emptyMessage="Salas não encontradas."
              selection={selectedRooms}
              selectionMode="checkbox"
              rowHover={true}
              onRowClick={(e) => {
                handleRowClicked(e);
                // goToRoom(e.data.id);
              }}
              onSelectionChange={(e) => {
                setSelectedRooms(e.value);
              }}
            >
              <Column selectionMode="multiple" />

              <Column
                field="id"
                headerStyle={{ padding: "5px 0.25rem" }}
                filterHeaderStyle={{ padding: "5px 0.25rem" }}
                header="ID"
                body={(rowData) => (
                  <div
                    css={css`
                      display: flex;
                      justify-content: center;
                    `}
                  >
                    <div
                      css={css`
                        align-items: center;
                        background: #eaeaea;
                        border-radius: 50px;
                        display: flex;
                        font-size: 12px;
                        justify-content: center;
                        padding: 5px;
                        width: 50px;
                      `}
                    >
                      {rowData.id}
                    </div>
                  </div>
                )}
                filter={true}
                showFilterMenu={false}
                style={{ minWidth: "50px" }}
              />

              <Column
                align="left"
                field="leadName"
                headerStyle={{ padding: "5px 0.25rem" }}
                filterHeaderStyle={{ padding: "5px 0.25rem" }}
                header="Lead"
                filter={true}
                showFilterMenu={false}
                body={(rowData) => (
                  <div
                    css={css`
                      display: flex;
                      justify-content: center;
                    `}
                  >
                    <div
                      css={css`
                        align-items: center;
                        background: #eaeaea;
                        border-radius: 50px;
                        display: flex;
                        font-size: 12px;
                        justify-content: center;
                        padding: 5px;
                        width: 50px;
                      `}
                    >
                      {rowData.leadName}
                    </div>
                  </div>
                )}
                style={{ minWidth: "150px" }}
              />

              <Column
                align="left"
                field="phone"
                headerStyle={{ padding: "5px 0.25rem" }}
                filterHeaderStyle={{ padding: "5px 0.25rem" }}
                header="Telefone"
                filter={true}
                showFilterMenu={false}
                body={(rowData) => (
                  <div
                    css={css`
                      display: flex;
                      justify-content: center;
                    `}
                  >
                    <div
                      css={css`
                        align-items: center;
                        background: #eaeaea;
                        border-radius: 50px;
                        display: flex;
                        font-size: 12px;
                        justify-content: center;
                        padding: 5px;
                        width: 50px;
                      `}
                    >
                      {rowData.phone ? formatPhone(rowData.phone) : "-"}
                    </div>
                  </div>
                )}
                style={{ minWidth: "150px" }}
              />

              <Column
                field="botName"
                header="Atendido Por"
                align="left"
                alignHeader="left"
                headerStyle={{ padding: "5px 0.25rem" }}
                filterHeaderStyle={{ padding: "5px 0.25rem" }}
                filter={true}
                showFilterMenu={false}
                style={{ minWidth: "220px" }}
                body={(rowData) => (
                  <div className="flex align-items-center justify-content-start">
                    <div className="flex justify-content-start">
                      {rowData.plataform === "LIVE CHAT" && (
                        <Img src={WebChat} alt="Bot Image" />
                      )}
                      {rowData.plataform === "WHATSAPP" && (
                        <Img src={WhatsAppDataTable} alt="WhatsApp Image" />
                      )}
                      {rowData.plataform === "INSTAGRAM" && (
                        <Img src={InstagramDataTable} alt="Instagram Image" />
                      )}
                      {rowData.plataform === "FACEBOOK" && (
                        <Img src={facebookDataTable} alt="Facebook Image" />
                      )}
                    </div>
                    <Text
                      style={{
                        marginLeft: "0.3rem",
                        padding: "0 0.25rem",
                        textAlign: "initial"
                      }}
                      color="#6E6B7B"
                      weight={300}
                      s="0.875rem"
                    >
                      {rowData.botName}
                    </Text>
                  </div>
                )}
              />

              <Column
                field="workspace"
                header="Workspace"
                align="left"
                alignHeader="left"
                style={{ minWidth: "150px" }}
                headerStyle={{ padding: "5px 0.25rem" }}
                filterHeaderStyle={{ padding: "5px 0.25rem" }}
                filter={true}
                showFilterMenu={false}
                filterElement={workspaceRowFilterTemplate}
                body={(rowData) => (
                  <div className="w-full flex justify-content-end">
                    <Text color="#6E6B7B" weight={300} s="0.875rem">
                      {rowData.workspace}
                    </Text>
                  </div>
                )}
              />

              <Column
                style={{ minWidth: "170px" }}
                headerStyle={{ padding: "5px 0.25rem" }}
                filterHeaderStyle={{ padding: "5px 0.25rem" }}
                field="lastMessage"
                align="left"
                sortable={true}
                header="Última mensagem"
                filter={true}
                showFilterMenu={false}
                filterElement={lastMessageRowFilterTemplate}
                body={(rowData) => (
                  <div className="w-full flex justify-content-end">
                    <Text color="#6E6B7B" weight={300} s="0.875rem">
                      {formatDate(rowData.lastMessage)}
                    </Text>
                  </div>
                )}
              />

              <Column
                style={{ minWidth: "130px" }}
                headerStyle={{ padding: "5px 0.25rem" }}
                filterHeaderStyle={{ padding: "5px 0.25rem" }}
                field="status"
                align="left"
                header="Status"
                sortable={true}
                filter={true}
                showFilterMenu={false}
                filterElement={statusRowFilterTemplate}
                alignHeader="left"
                body={statusBodyTemplate}
                frozen={true}
                alignFrozen="right"
              />
            </DataTable>
          </div>
        ) : (
          <DataTable
            className="table-style"
            value={rooms}
            lazy={true}
            filterDisplay="row"
            scrollable={true}
            scrollDirection="horizontal"
            dataKey="id"
            paginator={true}
            first={lazyParams.first}
            rows={lazyParams.rows}
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            totalRecords={totalRecords}
            onPage={onPage}
            onSort={onSort}
            onFilter={onFilter}
            filterDelay={1000}
            paginatorTemplate={paginatorCustomTemplate}
            rowsPerPageOptions={[20, 50, 100]}
            // multiSortMeta={lazyParams.multiSortMeta}
            filters={lazyParams.filters}
            loading={loading}
            removableSort={true}
            sortMode="single"
            emptyMessage="Salas não encontradas."
            selection={selectedRooms}
            selectionMode="checkbox"
            rowHover={true}
            onRowClick={(e) => {
              handleRowClicked(e);
              // goToRoom(e.data.id);
            }}
            onSelectionChange={(e) => {
              setSelectedRooms(e.value);
            }}
          >
            <Column selectionMode="multiple" />

            <Column
              field="id"
              headerStyle={{ padding: "5px 0.25rem" }}
              filterHeaderStyle={{ padding: "5px 0.25rem" }}
              header="ID"
              body={(rowData) => <>{rowData.id}</>}
              filter={true}
              showFilterMenu={false}
              style={{ minWidth: "50px" }}
            />

            <Column
              align="left"
              field="leadName"
              headerStyle={{ padding: "5px 0.25rem" }}
              filterHeaderStyle={{ padding: "5px 0.25rem" }}
              header="Lead"
              filter={true}
              showFilterMenu={false}
              body={(rowData) => <>{rowData.leadName}</>}
              style={{ minWidth: "150px" }}
            />

            <Column
              align="left"
              field="phone"
              headerStyle={{ padding: "5px 0.25rem" }}
              filterHeaderStyle={{ padding: "5px 0.25rem" }}
              header="Telefone"
              filter={true}
              showFilterMenu={false}
              body={(rowData) => (
                <>{rowData.phone ? formatPhone(rowData.phone) : "-"}</>
              )}
              style={{ minWidth: "150px" }}
            />

            <Column
              field="botName"
              header="Atendido Por"
              align="left"
              alignHeader="left"
              headerStyle={{ padding: "5px 0.25rem" }}
              filterHeaderStyle={{ padding: "5px 0.25rem" }}
              filter={true}
              showFilterMenu={false}
              style={{ minWidth: "220px" }}
              body={(rowData) => (
                <div className="flex align-items-center justify-content-start">
                  <div className="flex justify-content-start">
                    {rowData.plataform === "LIVE CHAT" && (
                      <Img src={WebChat} alt="Bot Image" />
                    )}
                    {rowData.plataform === "WHATSAPP" && (
                      <Img src={WhatsAppDataTable} alt="WhatsApp Image" />
                    )}
                    {rowData.plataform === "INSTAGRAM" && (
                      <Img src={InstagramDataTable} alt="Instagram Image" />
                    )}
                    {rowData.plataform === "FACEBOOK" && (
                      <Img src={facebookDataTable} alt="Facebook Image" />
                    )}
                  </div>
                  <Text
                    style={{
                      marginLeft: "0.3rem",
                      padding: "0 0.25rem",
                      textAlign: "initial"
                    }}
                    color="#6E6B7B"
                    weight={300}
                    s="0.875rem"
                  >
                    {rowData.botName}
                  </Text>
                </div>
              )}
            />

            <Column
              field="transferredTo"
              header="Transferido para"
              align="left"
              alignHeader="left"
              headerStyle={{ padding: "5px 0.25rem" }}
              filterHeaderStyle={{ padding: "5px 0.25rem" }}
              filter={true}
              showFilterMenu={false}
              style={{ minWidth: "200px" }}
              body={(rowData) =>
                rowData.transferredTo ? (
                  <div className="flex align-items-center justify-content-start">
                    <div className="flex justify-content-start">
                      <LetterCircle
                        initial={firstCapitalLetter(rowData.transferredTo)}
                      />
                    </div>
                    <Tooltip target={`.tooltip-${rowData.id}`}>
                      {rowData.transferredTo}
                    </Tooltip>
                    <Text
                      className={`tooltip-${rowData.id}`}
                      style={{
                        width: "135px",
                        height: "18px",
                        padding: "0 0.25rem",
                        color: "#6E6B7B",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "initial",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 1
                      }}
                      weight={300}
                      s="0.875rem"
                    >
                      {rowData.transferredTo}
                    </Text>
                  </div>
                ) : (
                  "-"
                )
              }
            />

            <Column
              field="workspace"
              header="Workspace"
              align="left"
              alignHeader="left"
              style={{ minWidth: "150px" }}
              headerStyle={{ padding: "5px 0.25rem" }}
              filterHeaderStyle={{ padding: "5px 0.25rem" }}
              filter={true}
              showFilterMenu={false}
              filterElement={workspaceRowFilterTemplate}
              body={(rowData) => (
                <div className="w-full flex justify-content-start">
                  <Text color="#6E6B7B" weight={300} s="0.875rem">
                    {rowData.workspace}
                  </Text>
                </div>
              )}
            />

            <Column
              headerStyle={{ padding: "5px 0.25rem" }}
              filterHeaderStyle={{ padding: "5px 0.25rem" }}
              style={{ minWidth: "140px" }}
              field="objective"
              align="left"
              header="Objetivo"
              showFilterMenu={false}
              filter={true}
              filterElement={objectiveRowFilterTemplate}
              body={(rowData) => (
                <div className="w-full flex justify-content-center">
                  <Text color="#6E6B7B" weight={300} s="0.875rem">
                    {rowData.objective}
                  </Text>
                </div>
              )}
            />

            <Column
              headerStyle={{ padding: "5px 0.25rem" }}
              filterHeaderStyle={{ padding: "5px 0.25rem" }}
              style={{ minWidth: "100px" }}
              field="tag"
              align="left"
              header="Etapa"
              sortable={true}
              filter={true}
              showFilterMenu={false}
              body={(rowData) => (
                <div className="w-full flex justify-content-center">
                  {rowData.tag}
                </div>
              )}
            />

            <Column
              style={{ minWidth: "170px" }}
              headerStyle={{ padding: "5px 0.25rem" }}
              filterHeaderStyle={{ padding: "5px 0.25rem" }}
              field="firstMessage"
              align="left"
              sortable={true}
              header="Iniciado em"
              filter={true}
              showFilterMenu={false}
              filterElement={firstMessageRowFilterTemplate}
              body={(rowData) => (
                <div className="w-full flex justify-content-start">
                  <Text color="#6E6B7B" weight={300} s="0.875rem">
                    {formatDate(rowData.firstMessage)}
                  </Text>
                </div>
              )}
            />

            <Column
              style={{ minWidth: "170px" }}
              headerStyle={{ padding: "5px 0.25rem" }}
              filterHeaderStyle={{ padding: "5px 0.25rem" }}
              field="lastMessage"
              align="left"
              sortable={true}
              header="Última mensagem"
              filter={true}
              showFilterMenu={false}
              filterElement={lastMessageRowFilterTemplate}
              body={(rowData) => (
                <div className="w-full flex justify-content-start">
                  <Text color="#6E6B7B" weight={300} s="0.875rem">
                    {formatDate(rowData.lastMessage)}
                  </Text>
                </div>
              )}
            />

            <Column
              style={{ minWidth: "170px" }}
              headerStyle={{ padding: "5px 0.25rem" }}
              filterHeaderStyle={{ padding: "5px 0.25rem" }}
              field="statusTime"
              align="left"
              sortable={true}
              header="Horário Status"
              filter={true}
              showFilterMenu={false}
              body={(rowData) => (
                <div className="w-full flex justify-content-start">
                  {rowData.status === "Aberto" && (
                    <Text color="#6E6B7B" weight={300} s="0.875rem">
                      {formatDate(rowData.firstMessage)}
                    </Text>
                  )}

                  {rowData.status === "Finalizado" && (
                    <Text color="#6E6B7B" weight={300} s="0.875rem">
                      {formatDate(rowData.closedAt)}
                    </Text>
                  )}
                </div>
              )}
            />

            <Column
              style={{ minWidth: "130px" }}
              headerStyle={{ padding: "5px 0.25rem" }}
              filterHeaderStyle={{ padding: "5px 0.25rem" }}
              field="status"
              align="left"
              header="Status"
              sortable={true}
              filter={true}
              showFilterMenu={false}
              filterElement={statusRowFilterTemplate}
              alignHeader="left"
              body={statusBodyTemplate}
              frozen={true}
              alignFrozen="right"
            />
          </DataTable>
        )}
      </div>
    </Container>
  );
};
