import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Card = css`
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 25px;
  display: block;
  margin-bottom: 2rem;

  .p-paginator {
    border-radius: 0 0 10px 10px;
    background: #eaeaea;
  }

  .p-paginator .p-paginator-pages .p-paginator-page {
    height: auto;
    padding: 4px 12px;
    min-width: auto;
    margin: 0;
    font-size: 12px;
    background: transparent;
    border: none;
    color: #6e6b7b;
  }

  .p-paginator-pages {
    font-size: 12px !important;

    background: #f3f2f7;
    border-radius: 16px;
    padding: 4px;
  }

  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    border-radius: 29px;
    color: #0a043c;
    background-color: #eaeaea;
    border-color: #eaeaea;
  }

  .p-paginator .p-paginator-pages .p-paginator-page:hover {
    background: #e9ecef;
    border-color: #dee2e6;
    border-radius: 29px;
  }

  .css-18e5gj2 .p-paginator .p-paginator-prev {
    border-radius: 29px;
    margin-right: 10px;
  }

  .p-paginator .p-paginator-first,
  .p-paginator .p-paginator-prev,
  .p-paginator .p-paginator-next,
  .p-paginator .p-paginator-last {
    color: #6e6b7b;
  }

  .p-paginator .p-paginator-first,
  .p-paginator .p-paginator-prev,
  .p-paginator .p-paginator-next,
  .p-paginator .p-paginator-last {
    background: #f3f2f7;
    border-radius: 29px;
    margin-right: 10px;
    margin-left: 10px;
  }
`;

export const Title = css`
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 0.5rem;
  margin: 1.5rem 0 1rem 1.5rem;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #0a043c;
  font-family: "Poppins";
`;

export const Icon = css`
  background: #d4e2f3;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
interface IActive {
  active: boolean;
}
export const Active = styled.div<IActive>`
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  margin: 0 auto;
  background-color: ${(props) => (props.active ? "#6FCF97" : "#801515")};
`;

export const Button = css`
  height: 28px;
  background: #d4e2f3;
  border: 1px solid #dddddd;
  border-radius: 5px;
  color: #046af3;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  :hover {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    background-color: #dddddd;
    color: #ffffff;
  }
`;
