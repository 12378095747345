import { css } from "@emotion/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  InputText,
  Password,
  PoliticaPrivacidadeConfirmar
} from "../components";
import { useFetch, v } from "../util";
import {
  background,
  botRed,
  dialogosInteligentes,
  spaikBeforeAuth
} from "./../assets";
import Plataform from "../services/Plataform";

const schema = v.object({
  email: v.string().superRefine(v.nonempty()),
  name: v.string().superRefine(v.nonempty()),
  password: v.string().superRefine(v.nonempty()),
  phone: v.string().superRefine(v.nonempty())
});

export default () => {
  const { userMail, confirmationCode } = useParams();
  const userInvite = useFetch(`/api/user-invite/${confirmationCode}`, {
    method: "POST"
  });

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      email: userMail,
      name: "",
      password: "",
      passwordConfirm: "",
      phone: ""
    },
    resolver: zodResolver(schema)
  });

  const navigate = useNavigate();
  const location = useLocation();

  const from =
    (
      location.state as {
        from: {
          pathname: string;
        };
      }
    )?.from?.pathname || "/confirm/";

  return (
    <div
      className="grid grid-nogutter"
      css={css`
        height: 100vh;
      `}
    >
      <div
        className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6"
        css={css`
          background-image: ${Plataform.customLogin
            ? "none"
            : `url(${background})`};
          background-color: ${Plataform.customLogin
            ? "#2624CD"
            : "transparent"};
          background-repeat: no-repeat;
          background-size: cover;
          display: flex;
          flex-direction: column;
          flex: 1;
          align-items: center;
          justify-content: center;
        `}
      >
        <div
          css={css`
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
          `}
        >
          {Plataform.customLogin ? (
            <img
              src={spaikBeforeAuth}
              css={css`
                width: 700px;

                @media (width: 1600px) {
                  width: 520px;
                }

                @media (max-width: 1400px) {
                  width: 500px;
                }

                @media (max-width: 1280px) {
                  width: 450px;
                }

                @media (max-width: 1200px) {
                  width: 400px;
                }

                @media (max-width: 1050px) {
                  width: 350px;
                }

                @media (max-width: 950px) {
                  margin-top: 1rem;
                }
              `}
            />
          ) : (
            <img
              src={dialogosInteligentes}
              css={css`
                @media (max-width: 950px) {
                  max-width: 100%;
                  width: auto;
                  height: auto;
                }
              `}
            />
          )}
        </div>

        <div
          css={css`
            padding-bottom: 16px;
            color: white;
          `}
        >
          <p>©{Plataform.displayName}</p>
        </div>
      </div>

      <div
        className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6"
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1;
          align-items: center;
          justify-content: center;
        `}
      >
        <div
          css={css`
            display: flex;
            font-size: 12px;
            margin-top: 10px;
          `}
        >
          Já possui uma conta? &nbsp;
          <Link to="/login">Login</Link>
        </div>

        <div
          css={css`
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 400px;
            gap: 16px;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 16px;
              width: 100%;
            `}
          >
            <img
              src={Plataform.urlLogo ? Plataform.urlLogo : botRed}
              style={{ maxWidth: "70px", maxHeight: "70px" }}
            />
            <div>
              <span>Bem-vindo ao seu </span>
              <span
                css={css`
                  font-weight: 600;
                `}
              >
                {Plataform.displayName}
              </span>
              👋
              <div
                css={css`
                  font-weight: 600;
                `}
              >
                Faça o seu cadastro :)
              </div>
            </div>
          </div>

          <div
            css={css`
              margin-top: 25px;
              width: 100%;
            `}
          >
            <form
              onSubmit={handleSubmit(async ({ passwordConfirm, ...data }) => {
                const { userEmail } = await userInvite(data);
                navigate(`/login/${data.email}/${true}`, {
                  replace: true
                });
              })}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: 25px;
                `}
              >
                <div className="p-float-label">
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <InputText disabled={true} {...field} id="email" />
                    )}
                  />
                  <label htmlFor="email">E-mail</label>
                </div>

                <div className="p-float-label">
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => <InputText {...field} id="name" />}
                  />
                  <label htmlFor="name">Nome completo</label>
                </div>

                <div className="p-float-label">
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <InputText {...field} id="userPhone" />
                    )}
                  />
                  <label htmlFor="userPhone">Telefone</label>
                </div>

                <div className="p-float-label">
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => <Password {...field} id="passwd" />}
                  />
                  <label htmlFor="passwd">Criar senha</label>
                </div>

                <div className="p-float-label">
                  <Controller
                    name="passwordConfirm"
                    control={control}
                    render={({ field }) => (
                      <Password {...field} id="passwdConfirm" />
                    )}
                  />
                  <label htmlFor="passwd">Confirmar senha</label>
                </div>

                <Button
                  css={css`
                    width: 100%;
                  `}
                  label="Cadastrar"
                />
              </div>
            </form>
          </div>
        </div>

        <PoliticaPrivacidadeConfirmar />
      </div>
    </div>
  );
};
