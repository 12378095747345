import React, { useEffect, useState } from "react";
import {
  Chip,
  Container,
  Content,
  Header,
  Button as MyButtton
} from "./styles";
import * as comunCss from "../comum_css";
import { Contacts, workspace } from "../../assets";
import { InputText } from "primereact/inputtext";
import { Button, DataTable } from "..";
import { Column } from "primereact/column";
import { Text } from "../../components/Text";
import useCampaignsListing from "./use-campaignsListing";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useToast } from "../toast-provider";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { css } from "@emotion/react";
import { Dialog } from "primereact/dialog";
import { startOfMonth, subYears } from "date-fns";
import { FilterMatchMode, SortOrder } from "primereact/api";
import { MyCalendar } from "../MyCalendar";
import { AuthContextType, useAuth } from "../../auth/auth-provider";

const CampaignListing = () => {
  const { listCampaigns, startCampaign, pauseCampaign, cancelCampaign } =
    useCampaignsListing();
  const toast = useToast();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(20);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [idSelected, setIdSelected] = useState<number>(0);
  const [selectedAction, setSelectedAction] = useState<string | null>(null);
  const [showModalStart, setShowModalStart] = useState(false);
  const [showModalPause, setShowModalPause] = useState(false);
  const [showModalCancel, setShowModalCancel] = useState(false);
  const [updateDatatable, setUpdateDatatable] = useState(false);
  const { user, hasAnyPermission } = useAuth() as AuthContextType;
  const [statusOptions] = useState([
    { label: "Todos", value: null },
    { label: "Cancelada", value: "CANCELLED" },
    { label: "Em progresso", value: "IN_PROGRESS" },
    { label: "Enviada", value: "COMPLETED" },
    { label: "Pausada", value: "PAUSED" },
    { label: "Pendente", value: "PENDING" }
  ]);
  const [campaigns, setCampaigns] = useState([]);
  const selectedWorkspacesIds = useAppSelector(
    (state) => state.workspaces.selectedWorkspaces
  );
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    limit: 20,
    name: "",
    id: null,
    status: null,
    createdAtPeriod: {
      value: [startOfMonth(new Date()), new Date()],
      matchMode: FilterMatchMode.DATE_AFTER
    }
  });

  const fetchCampaigns = async () => {
    if (selectedWorkspacesIds.length < 1) {
      return;
    }

    const [startAt, endAt] = lazyParams.createdAtPeriod.value.map((date: any) =>
      date instanceof Date ? date.toISOString().split("T")[0] : null
    );
    if (!startAt || !endAt) {
      return;
    }
    try {
      const data = await listCampaigns({
        offset: lazyParams.first,
        limit: lazyParams.limit,
        workspaces: selectedWorkspacesIds,
        name: debouncedText,
        id: lazyParams.id,
        status: lazyParams.status,
        createdAtPeriod: {
          startAt,
          endAt
        }
      });

      setCampaigns(data.result);
      setTotalRecords(data.total);
    } catch (error) {
      toast?.current?.show({
        life: 3000,
        severity: "error",
        summary: "Erro.",
        detail: "Erro ao carregar as campanhas. Por favor tente mais tarde."
      });
    }
  };

  const formatDate = (inputDate: string): string => {
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  const statusBodyTemplate = (rowData: {
    status: string;
    firstMessage: Date;
    lastMessage: Date;
  }) => {
    if (rowData.status === "PENDING") {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Chip
            className="expand-icon-tooltip-time cursor-pointer"
            bgColor="#AEE9D1"
          >
            Pendente
          </Chip>
        </div>
      );
    }

    if (rowData.status === "CANCELLED") {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Chip
            className="expand-icon-tooltip-time cursor-pointer"
            bgColor="#EAEAEA"
          >
            Cancelada
          </Chip>
        </div>
      );
    }

    if (rowData.status === "IN_PROGRESS") {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Chip
            className="expand-icon-tooltip-time cursor-pointer"
            bgColor="#007bff"
          >
            Em progresso
          </Chip>
        </div>
      );
    }

    if (rowData.status === "COMPLETED") {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Chip
            className="expand-icon-tooltip-time cursor-pointer"
            bgColor="#AEE9D1"
          >
            Enviada
          </Chip>
        </div>
      );
    }

    if (rowData.status === "PAUSED") {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Chip
            className="expand-icon-tooltip-time cursor-pointer"
            bgColor="#FFC107"
          >
            Pausada
          </Chip>
        </div>
      );
    }

    // if (rowData.status === "FAILED") {
    //   return (
    //     <div
    //       style={{
    //         display: "flex",
    //         justifyContent: "center"
    //       }}
    //     >
    //       <Chip
    //         className="expand-icon-tooltip-time cursor-pointer"
    //         bgColor="#F44336"
    //       >
    //         Falha
    //       </Chip>
    //     </div>
    //   );
    // }

    return null;
  };

  const useDebounce = (value: string, delay: number) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const timer = setTimeout(() => setDebouncedValue(value), delay);

      return () => {
        clearTimeout(timer);
      };
    }, [value, delay]);
    return debouncedValue;
  };

  const debouncedText = useDebounce(lazyParams.name, 500);

  useEffect(() => {
    fetchCampaigns();
  }, [
    debouncedText,
    updateDatatable,
    lazyParams.createdAtPeriod,
    lazyParams.id,
    lazyParams.status,
    lazyParams.first,
    lazyParams.limit,
    first,
    selectedWorkspacesIds
  ]);

  useEffect(() => {
    setLazyParams((prev) => ({
      ...prev,
      name: debouncedText
    }));
  }, [debouncedText]);

  const handleInputSearch = (e: any) => {
    setLazyParams((prev) => ({
      ...prev,
      name: e.target.value
    }));
  };

  useEffect(() => {
    if (selectedAction === "continuar") {
      setShowModalStart(true);
    } else if (selectedAction === "pausar") {
      setShowModalPause(true);
    } else if (selectedAction === "cancelar") {
      setShowModalCancel(true);
    }
  }, [selectedAction]);

  const handleActionChange = (e: DropdownChangeParams, id: number) => {
    setIdSelected(id);
    setSelectedAction(e.value);
  };

  const actionBodyTemplate = (rowData: { id: number; status: string }) => {
    const permissionEditCampaign =
      user.admin || hasAnyPermission("campaign_edit");

    const getActionsByStatus = (status: string) => {
      if (status === "PAUSED") {
        return [
          { label: "Continuar", value: "continuar" },
          { label: "Cancelar", value: "cancelar" }
        ];
      } else if (status === "IN_PROGRESS" || status === "PENDING") {
        return [
          { label: "Pausar", value: "pausar" },
          { label: "Cancelar", value: "cancelar" }
        ];
      }
      return [];
    };

    const actions = getActionsByStatus(rowData.status);

    return (
      <Dropdown
        value={selectedAction}
        className="custom-dropdown"
        options={actions}
        onChange={(e) => handleActionChange(e, rowData.id)}
        style={{
          height: "30px"
        }}
        emptyMessage="Sem opções"
        disabled={!permissionEditCampaign}
      />
    );
  };

  const handleStartCampaign = async () => {
    try {
      await startCampaign(idSelected);
      toast?.current?.show({
        life: 3000,
        severity: "success",
        summary: "Sucesso!",
        detail: "Campanha retomada com sucesso."
      });
    } catch (error) {
      toast?.current?.show({
        life: 3000,
        severity: "error",
        summary: "Erro.",
        detail: "Erro ao continuar a campanha. Por favor tente mais tarde."
      });
    } finally {
      setUpdateDatatable(!updateDatatable);
      setShowModalStart(false);
    }
  };

  const handlePauseCampaign = async () => {
    try {
      await pauseCampaign(idSelected);
      toast?.current?.show({
        life: 3000,
        severity: "success",
        summary: "Sucesso!",
        detail: "Campanha pausada com sucesso."
      });
    } catch (error) {
      toast?.current?.show({
        life: 3000,
        severity: "error",
        summary: "Erro.",
        detail: "Erro ao pausar a campanha. Por favor tente mais tarde.",
        sticky: true
      });
    } finally {
      setUpdateDatatable(!updateDatatable);
      setShowModalPause(false);
    }
  };

  const handleCancelCampaign = async () => {
    try {
      await cancelCampaign(idSelected);
      toast?.current?.show({
        life: 3000,
        severity: "success",
        summary: "Sucesso!",
        detail: "Campanha cancelada com sucesso."
      });
    } catch (error) {
      toast?.current?.show({
        life: 3000,
        severity: "error",
        summary: "Erro.",
        detail: "Erro ao cancelar a campanha. Por favor tente mais tarde.",
        sticky: true
      });
    } finally {
      setUpdateDatatable(!updateDatatable);
      setShowModalCancel(false);
    }
  };

  const createdIn = (options: any) => (
    <MyCalendar
      value={lazyParams.createdAtPeriod.value}
      selectionMode="range"
      onChange={(e) => {
        const dates = e.value;

        setLazyParams((prev) => ({
          ...prev,
          createdAtPeriod: {
            ...prev.createdAtPeriod,
            value: dates
          }
        }));

        // options.filterApplyCallback(dates);
      }}
      dateFormat="dd/mm/yy"
      placeholder="Período"
      mask="99/99/9999"
      locale="pt-br"
      readOnlyInput={true}
      inputStyle={{
        padding: "10px",
        borderRadius: "5px",
        backgroundColor: "#ffffff",
        cursor: "pointer"
      }}
    />
  );

  const onFilter = (event: any) => {
    const updatedFilters = Object.keys(event.filters).reduce((acc, key) => {
      const filter = event.filters[key];

      if (key === "createdAtPeriod" && filter.value) {
        acc[key] = {
          ...filter,
          value: Array.isArray(filter.value)
            ? filter.value.map((v) => (v instanceof Date ? v : new Date(v)))
            : filter.value instanceof Date
            ? filter.value
            : new Date(filter.value)
        };
      } else {
        acc[key] = filter.value ? { ...filter, value: filter.value } : null;
      }

      return acc;
    }, {});

    setLazyParams((prev) => ({
      ...prev,
      ...Object.keys(updatedFilters).reduce((acc, key) => {
        acc[key] = updatedFilters[key]?.value || null;
        return acc;
      }, {})
    }));
  };

  const statusRowFilterTemplate = (options: any) => (
    <Dropdown
      value={lazyParams.status}
      options={statusOptions}
      onChange={(e) => {
        setLazyParams((prev) => ({
          ...prev,
          status: e.value
        }));

        // options.filterApplyCallback(e);
      }}
      placeholder="Selecione"
      className="p-column-filter"
      showClear={false}
      scrollHeight="auto"
    />
  );

  const onPage = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);

    setLazyParams((prev) => ({
      ...prev,
      first: event.first,
      limit: event.rows
    }));
  };

  const paginatorCustomTemplate = {
    layout: "RowsPerPageDropdown PrevPageLink PageLinks NextPageLink",
    RowsPerPageDropdown: (options: {
      value: number;
      onChange: ((e: DropdownChangeParams) => void) | undefined;
    }) => {
      const dropdownOptions = [
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: 100, value: 100 }
      ];

      return (
        <div
          style={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <span
            style={{
              minWidth: "fit-content"
            }}
          >
            Por página:
          </span>

          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
            css={css`
              .p-inputtext {
                color: #6e6b7b;
              }
            `}
          />
        </div>
      );
    }
  };

  return (
    <Container>
      <div>
        <div className="p-5 gap-2">
          <Header>
            <div
              css={comunCss.TitleContent}
              style={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              <img src={Contacts} width="25px" />
              Gestão de campanhas
            </div>
          </Header>

          <Content>
            <div className="divTitle">
              Uma campanha permite o envio de mensagens em massa para vários
              contatos.
            </div>

            <div className="divTitle">
              <div className="divFilter">
                <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText
                    id="name"
                    value={lazyParams.name || ""}
                    onChange={handleInputSearch}
                    style={{
                      height: "100%",
                      width: "400px"
                    }}
                    placeholder="Buscar"
                  />
                </span>
              </div>

              <DataTable
                className="custom-datatable"
                value={campaigns}
                paginator={true}
                rows={rows}
                first={first}
                filterDelay={600}
                emptyMessage="Nenhum resultado encontrado"
                filterDisplay="row"
                onFilter={onFilter}
                // filters={lazyParams.filters}
                totalRecords={totalRecords}
                // onSort={onSort}
                onPage={onPage}
                lazy={true}
                scrollHeight="true"
                rowsPerPageOptions={[20, 50, 100]}
                paginatorTemplate={paginatorCustomTemplate}
              >
                <Column
                  field="id"
                  header="ID"
                  headerStyle={{ width: "150px" }}
                  alignHeader="center"
                  align="center"
                  filter={true}
                  showFilterMenu={false}
                />

                <Column
                  field="name"
                  header="Nome"
                  headerStyle={{ width: "500px" }}
                  alignHeader="center"
                  filter={true}
                  showFilterMenu={false}
                />

                <Column
                  field="createdAt"
                  header="Criado em"
                  headerStyle={{ width: "450px" }}
                  alignHeader="center"
                  filter={true}
                  showFilterMenu={false}
                  filterElement={createdIn}
                  body={(rowData) => (
                    <div className="w-full flex justify-content-center">
                      <Text color="#6E6B7B" weight={300} s="0.875rem">
                        {formatDate(rowData.createdAt)}
                      </Text>
                    </div>
                  )}
                />

                <Column
                  field="totalMessagesSent"
                  header="Enviadas"
                  headerStyle={{ width: "100px" }}
                  alignHeader="center"
                  align="center"
                  body={(rowData) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "3px 0"
                      }}
                    >
                      <Chip
                        className="expand-icon-tooltip-time cursor-pointer"
                        bgColor="#EAEAEA"
                        style={{ width: "70px" }}
                      >
                        {rowData.totalMessagesSent}
                      </Chip>
                    </div>
                  )}
                />

                <Column
                  field="totalMessagesPending"
                  header="Pendentes"
                  headerStyle={{ width: "100px" }}
                  alignHeader="center"
                  align="center"
                  body={(rowData) => (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Chip
                        className="expand-icon-tooltip-time cursor-pointer"
                        bgColor="#EAEAEA"
                        style={{ width: "70px" }}
                      >
                        {rowData.totalMessagesPending}
                      </Chip>
                    </div>
                  )}
                />

                <Column
                  field="totalMessagesCanceled"
                  header="Falhas"
                  headerStyle={{ width: "100px" }}
                  alignHeader="center"
                  align="center"
                  body={(rowData) => (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Chip
                        className="expand-icon-tooltip-time cursor-pointer"
                        bgColor="#EAEAEA"
                        style={{ width: "70px" }}
                      >
                        {rowData.totalMessagesFailed}
                      </Chip>
                    </div>
                  )}
                />

                <Column
                  field="totalMessages"
                  header="Canceladas"
                  headerStyle={{ width: "100px" }}
                  alignHeader="center"
                  align="center"
                  body={(rowData) => (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Chip
                        className="expand-icon-tooltip-time cursor-pointer"
                        bgColor="#EAEAEA"
                        style={{ width: "70px" }}
                      >
                        {rowData.totalMessagesCanceled}
                      </Chip>
                    </div>
                  )}
                />

                <Column
                  field="totalMessages"
                  header="Total"
                  headerStyle={{ width: "100px" }}
                  alignHeader="center"
                  align="center"
                  body={(rowData) => (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Chip
                        className="expand-icon-tooltip-time cursor-pointer"
                        bgColor="#EAEAEA"
                        style={{ width: "70px" }}
                      >
                        {rowData.totalMessages}
                      </Chip>
                    </div>
                  )}
                />

                <Column
                  style={{ minWidth: "160px" }}
                  headerStyle={{ padding: "5px 0.25rem" }}
                  filterHeaderStyle={{ padding: "5px 0.25rem" }}
                  field="status"
                  align="center"
                  header="Status"
                  alignHeader="center"
                  filter={true}
                  showFilterMenu={false}
                  filterElement={statusRowFilterTemplate}
                  body={statusBodyTemplate}
                  alignFrozen="right"
                />

                <Column
                  style={{ minWidth: "65px" }}
                  field="id"
                  header="Ação"
                  body={actionBodyTemplate}
                />
              </DataTable>
            </div>
          </Content>
        </div>
      </div>
      <Dialog
        header="Atualização da campanha"
        visible={showModalStart}
        onHide={() => {
          setShowModalStart(false);
          setSelectedAction(null);
        }}
        draggable={false}
        resizable={false}
        style={{ width: "425px" }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
          <div>Tem certeza de que deseja retomar essa campanha?</div>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              label="Sim"
              className="p-button-danger ml-2 mr-2"
              onClick={handleStartCampaign}
            />
            <Button
              label="Não"
              className="p-button-secondary"
              onClick={() => {
                setShowModalStart(false);
                setSelectedAction(null);
              }}
            />
          </div>
        </div>
      </Dialog>

      <Dialog
        header="Atualização da campanha"
        visible={showModalPause}
        onHide={() => {
          setShowModalPause(false);
          setSelectedAction(null);
        }}
        draggable={false}
        resizable={false}
        style={{ width: "425px" }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
          <div>Tem certeza de que deseja pausar essa campanha?</div>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              label="Sim"
              className="p-button-danger ml-2 mr-2"
              onClick={handlePauseCampaign}
            />
            <Button
              label="Não"
              className="p-button-secondary"
              onClick={() => {
                setShowModalPause(false);
                setSelectedAction(null);
              }}
            />
          </div>
        </div>
      </Dialog>

      <Dialog
        header="Atualização da campanha"
        visible={showModalCancel}
        onHide={() => {
          setShowModalCancel(false);
          setSelectedAction(null);
        }}
        draggable={false}
        resizable={false}
        style={{ width: "425px" }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
          <div>Tem certeza de que deseja cancelar essa campanha?</div>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              label="Sim"
              className="p-button-danger ml-2 mr-2"
              onClick={handleCancelCampaign}
            />
            <Button
              label="Não"
              className="p-button-secondary"
              onClick={() => {
                setShowModalCancel(false);
                setSelectedAction(null);
              }}
            />
          </div>
        </div>
      </Dialog>
    </Container>
  );
};

export default CampaignListing;
