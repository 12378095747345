import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const CheckCard = css`
  padding: 0.7rem;
  border: 2px solid #acd0f6;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  justify-content: space-evenly;
  display: flex;
  color: #999999;
  margin-top: 1.1rem;
  height: 50px;

  @media (min-width: 1921px) {
    width: 190px;
  }
`;
export const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 22px;
  height: 22px;
  right: -11px;
  top: -11px;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 800px;
`;
export const ContainerLeftModal = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  top: 0px;
  content: none;
  background-color: white;
  padding: 0 0 1rem 1rem;
`;
export const ContainerRightModal = styled.div`
  width: 68.3%;
  position: relative;
  height: 100%;
  top: 0px;
  content: none;
  padding: 1rem;
  display: flex;
  align-items: start;
  justify-content: space-between;
`;

export const Label = styled.label`
  @media (max-width: 1921px) {
    font-size: 0.75rem;
    /* display: none; */
  }
  @media (min-width: 1921px) {
    font-size: 1rem;
    /* display: none; */
  }
`;
export const HiddenDiv = styled.div`
  @media (max-width: 1921px) {
    display: none;
  }
`;
