import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { getIncomers, Node } from "react-flow-renderer";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import {
  setAdvisor,
  setModalOpenRobotActions,
  setOverlayInteractionsShow
} from "../../../../../../redux/slices/flowInteractions";
import { v4 as uuidv4 } from "uuid";
import { Attribute } from "./Attribute";
import { Modal, Overlay, Textp, Title } from "./styles";
import { botRed } from "../../../../../../assets";
import { NodeData } from "../../../../../react-flow-renderer/NodeData";
import { BaseEditor } from "slate";
import { ReactEditor } from "slate-react";
import { HistoryEditor } from "slate-history";
import { AuthContextType, useAuth } from "../../../../../../auth/auth-provider";
// const useDebounce = (value: string, delay: number) => {
//   const [debouncedValue, setDebouncedValue] = useState(value);

//   useEffect(() => {
//     const timer = setTimeout(() => setDebouncedValue(value), delay);

//     return () => {
//       clearTimeout(timer);
//     };
//   }, [value, delay]);
//   return debouncedValue;
// };
// interface IEntitiesResult {
//   result: IEntities[];
//   total: number;
// }

// interface IEntities {
//   id: number;
//   name: string;
// }

interface IEntity {
  entityTypeId?: number;
  entityTypeName?: string;
  nodeSourceID: string;
  userInteractionId?: string;
}
interface IVariables {
  handleInsertMentions: (
    variable: string,
    editor: BaseEditor & ReactEditor & HistoryEditor,
    nodeSourceEntityId: string,
    userInteractionId: string
  ) => void;
  editor: BaseEditor & ReactEditor & HistoryEditor;
  modalOpen: boolean;
  setModalOpen: (modalOpen: boolean) => void;
}
export const VariablesModal: React.FC<IVariables> = ({
  handleInsertMentions,
  editor,
  modalOpen,
  setModalOpen
}) => {
  const dispatch = useAppDispatch();
  const toggleModalVariables = useAppSelector(
    (state) => state.flowInteractions.modalVariablesBotInteractions
  );
  const edges = useAppSelector((state) => state.edges);
  const toast = useRef<Toast>(null);
  const nodes = useAppSelector((state) => state.nodes);

  const { user } = useAuth() as AuthContextType;

  const nodeSelected = nodes.find((node) => node.selected === true);
  const [entitiesBefore, setEntitiesBefore] = useState<IEntity[]>([]);

  const handleSetAttribute = (entity: IEntity) => {
    const entityTypeName = entity?.entityTypeName || "";
    const nodeSourceID = entity?.nodeSourceID || "";
    const userInteractionId = entity?.userInteractionId || "";
    // const entityTypeId = entity?.entityTypeId;
    // dispatch(setAttribute({
    //   entityTypeId,
    //   entityTypeName,
    //   sourceEntityNodeId: nodeSourceID,
    //   nodeId: nodeSelected?.id as string,
    //   botInteractionID: botInteractionIDActive
    // }))
    handleInsertMentions(
      entityTypeName,
      editor,
      nodeSourceID,
      userInteractionId
    );
    dispatch(setModalOpenRobotActions(false));
    dispatch(setOverlayInteractionsShow(false));
    dispatch(setAdvisor(false));
  };
  const getAllIncomersVariables = (
    sources: Node<NodeData>[] | undefined,
    nodeTarget: Node<NodeData>,
    acc: IEntity[] = []
  ) => {
    // Busca todos os antecessores
    // let accumulator: Node<NodeData>[] = acc;
    const entities: IEntity[] = acc;
    // eslint-disable-next-line array-callback-return
    sources?.map((source: Node<NodeData>) => {
      const incomersNodes = getIncomers(source as Node, nodes, edges);
      source.data.userInteractions.map((ui) => {
        if (ui.target === nodeTarget.id) {
          if (ui.entityTypeName !== "") {
            entities.push({
              entityTypeId: ui?.entityTypeId,
              entityTypeName: ui?.entityTypeName,
              nodeSourceID: source?.id,
              userInteractionId: ui.id
            });
            return getAllIncomersVariables(incomersNodes, source, entities);
          }
          return getAllIncomersVariables(incomersNodes, source, entities);
        }
        return ui;
      });
    });
    return entities;
  };

  useEffect(() => {
    const getAllIncomersInternal = () => {
      // pegar os primeiros incomes
      const nodeSelectedIncomers = getIncomers(
        nodeSelected as Node<NodeData>,
        nodes,
        edges
      );
      const entities: IEntity[] = getAllIncomersVariables(
        nodeSelectedIncomers,
        nodeSelected as Node<NodeData>
      );

      // retirar os duplicados
      const uniques: IEntity[] = [];
      entities.forEach((x) => {
        if (!uniques.some((y) => JSON.stringify(y) === JSON.stringify(x))) {
          uniques.push(x);
        }
      });
      setEntitiesBefore(uniques);
    };
    if (nodeSelected) {
      getAllIncomersInternal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeSelected?.id]);

  return (
    <Overlay style={{ display: modalOpen ? "block" : "none" }}>
      <Toast ref={toast} />
      <Modal style={{ display: modalOpen ? "block" : "none" }} open={modalOpen}>
        <Button
          onClick={() => {
            dispatch(setOverlayInteractionsShow(false));
            dispatch(setAdvisor(false));
            setModalOpen(false);
          }}
          icon="pi pi-times"
          className="p-button-rounded p-button-danger p-button-text p-button-sm ml-auto"
          aria-label="Cancel"
        />

        <div className="flex mt-2 w-full justify-content-start align-items-center">
          <img
            src={user.plataform.urlLogo ? user.plataform.urlLogo : botRed}
            style={{ maxWidth: "65px", maxHeight: "65px" }}
          />
          <div className="ml-2 mt-2 flex-grow-1 justify-content-start align-items-start">
            <p css={Title}>O que são variáveis?</p>
            <p css={Textp}>
              Uma variável é um dado previamente armazenado em nodes Anteriores
              no fluxo. Ele pode ser utilizado pelo seu robô em um diálogo.
            </p>
          </div>
        </div>

        <p>Exemplo</p>
        <p>Qual é o seu nome, por gentileza? (nome é uma variável)</p>
        <p>
          Para utilizar uma variável em uma conversa você deve usar ex. @name.
        </p>

        {entitiesBefore?.map((entity) => (
          <Attribute
            key={uuidv4()}
            onClick={() => handleSetAttribute(entity)}
            name={
              `${entity.entityTypeName}-${
                nodes.find((_node) => _node.id === entity.nodeSourceID)?.data
                  ?.label
              }` as string
            }
            entityTypeId={entity.entityTypeId as number}
            entityTypeName={entity.entityTypeName as string}
            nodeSourceID={entity.nodeSourceID}
          />
        ))}
      </Modal>
    </Overlay>
  );
};
