import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { useRef, useState } from "react";
import { AuthContextType, useAuth } from "../../../../auth/auth-provider";
import Button from "../../../../components/button";
import { Text } from "../../../../components/Text";
import { BotBlue, botRed } from "./../../../../assets/";
import { Container, Content, Icon, Info, Textarea, Title } from "./styles";

export interface ChatbotScriptProps {
  botToken: string;
}

export const ChatbotScript = (props: ChatbotScriptProps) => {
  const [state, setState] = useState<string>("gerar");
  const [script, setScript] = useState<string>(
    "Deseja instalar nosso LiveChat em seu site?\nClique no botão para gerar um novo script."
  );
  const scriptRef = useRef<InputTextarea>(null);

  const { user } = useAuth() as AuthContextType;

  const toastRef = useRef<Toast>(null);

  return (
    <>
      <Toast ref={toastRef} />

      {/* <div css={Card}> */}
      <div css={Title}>
        <div css={Icon}>
          <img
            src={user.plataform.urlLogo ? user.plataform.urlLogo : botRed}
            style={{ maxWidth: "65px", maxHeight: "65px" }}
          />
        </div>
        <div className="flex flex-column align-items-start justify-content-start">
          <Text s="14px" weight={600} color="#0A043C">
            LiveChat
          </Text>
          <Text s="12px" lh="18px" color="#544F77" weight={400}>
            Integre o seu LiveChat ao seu site, plataforma...
          </Text>
        </div>
      </div>
      <Container>
        <div css={Content}>
          <InputTextarea
            ref={scriptRef}
            value={script}
            readOnly={true}
            css={Textarea}
          />

          <Button
            style={{
              border: "none",
              background: user.plataform.mainColor
                ? user.plataform.mainColor
                : "#046af3"
            }}
            label={state === "gerar" ? "Gerar" : "Copiar"}
            onClick={() => {
              if (state === "gerar") {
                setState("copiar");
                setScript(
                  `<link rel="stylesheet" type="text/css" href="https://${process.env.CHAT_URL}/main.css" />\n` +
                    "<script>\n" +
                    "  (() => {\n" +
                    `    window.icwToken = "${props.botToken}";\n` +
                    '    const script = document.createElement("script");\n' +
                    `    script.src = "https://${process.env.CHAT_URL}/main.js";\n` +
                    "    document.head.appendChild(script);\n" +
                    "  })();\n" +
                    "</script>"
                );
              } else {
                navigator.clipboard.writeText(script);
                toastRef.current?.show({
                  severity: "success",
                  detail: "Script copiado com sucesso."
                });
              }
            }}
          />

          <div css={Info}>
            Para instalar o widget do {user.plataform.displayName} no seu site,
            instale o código acima no seu arquivo de template ou em todas as
            páginas do seu site logo acima da tag de fechamento &lt;/head&gt;
          </div>
        </div>
      </Container>
      {/* </div> */}
    </>
  );
};
