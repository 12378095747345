import { QrCode } from "../../assets";
import { css } from "@emotion/react";
import { AuthContextType, useAuth } from "../../auth/auth-provider";
import Avatar from "../avatar";
import { useState } from "react";
import DialogPadrao from "../../components/DialogPadrao";
import { ContainerLeftModal, ContainerRightModal } from "../qrCode/styles";
import { RightModalBox } from "../qrCode/right-modal-box";
import { LeftDisabledBox } from "../qrCode/left-disabled-box";
import { LeftActiveBox } from "../qrCode/left-active-box";

const IdentificationBar = () => {
  interface IWhatsappInstanceInfo {
    error: string;
    message: string;
    instance_data: {
      instance_key: string;
      phone_connected: string;
      webhookUrl: string;
      user: {
        id: string;
        name: string;
        phone: string;
      };
    };
  }

  const [whatsappInstanceInfo, setWhatsappInstanceInfo] =
    useState<IWhatsappInstanceInfo>({} as IWhatsappInstanceInfo);

  const { user } = useAuth() as AuthContextType;

  const [displayModal, setDisplayModal] = useState(false);
  const [leftBoxState, setActiveLeftBox] = useState(false);

  const userProfiles = () => {
    let profiles = "";

    if (user.profiles.length > 1) {
      user.profiles.forEach((element, index) => {
        if (index > 0) {
          profiles += ", ";
        }

        profiles += element;
      });
    } else {
      profiles = user.profiles[0];
    }

    return profiles;
  };

  const onDesconnect = () => setDisplayModal(false);

  const getWhatsappInstanceInfo = (instanceInfo: IWhatsappInstanceInfo) => {
    if (instanceInfo?.instance_data?.phone_connected == "true") {
      setWhatsappInstanceInfo(instanceInfo);

      setActiveLeftBox(true);
    } else {
      setActiveLeftBox(false);
    }
  };

  return (
    <div
      css={css`
        align-items: center;
        border-bottom: 1px solid #282d35;
        display: flex;
        padding: 12px;

        @media screen and (max-width: 499px) {
          gap: 0.5rem;
        }
      `}
    >
      <Avatar name={user.name} />

      <div>
        <div
          css={css`
            align-items: center;
            text-overflow: ellipsis;
            white-space: nowrap
            font-size: 16px;
            width: 100%;
            margin-right: 5px;
            overflow: hidden;
            color: #ffffff;
          `}
        >
          Olá, {user.name}
        </div>

        <div
          css={css`
            align-items: center;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 12px;
            width: 200px;
            margin-right: 5px;
            overflow: hidden;
            color: #90a0b7;
          `}
        >
          {userProfiles()}
        </div>
      </div>

      <img
        css={css`
          @media screen and (max-width: 499px) {
            display: none;
          }
        `}
        src={QrCode}
        width={25}
        height={25}
        onClick={() => setDisplayModal(true)}
      />

      <DialogPadrao
        displayDialog={displayModal}
        contentStyle={{ height: "475px" }}
        onHide={() => setDisplayModal(false)}
        headerStyle={{
          backgroundColor: user.plataform.mainColor || "#ff0025"
        }}
      >
        <ContainerLeftModal>
          {leftBoxState ? (
            <LeftActiveBox
              name={whatsappInstanceInfo.instance_data.user.name}
              phone={whatsappInstanceInfo.instance_data.user.phone}
              emitDisconnect={onDesconnect}
            />
          ) : (
            <LeftDisabledBox />
          )}
        </ContainerLeftModal>

        <ContainerRightModal
          style={{ backgroundColor: user.plataform.mainColor || "#ff0025" }}
        >
          <RightModalBox emitWhatsappInfo={getWhatsappInstanceInfo} />
        </ContainerRightModal>
      </DialogPadrao>
    </div>
  );
};

export default IdentificationBar;
