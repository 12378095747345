import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Girl1,
  ImportScriptHome,
  createRoboImge,
  CreateRoboSpaik,
  humanAttendent,
  useOurTemplate,
  OmnichannelSpaik
} from "../../../assets";
import { Text } from "../../../components/Text";
import DialogCreateFromZero from "../../../components/template/addBlocks/DialogCreateFromZero";
import { useAppSelector } from "../../../redux/hooks";
import { CardHome } from "./Components/CardHome";
import {
  BgGradient,
  Container,
  ContainerImage,
  ContainerTransparent,
  DivImage,
  Img
} from "./styles";
import { useAuth, AuthContextType } from "../../../auth/auth-provider";

export const HomePage: React.FC = () => {
  const bots = useAppSelector((state) => state.chatbots.chatbots);
  const [openDialogCreateFromZero, setOpenDialogCreateFromZero] =
    useState(false);
  const redirect = useNavigate();
  const handleOmniChannel = () => {
    redirect("/omnichannel");
  };
  const handleOpenDialogCreateFromZero = () => {
    setOpenDialogCreateFromZero(true);
  };
  const { user } = useAuth() as AuthContextType;

  return (
    <Container>
      <BgGradient
        style={{
          background: user.plataform.mainColor
            ? user.plataform.mainColor
            : "linear-gradient(154.77deg, #046af3 9.18%, #95c2ff 90.33%)"
        }}
      />
      <ContainerTransparent>
        <div className="flex gap-3">
          <ContainerImage>
            <DivImage>
              {user.plataform.urlAvatar ? (
                <Img
                  src={user.plataform.urlAvatar}
                  alt="Bot Girl"
                  style={{
                    maxHeight: "350px",
                    width: "65px",
                    height: "75px"
                  }}
                />
              ) : (
                <Img src={Girl1} alt="Bot Girl" />
              )}
            </DivImage>
          </ContainerImage>
          <div className="flex flex-column align-itens-start justify-content-between h-4rem">
            <Text color="#FFF" weight={600} s="1.25rem" lh="1.75rem">
              Atendimento inteligente e automatizado
            </Text>
            <Text
              color="#FFF"
              weight={400}
              s="0.875rem"
              lh="1.25rem"
              style={{ textAlign: "left" }}
            >
              {`${bots?.length || 0} Bot${bots?.length > 1 ? "s" : ""}`} | 2
              canais conectados | Ver Insights
            </Text>
          </div>
        </div>
        <div className="flex align-itens-start w-full justify-content-between">
          <div className="flex mt-4 w-full align-items-center justify-content-between gap-2 flex-wrap">
            {user.plataform.id === 3 || user.plataform.id === 4 ? (
              <>
                <CardHome
                  onClick={handleOpenDialogCreateFromZero}
                  title="Criar Bot do zero"
                  subtitle={`Utilize a área do ${user.plataform.displayName} para criar um chatbot de forma prática e personalizada.`}
                >
                  <img width="70%" src={CreateRoboSpaik} alt="Create Robot" />
                </CardHome>
                <CardHome
                  onClick={handleOmniChannel}
                  title="Omnichannel"
                  subtitle="Realize atendimentos em um ambiente totalmente integrado."
                >
                  <img width="70%" src={OmnichannelSpaik} alt="Create Robot" />
                </CardHome>
              </>
            ) : (
              <>
                <CardHome
                  onClick={handleOpenDialogCreateFromZero}
                  title="Criar Bot do zero"
                  subtitle={`Utilize a área do ${user.plataform.displayName} para criar um chatbot de forma prática e personalizada.`}
                >
                  <img width="70%" src={createRoboImge} alt="Create Robot" />
                </CardHome>
                <CardHome
                  onClick={handleOmniChannel}
                  title="Omnichannel"
                  subtitle="Realize atendimentos em um ambiente totalmente integrado."
                >
                  <img width="70%" src={useOurTemplate} alt="Create Robot" />
                </CardHome>
              </>
            )}

            <CardHome
              proximoLancamento={true}
              title="Usar nossos templates"
              subtitle="Escolha um template pronto e facilite toda implementação do seu robô."
            >
              <img width="70%" src={humanAttendent} alt="Create Robot" />
            </CardHome>
            <CardHome
              proximoLancamento={true}
              title="Importar Script"
              subtitle="Importe Scripts de Conversação de forma fácil e prática."
            >
              <img width="100%" src={ImportScriptHome} alt="Create Robot" />
            </CardHome>
          </div>
        </div>
      </ContainerTransparent>
      <DialogCreateFromZero
        displayDialog={openDialogCreateFromZero}
        onHide={() => setOpenDialogCreateFromZero(false)}
      />
    </Container>
  );
};
