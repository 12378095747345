import { ReactSVG } from "react-svg";

interface ColorSvgProps {
  src: string;
  color?: string;
  size?: number;
}

const ColorSvg = ({ src, color = "#02CDAD", size = 25 }: ColorSvgProps) => (
  <div style={{ pointerEvents: "none" }}>
    <ReactSVG
      src={src}
      beforeInjection={(svg) => {
        svg.querySelectorAll("[fill]").forEach((el) => {
          el.setAttribute("fill", color);
        });

        svg.querySelectorAll("[stroke]").forEach((el) => {
          el.setAttribute("stroke", color);
        });

        svg.setAttribute("width", `${size}px`);
        svg.setAttribute("height", `${size}px`);

        svg.setAttribute("pointer-events", "none");
      }}
    />
  </div>
);

export default ColorSvg;
