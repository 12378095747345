import {
  BotId,
  ButtonOpenModal,
  ButtonStyle,
  ButtonThreePoints,
  Card,
  DivImgAtualizadoPor,
  Divisor,
  DropDownArea,
  FlexSB,
  ImgAtualizadoPor,
  MainImage,
  MainImageDiv,
  SelectThreePoints,
  TextContent,
  TextSubTitle,
  Title,
  QrCodeDiv,
  ContainerLeftModal,
  DivImg,
  ContainerRightModal,
  Img
} from "./styles";
import {
  setActive,
  setCompanyId,
  setDefaultFallBackMessage,
  setFirstBotInteractions,
  setName,
  setTarget,
  setWorkspaceId
} from "../../../../redux/slices/bot";
import {
  ThreePointsBlue,
  girl,
  RLAvatar,
  QrCode,
  Clone as CloneImg
} from "../../../../assets";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  setBotIdActive,
  setTokenActive
} from "../../../../redux/slices/flowInteractions";
import { AuthContextType, useAuth } from "../../../../auth/auth-provider";
import BotsServices from "../../../../services/bots";
import { useEffect, useState } from "react";
import { Text } from "../../../../components/Text/text";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { v } from "../../../../util";
import { Dropdown, InputText, useToast } from "../../..";
import { IoMdPlayCircle } from "react-icons/io";
import DialogPadrao from "../../../../components/DialogPadrao";
import { Button } from "primereact/button";

interface IChatbot {
  active: boolean;
  createdAt: string;
  dialogs: number;
  id: number;
  name: string;
  updatedAt: string;
  updatedBy: string;
  activeFlowId: number;
  token: string;
  target: number;
  isWhatsappQrCodeConnected: boolean;
}
export const Chatbot: React.FC<IChatbot> = ({
  active,
  createdAt,
  dialogs,
  id,
  name,
  updatedAt,
  updatedBy,
  activeFlowId,
  token,
  target,
  isWhatsappQrCodeConnected
}) => {
  const redirect = useNavigate();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const { botId } = useParams();
  const { GetBotResume, UpdateBot } = BotsServices();
  const { company } = useAuth() as AuthContextType;
  const { user } = useAuth() as AuthContextType;
  const [showModal, setShowModal] = useState(false);
  const [showEditScript, setShowEditScript] = useState(true);
  const { Clone } = BotsServices();
  const toast = useToast();
  const errorNameMessage = () => errors.name?.message;
  const errorWorkspaceIdMessage = () => errors.workspaceId?.message;
  const navigate = useNavigate();
  const botIdDuplicateBot = useAppSelector(
    (state) => state.flowInteractions.botIdActive
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset
  } = useForm<{ name: string; workspaceId: number }>({
    defaultValues: {
      name: "",
      workspaceId: undefined
    },
    resolver: zodResolver(
      v.object({
        name: v.string().superRefine(v.nonempty()),
        workspaceId: v.number().optional()
      })
    )
  });

  useEffect(() => {
    if (target === 4 || target === 6) {
      setShowEditScript(false);
    }
  }, []);

  const handleBotIdActive = (id: number) => {
    dispatch(setBotIdActive(id));
    dispatch(setTokenActive(token));
    redirect(`/chatbots/${id}`);
  };

  useEffect(() => {
    const BotId = botId ? parseInt(botId) : 0;
    const getResumeBot = async () => {
      dispatch(setBotIdActive(BotId));
    };
    if (!isNaN(BotId)) {
      getResumeBot();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botId]);
  const handleEditarScript = async (BotId: number) => {
    dispatch(setBotIdActive(BotId));
    const activeFlowId = await GetBotResume(BotId);
    dispatch(setName(activeFlowId?.name));
    dispatch(setActive(activeFlowId?.active));
    dispatch(setTarget(activeFlowId?.target));
    dispatch(setDefaultFallBackMessage(activeFlowId?.defaulFallbackMessages));
    dispatch(setCompanyId(company?.id));
    dispatch(setWorkspaceId(activeFlowId?.workspaceId));
    dispatch(setFirstBotInteractions(activeFlowId?.liveChatInteractions));

    redirect(`/drawflow/${BotId}/${activeFlowId?.activeBotFlowId}`);
  };
  const toggleOpenDiv = (BotId: number) => {
    dispatch(setBotIdActive(BotId));
    setOpen(!open);
  };

  const sendForm = handleSubmit(async (data) => {
    try {
      await Clone({
        name: data.name,
        botSourceId: botIdDuplicateBot,
        workspaceId: data.workspaceId
      }).then(() => {
        reset();
        setShowModal(false);
        toast?.current?.show({
          severity: "success",
          summary: "Sucesso",
          detail: "Bot clonado com sucesso!!",
          life: 3000
        });
        navigate({ pathname: "/chatbots" });
      });
    } catch (e: any) {
      toast?.current?.show({
        severity: "error",
        summary: "Erro",
        detail: e?.message,
        life: 3000
      });
    }
  });

  return (
    <div css={Card}>
      <DropDownArea>
        <QrCodeDiv>
          {isWhatsappQrCodeConnected && (
            <img src={QrCode} alt="Icon-WhatsApp" />
          )}
        </QrCodeDiv>
        <ButtonThreePoints onClick={() => toggleOpenDiv(id)}>
          <img src={ThreePointsBlue} alt="Icon-ThreePointsBlue" />
        </ButtonThreePoints>
        <SelectThreePoints style={{ display: open ? "block" : "none" }}>
          <ButtonOpenModal onClick={() => setShowModal(true)}>
            Clonar
          </ButtonOpenModal>
        </SelectThreePoints>
      </DropDownArea>
      <div css={MainImageDiv}>
        {user.plataform.urlAvatar ? (
          <img
            css={MainImage}
            style={{ height: "90px" }}
            src={user.plataform.urlAvatar}
            alt="imgBot"
          />
        ) : (
          <img css={MainImage} src={girl} alt="imgBot" />
        )}
      </div>
      <div css={Title}>{name}</div>
      <div css={BotId}>#{id}</div>
      <div css={Divisor} />
      <div css={FlexSB}>
        <div>
          <div css={TextSubTitle}>Atendimentos</div>
          <div css={TextContent}>{dialogs}</div>
        </div>
        <div>
          <div css={TextSubTitle}>Criado</div>
          <div css={TextContent}>
            {new Date(createdAt).toLocaleDateString()}
          </div>
        </div>
        <div>
          <div css={TextSubTitle}>Atualizado em</div>
          <div css={TextContent}>
            {new Date(updatedAt).toLocaleDateString()}
          </div>
        </div>
      </div>
      <div css={Divisor} />
      <div css={FlexSB}>
        <div className="flex">
          <div css={DivImgAtualizadoPor}>
            <ImgAtualizadoPor css={ImgAtualizadoPor} imgURL={RLAvatar} />
          </div>
          <div>
            <div css={TextSubTitle}>Atualizado por</div>
            <div css={TextContent}>{updatedBy}</div>
          </div>
        </div>
        <div className="flex">
          <div css={TextSubTitle}>Status</div>
          <div className="ml-2" css={TextContent}>
            {active ? "Ativo" : "Inativo"}
          </div>
        </div>
      </div>
      <div css={Divisor} />
      <div css={FlexSB} style={{ marginTop: "0.4rem" }}>
        <button
          type="button"
          onClick={() => handleBotIdActive(id)}
          css={ButtonStyle}
        >
          Detalhes do Bot
        </button>
        {showEditScript && (
          <button
            type="button"
            onClick={() => handleEditarScript(id)}
            css={ButtonStyle}
          >
            Editar Script
          </button>
        )}
      </div>
      <DialogPadrao
        displayDialog={showModal}
        contentStyle={{
          padding: 0,
          height: "55vh",
          borderBottomLeftRadius: "1rem",
          borderBottomRightRadius: "1rem",
          border: "none",
          overflow: "hidden"
        }}
        onHide={() => setShowModal(false)}
        headerStyle={{
          backgroundColor: user.plataform.mainColor
            ? user.plataform.mainColor
            : "#ff0025"
        }}
      >
        <ContainerLeftModal>
          <div className="flex align-items-start justify-content-left gap-2">
            <DivImg style={{ marginLeft: "0.14rem" }}>
              <img src={CloneImg} alt="Clone-Image" />
            </DivImg>
            <div>
              <Text
                fontWeight={600}
                fontSize="0.9375rem"
                lineHeight="3.25rem"
                color="#0A043C"
              >
                Duplicar Bot
              </Text>
            </div>
          </div>
          <form
            onSubmit={sendForm}
            style={{
              marginTop: "25px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              flexGrow: 1
            }}
          >
            <div className="flex flex-column flex-grow-1">
              <div className="flex flex-column" style={{ gap: "25px" }}>
                <div>
                  <div className="">
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <InputText
                          {...field}
                          id="name"
                          placeholder="Insira o nome do seu robô"
                        />
                      )}
                    />
                  </div>
                  <div className="mt-1">
                    {" "}
                    {errors.name?.message !== undefined ? (
                      <> {errorNameMessage()} </>
                    ) : (
                      ""
                    )}{" "}
                  </div>
                </div>
              </div>

              <div className="mt-2">
                <div className="">
                  <Controller
                    name="workspaceId"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        {...field}
                        id="workspaceId"
                        options={user?.workspaces?.workspaces}
                        optionLabel="name"
                        optionValue="id"
                        placeholder="Área de trabalho"
                        onChange={(e) => field.onChange(e.value)}
                        filter={true}
                      />
                    )}
                  />
                </div>
                <div className="mt-1">
                  {" "}
                  {errors.workspaceId?.message !== undefined ? (
                    <> {errorWorkspaceIdMessage()} </>
                  ) : (
                    ""
                  )}{" "}
                </div>
              </div>
            </div>
            <div className="flex justify-content-center">
              <Button
                label="Salvar"
                disabled={isSubmitting}
                style={{
                  width: "12.375rem",
                  background: user.plataform.mainColor
                    ? user.plataform.mainColor
                    : "#046af3",
                  borderColor: user.plataform.mainColor
                    ? user.plataform.mainColor
                    : "#046af3"
                }}
                loading={isSubmitting}
              />
            </div>
          </form>
        </ContainerLeftModal>
        <ContainerRightModal
          style={{
            backgroundColor: user.plataform.mainColor
              ? user.plataform.mainColor
              : "#ff0025"
          }}
        >
          {user.plataform.urlAvatar ? (
            <Img
              src={user.plataform.urlAvatar}
              alt="Bot Girl"
              style={{ height: "120px", marginBottom: 0 }}
            />
          ) : (
            <Img src={girl} alt="Bot Girl" />
          )}
          <div className="mb-3">
            <Text
              fontSize="14px"
              fontWeight={600}
              lineHeight="20px"
              color="#FFF"
            >
              Dica para duplicar seu bot
            </Text>
          </div>
          <Text fontSize="12px" fontWeight={400} lineHeight="20px" color="#FFF">
            Duplicar um robô e aproveitar o script atual no Im bot oferece
            vantagens significativas aos usuários. Isso permite uma rápida
            expansão e otimização das estratégias de criação de bots já
            estabelecidas. Os benefícios incluem economia de tempo. Dessa forma,
            os usuários podem criar novos bots sem começar do zero.
          </Text>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%"
            }}
          >
            <IoMdPlayCircle size={25} color="white" />
            <Text
              fontSize="12px"
              fontWeight={500}
              lineHeight="16px"
              color="#FAFBFC"
              style={{ textAlign: "left" }}
            >
              Confira o vídeo
            </Text>
          </div>
        </ContainerRightModal>
      </DialogPadrao>
    </div>
  );
};
