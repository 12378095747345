import CreateForm from "./CreateForm";
import SideTextDialog from "./SideTextDialog";
import DialogPadrao from "../../DialogPadrao";
import { AuthContextType, useAuth } from "../../../auth/auth-provider";

export default function botCreate({
  displayDialog,
  onHide
}: {
  displayDialog: boolean;
  onHide: () => void;
}) {
  const { user } = useAuth() as AuthContextType;

  return (
    <DialogPadrao
      displayDialog={displayDialog}
      onHide={onHide}
      contentStyle={{ height: "420px" }}
      headerStyle={{
        backgroundColor: user.plataform.mainColor || "#ff0025"
      }}
    >
      <div className="rigtContentFake">
        <CreateForm hideDialog={onHide} />
      </div>

      <div
        style={{
          width: "41%",
          backgroundColor: user.plataform.mainColor || "#ff0025"
        }}
      >
        <SideTextDialog />
      </div>
    </DialogPadrao>
  );
}
