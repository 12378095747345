import styled from "@emotion/styled";

export const Container = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  max-height: calc(100vh - 85px);
  overflow: auto;

  &&::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
    border-radius: 4px;
  }

  &&::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;
  }
`;
export const DivIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: #fff;
  mix-blend-mode: normal;
`;
export const Img = styled.img`
  max-width: 70%;
  max-height: 70%;
`;
export const Span = styled.span`
  margin-left: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 0.875rem;
  color: #046af3;
  background-color: #d4e2f3;
  width: 1.0625rem;
  height: 1.0625rem;
  border-radius: 50%;
`;
export const Strong = styled.strong`
  font-weight: 600;
`;
export const Chip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eaeaea;
  border-radius: 3.125rem;
  width: 3.8125rem;
  height: 2rem;
`;
export const ImgFilter = styled.img`
  width: 1rem;
  position: absolute;
  top: 30%;
  left: 12px;
`;

export const ContainerLeftModal = styled.div`
  width: 59%;
  position: relative;
  height: 100%;
  top: 0px;
  content: none;
  background-color: white;
  padding: 1rem;
  padding-top: 0rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;
export const ContainerRightModal = styled.div`
  width: 41%;
  position: relative;
  height: 100%;
  top: 0px;
  content: none;
  padding: 1rem;
  display: flex;
  align-items: start;
  gap: 15px;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const Relative = styled.div`
  width: 100%;
  height: 48px;
  position: relative;
  margin: 0;
  padding: 0;
`;
export const ButtonUpload = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 110px;
  height: 32px;
  right: 6px;
  top: 10%;
  background: #d4e2f3;
  color: #046af3;
  border-radius: 6px;
  transition: all ease-in-out 0.2s;
  :hover {
    background-color: #024aae;
    color: white;
    cursor: pointer;
  }
  :disabled {
    background-color: #d4e2f3;
    color: #d3d3d3;
    opacity: 0.35;
  }
`;

export const InputFile = styled.input`
  border-radius: 6px;
  color: #212529;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  background: #ffffff;
  padding: 0.5rem 0.75rem;
  padding-left: 0.75rem;
  border: 1px solid #ced4da;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  appearance: none;
  border-radius: 4px;
  margin: 0;
  box-sizing: border-box;

  ::placeholder {
    color: red;
  }

  ::after {
    content: "Selecionar";
  }

  ::before {
    content: "Selecionar...";
    display: inline-block;
  }

  ::file-selector-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 106px;
    height: 32px;
    right: 6px;
    top: 10%;
    background: #046af3;
    color: #ffffff;
    border-radius: 6px;
    transition: all ease-in-out 0.2s;

    :hover {
      background-color: #024aae;
      color: white;
      cursor: pointer;
    }

    ::file-selector-button::disabled {
      background-color: #d4e2f3;
      color: #d3d3d3;
      opacity: 0.35;
    }
  }

  ::file-selector-button::hover {
    display: block;
    align-self: flex-end;
    background-color: red;
    color: yellow;
    padding: 1rem;
    border-radius: 6px;
    background-color: #024aae;
    color: white;
    cursor: pointer;
  }
`;
export const PlayButton = styled.button`
  background-color: #fff;
  width: 1.1875rem;
  height: 1.1875rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  :hover {
    cursor: pointer;
  }
`;
