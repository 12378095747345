import { css } from "@emotion/react";
import { Link, useParams } from "react-router-dom";
import {
  background,
  botRed,
  dialogosInteligentes,
  spaikBeforeAuth
} from "./../assets";

import { PoliticaPrivacidadeConfirmar } from "../components";
import { useFetch } from "../util";

import Plataform from "../services/Plataform";
import { useEffect, useState } from "react";

export default () => {
  const { confirmationCode } = useParams();
  const confirm = useFetch(`/api/confirm/${confirmationCode}`, {
    method: "POST"
  });
  const [confirmReturn, setConfirmReturn] = useState({
    userName: "",
    message: ""
  });

  useEffect(() => {
    confirm()
      .then((response) => {
        setConfirmReturn(response);
      })
      .catch((response) => {
        setConfirmReturn(response);
      });
  }, []);

  return (
    <div
      className="grid grid-nogutter"
      css={css`
        height: 100vh;
      `}
    >
      <div
        className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6"
        css={css`
          background-image: ${Plataform.customLogin
            ? "none"
            : `url(${background})`};
          background-color: ${Plataform.customLogin
            ? "#2624CD"
            : "transparent"};
          background-repeat: no-repeat;
          background-size: cover;
          display: flex;
          flex-direction: column;
          flex: 1;
          align-items: center;
          justify-content: center;
        `}
      >
        <div
          css={css`
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
          `}
        >
          {Plataform.customLogin ? (
            <img
              src={spaikBeforeAuth}
              css={css`
                width: 700px;

                @media (width: 1600px) {
                  width: 520px;
                }

                @media (max-width: 1400px) {
                  width: 500px;
                }

                @media (max-width: 1280px) {
                  width: 450px;
                }

                @media (max-width: 1200px) {
                  width: 400px;
                }

                @media (max-width: 1050px) {
                  width: 350px;
                }

                @media (max-width: 950px) {
                  margin-top: 1rem;
                }
              `}
            />
          ) : (
            <img
              src={dialogosInteligentes}
              css={css`
                @media (max-width: 950px) {
                  max-width: 100%;
                  width: auto;
                  height: auto;
                }
              `}
            />
          )}
        </div>

        <div
          css={css`
            padding-bottom: 16px;
            color: white;
          `}
        >
          <p>©{Plataform.displayName}</p>
        </div>
      </div>

      <div
        className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6"
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1;
          align-items: center;
          justify-content: center;
        `}
      >
        <div
          css={css`
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 400px;
            gap: 16px;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 16px;
              width: 100%;
            `}
          >
            <img
              src={Plataform.urlLogo ? Plataform.urlLogo : botRed}
              style={{ maxWidth: "70px", maxHeight: "70px" }}
            />
            <div
              css={css`
                display: flex;
                flex-direction: column;
              `}
            >
              <span>Olá {confirmReturn.userName}</span>
              <div>
                <span>Bem-vindo ao seu </span>
                <span
                  css={css`
                    font-weight: 600;
                  `}
                >
                  {Plataform.displayName}
                </span>
                👋
              </div>
            </div>
          </div>
          <div
            css={css`
              font-weight: 600;
            `}
          >
            {confirmReturn.userName ? (
              <>Seu cadastro foi concluído com sucesso :)</>
            ) : (
              <>{confirmReturn.message}</>
            )}
          </div>

          <div
            css={css`
              margin-top: 25px;
              width: 100%;
            `}
          >
            <form>
              <div
                css={css`
                  color: "#046AF3";
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 8px;
                `}
              >
                <Link to="/login">Ir para a tela de login</Link>
              </div>
            </form>
          </div>
        </div>

        <PoliticaPrivacidadeConfirmar />
      </div>
    </div>
  );
};
