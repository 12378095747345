import styled from "@emotion/styled";

export const ContainerLeftModal = styled.div`
  position: absolute;
  background-color: #fff;
  height: 694px;
  top: -3rem;
  width: 59%;
  position: relative;
  height: 100%;
  background-color: white;
  padding: 0.5rem;
  padding-top: 3rem;
`;
export const ContainerRightModal = styled.div`
  width: 41%;
  position: relative;
  height: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`;

export const LeftHeaderWhiter = styled.div`
  width: 59%;
  position: absolute;
  height: 100%;
  top: 0px;
  border-radius: 20px;
  background-color: white;
  border-top-right-radius: 3rem;
  border-top-left-radius: 1rem;
`;

export const InputContainer = styled.div`
  width: 90%;
  margin: 2rem auto;
  position: relative;
`;

export const ButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
