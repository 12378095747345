import { Text } from "../../../../../components/Text/text";
import { girl, PlayRed } from "../../../../../assets";
import { AuthContextType, useAuth } from "../../../../../auth/auth-provider";
import { IoMdPlayCircle } from "react-icons/io";

export const RightContentChatBotModal: React.FC = () => {
  const { user } = useAuth() as AuthContextType;

  return (
    <div className="text-white">
      <div className="flex align-items-center justify-content-center">
        {user.plataform.urlAvatar ? (
          <img
            src={user.plataform.urlAvatar}
            alt="imgBot"
            style={{ height: "120px" }}
          />
        ) : (
          <img src={girl} alt="imgBot" />
        )}
      </div>
      <div className="my-3">
        <Text
          fontSize="13px"
          fontWeight={500}
          lineHeight="16px"
          color="#FAFBFC"
        >
          Dica para integrar o seu LiveChat
        </Text>
      </div>
      <Text fontSize="11px" fontWeight={400} lineHeight="14px" color="#FAFBFC">
        Copie e cole o script gerado diretamente no código do seu site para
        integrar o nosso LiveChat em segundos. Sem complicações, com suporte
        para customizações de estilo e comportamento, garantindo uma experiência
        única para seus visitantes!
      </Text>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "5px",
          marginTop: "10px"
        }}
      >
        <IoMdPlayCircle
          style={{ marginLeft: "0.14rem" }}
          size={25}
          color="white"
        />
        <div>
          <Text
            fontSize="13px"
            fontWeight={500}
            lineHeight="16px"
            color="#FAFBFC"
          >
            Vídeo - LiveChat
          </Text>
        </div>
      </div>
    </div>
  );
};
