import styled from "@emotion/styled";

export const Container = styled.div`
  padding: 1rem;
  width: 100%;
  min-width: 411px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 25px 10px 10px 25px;
  align-items: center;
  height: 546px;
  overflow-y: auto;

  &&::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
    border-radius: 4px;
  }

  &&::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;
  }
`;
export const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  border: none;
  :hover {
    cursor: pointer;
    background-color: #f5f5f5;
  }
`;
export const ContainerIcon = styled.div`
  width: 25px;
  height: 25px;
  background: #d4e2f3;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Divisor = styled.div`
  height: 1px;
  width: 100%;
  border: 1px solid #e5e5e5;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;
