import styled from "@emotion/styled";

export const Container = styled.div`
  max-height: calc(100vh - 70px);
  overflow: auto;

  &&::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
    border-radius: 4px;
  }

  &&::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;
  }
`;
