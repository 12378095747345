import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Container = styled.div`
  max-height: calc(100vh - 70px);
  overflow: auto;

  &&::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
    border-radius: 4px;
  }

  &&::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  .imageWebChat {
    height: 40px;
    width: 40px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  .divTitle {
    margin-top: 10px;
    color: #6e6b7b;
  }

  .divFilter {
    background-color: #ffffff;
    border-radius: 10px 10px 0px 0px;
    padding: 8px 12px;
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
  }

  .custom-dropdown .p-dropdown-label {
    display: none;
  }
`;

interface IChip {
  bgColor?: string;
}
export const Chip = styled.div<IChip>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3.125rem;
  height: 1.8rem;
  width: 6.2rem;
  background: ${(props) => (props.bgColor ? props.bgColor : " #EAEAEA")};
  color: #333c3f;
`;

export const Button = css`
  height: 28px;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 5px;
  color: #dddddd;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 16px;
  color: #333c3f;
  :hover {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    background-color: #dddddd;
    color: #ffffff;
  }
`;
