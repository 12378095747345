import React from "react";
import { Button, Dropdown, InputText } from "../..";
import { AuthContextType, useAuth } from "../../../auth/auth-provider";

type paramType = {
  labelName: string;
  clickToggle: () => void;
};

function NextBtn({ labelName, clickToggle }: paramType) {
  const { user } = useAuth() as AuthContextType;
  return (
    <div className="flex justify-content-center">
      <Button
        label={labelName}
        icon="pi pi-arrow-right"
        onClick={clickToggle}
        iconPos="right"
        className="border-round-lg w-4 mt-3"
        style={{
          border: "none",
          background: user.plataform.mainColor
            ? user.plataform.mainColor
            : " #046af3"
        }}
      />
    </div>
  );
}

export default NextBtn;
