export { default as Sidebar } from "./sidebar";
import styled from "@emotion/styled";

export const Title = styled.div`
  color: #544f77;
  transition: color 0.3s ease;

  &:hover {
    color: #212529;
  }
`;
