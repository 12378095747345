import styled from "@emotion/styled";

export const ContentPaginator = styled.div`
  display: flex;

  .p-paginator {
    border-radius: 0 0 10px 10px;
    background: #eaeaea;
  }

  .p-paginator .p-paginator-pages .p-paginator-page {
    height: auto;
    padding: 4px 12px;
    min-width: auto;
    margin: 0;
    font-size: 12px;
    background: transparent;
    border: none;
    color: #6e6b7b;
  }

  .p-paginator-pages {
    font-size: 12px !important;

    background: #f3f2f7;
    border-radius: 16px;
    padding: 4px;
  }

  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    border-radius: 29px;
    color: #0a043c;
    background-color: #eaeaea;
    border-color: #eaeaea;
  }

  .p-paginator .p-paginator-pages .p-paginator-page:hover {
    background: #e9ecef;
    border-color: #dee2e6;
    border-radius: 29px;
  }

  .css-18e5gj2 .p-paginator .p-paginator-prev {
    border-radius: 29px;
    margin-right: 10px;
  }

  .p-paginator .p-paginator-first,
  .p-paginator .p-paginator-prev,
  .p-paginator .p-paginator-next,
  .p-paginator .p-paginator-last {
    color: #6e6b7b;
  }

  .p-paginator .p-paginator-first,
  .p-paginator .p-paginator-prev,
  .p-paginator .p-paginator-next,
  .p-paginator .p-paginator-last {
    background: #f3f2f7;
    border-radius: 29px;
    margin-right: 10px;
    margin-left: 10px;
  }
`;
