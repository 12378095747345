import styled from "@emotion/styled";

export const Container = styled.div`
  max-height: calc(100vh - 75px);
  overflow: auto;

  &&::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
    border-radius: 4px;
  }

  &&::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;
  }
`;

export const ContainerTabs = styled.div<{ tabColor: string }>`
  .p-tabview-selected {
    border-bottom: 2px solid ${({ tabColor }) => tabColor};
  }
`;
