/* eslint-disable no-negated-condition */
import { css } from "@emotion/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { ColorPicker, ColorPickerChangeParams } from "primereact/colorpicker";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Dropdown, InputText, useToast } from "../..";
import { bot, botRed } from "../../../assets";
import { AuthContextType, useAuth } from "../../../auth/auth-provider";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setRChatBots } from "../../../redux/slices/chatbots";
import BotsService from "../../../services/bots";
import { v } from "../../../util";
import * as comunCss from "../../comum_css";
import BackArrowBtn from "./BackArrowBtn";
import NextBtn from "./NextBtn";
require("../../comum_css/rawCss.css");

type paramType = {
  hideDialog: () => void;
};

const CreateForm = ({ hideDialog }: paramType) => {
  const toast = useToast();
  const inputFileRef = useRef<HTMLInputElement>(null);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm<{ name: string; objectivo: number; nivel: number }>({
    defaultValues: {
      name: "",
      objectivo: 0,
      nivel: undefined
    },
    resolver: zodResolver(
      v.object({
        name: v.string().superRefine(v.nonempty()),
        objectivo: v.number().optional(),
        nivel: v.number().optional()
      })
    )
  });
  const dispatch = useAppDispatch();
  const { GetBots } = BotsService();
  const [mode, setMode] = useState("createFromZero");

  const [selectedFile, setSelectedFile] = useState<File>();
  // const [channels, setChannels] = useState<string[]>([]);
  const [colorAvatar, setColorAvatar] = useState("#0A043C");
  const [colorChat, setColorChat] = useState("#FF0025");

  const onchangeColorAvatar = (e: ColorPickerChangeParams) =>
    setColorAvatar(`#${e.value}`);
  const onchangeColorChat = (e: ColorPickerChangeParams) =>
    setColorChat(`#${e.value}`);

  // const onChannelChanges = (e: any) => {
  //   const channelss = [...channels];
  //   if (e?.checked) {
  //     channelss.push(e.value);
  //   } else {
  //     channelss.splice(channelss.indexOf(e.value), 1);
  //   }
  //   setChannels(channelss);
  // };

  const errorNameMessage = () => errors.name?.message;
  const errorNivelMessage = () => errors.nivel?.message;
  const errorObjectivoMessage = () => errors.objectivo?.message;

  const showSuccess = (message: string) => {
    toast?.current?.show({
      severity: "success",
      summary: "Success Message",
      detail: message,
      life: 3000
    });
    setTimeout(() => {
      hideDialog();
    }, 3000);
  };

  const { createBot } = BotsService();

  const { company, user } = useAuth() as AuthContextType;

  const workspacesSelected = useAppSelector(
    (state) => state.workspaces.selectedWorkspaces
  );

  const sendForm = handleSubmit(async (data) => {
    if (!data.nivel) {
      toast?.current?.show({
        severity: "error",
        summary: "Campo não selecionado",
        detail: "Você deve selecionar uma área de trabalho",
        life: 3000
      });
      return;
    } else if (!data.objectivo) {
      toast?.current?.show({
        severity: "error",
        summary: "Campo não selecionado",
        detail: "Você deve selecionar um objetivo do robô",
        life: 3000
      });
      return;
    } else if (
      Array.isArray(workspacesSelected) &&
      workspacesSelected.length < 1
    ) {
      toast?.current?.show({
        severity: "error",
        summary: "Erro",
        detail: "Favor informar ao menos uma workspace no cabeçalho da página",
        life: 5000
      });
      return;
    }

    try {
      await createBot({
        active: true,
        avatarColor: colorAvatar,
        chatColor: colorChat,
        companyId: company?.id ?? user.companyId,
        name: data.name,
        workspaceId: data.nivel,
        target: data.objectivo
      });

      const results = await GetBots({
        limit: 100,
        offset: 0,
        companyId: user.admin ? company?.id : user.companyId,
        workspaces: workspacesSelected
      });

      dispatch(setRChatBots(results.result));
      showSuccess("Chatbot criado com sucesso!!");
    } catch (e: any) {
      toast?.current?.show({
        severity: "error",
        summary: "Erro",
        detail: e.message,
        life: 3000
      });
    }
  });

  const [preview, setPreview] = useState<string>();

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    // eslint-disable-next-line consistent-return
    return () => URL.revokeObjectURL(objectUrl);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  return (
    <div className="col-8" style={comunCss.cssP}>
      <div>
        {mode === "porsonalize" ? (
          <BackArrowBtn setOption={() => setMode("createFromZero")} />
        ) : null}

        <div style={{ alignItems: "center", display: "flex", gap: "10px" }}>
          <img
            src={user.plataform.urlLogo ? user.plataform.urlLogo : botRed}
            style={{ maxWidth: "65px", maxHeight: "65px" }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            {mode === "createFromZero" && (
              <>
                <div className="font-bold titleSteps">
                  Olá, eu sou o <b>{user.plataform.displayName} 👋</b>{" "}
                </div>
                <div>
                  Vou te ajudar a configurar o melhor robô para o seu objetivo
                  ;).
                </div>
              </>
            )}
            {mode === "porsonalize" && (
              <>
                <div className="font-bold titleSteps">
                  Personalize o seu Chatbot
                </div>
                <div>Defina a avatar e cor principal do seu chatbot.</div>
              </>
            )}
          </div>
        </div>
        <form onSubmit={sendForm} style={{ marginTop: "25px" }}>
          {mode === "createFromZero" && (
            <>
              <div
                className="flex, flex-column"
                style={{ flex: "1", gap: "25px" }}
              >
                <div>
                  <div className="">
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <InputText
                          {...field}
                          id="name"
                          placeholder="Insira o nome do seu robô"
                        />
                      )}
                    />
                  </div>
                  <div className="mt-1">
                    {" "}
                    {errors.name?.message !== undefined ? (
                      <> {errorNameMessage()} </>
                    ) : (
                      ""
                    )}{" "}
                  </div>
                </div>

                <div className="mt-2">
                  <div className="">
                    <Controller
                      name="nivel"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          {...field}
                          id="nivel"
                          options={user?.workspaces?.workspaces}
                          optionLabel="name"
                          optionValue="id"
                          placeholder="Área de trabalho"
                          onChange={(e) => field.onChange(e.value)}
                          filter={true}
                        />
                      )}
                    />
                  </div>
                  <div className="mt-1">
                    {" "}
                    {errors.nivel?.message !== undefined ? (
                      <> {errorNivelMessage()} </>
                    ) : (
                      ""
                    )}{" "}
                  </div>
                </div>

                <div className="mt-2">
                  <div className="">
                    <Controller
                      name="objectivo"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          {...field}
                          id="objectivo"
                          options={[
                            { label: "Atendimento", value: 1 },
                            { label: "Suporte", value: 2 },
                            { label: "FAQ", value: 3 },
                            { label: "Funcionário digital", value: 4 },
                            { label: "Campanha", value: 5 },
                            { label: "Sem script", value: 6 }
                          ]}
                          placeholder="Objetivo do Robô"
                          onChange={(e) => field.onChange(e.value)}
                        />
                      )}
                    />
                  </div>
                  <div className="mt-1">
                    {" "}
                    {errors.objectivo?.message !== undefined ? (
                      <> {errorObjectivoMessage()} </>
                    ) : (
                      ""
                    )}{" "}
                  </div>
                </div>
              </div>

              <NextBtn
                labelName="Próximo"
                clickToggle={() => setMode("porsonalize")}
              />
            </>
          )}
          {mode === "porsonalize" && (
            <>
              <div className="blocoOne relative mt-5">
                <div
                  style={{ height: "4.6rem" }}
                  className="flex justify-content-center"
                >
                  <div className="relative avatarLayaOne">
                    <div
                      className="avatarLayaTwo avatarContent"
                      onClick={() => inputFileRef.current?.click()}
                      style={{ cursor: "pointer" }}
                    >
                      {preview ? (
                        <img
                          src={preview}
                          width={81}
                          height={81}
                          css={css`
                            border-radius: 50%;
                          `}
                        />
                      ) : (
                        <>
                          <i
                            className="pi pi-camera"
                            css={css`
                              color: #acaab8;
                            `}
                            style={{ fontSize: "2.1em" }}
                          ></i>
                          <span>Avatar</span>
                        </>
                      )}
                    </div>

                    <input
                      name="pic"
                      type="file"
                      css={css`
                        display: none;
                      `}
                      ref={inputFileRef}
                      onChange={(e) => {
                        if (!e.target.files || e.target.files.length === 0) {
                          setSelectedFile(undefined);
                          return;
                        }
                        setSelectedFile(e.target?.files?.[0]);
                      }}
                    />
                  </div>
                </div>

                <div className="flex justify-content-center mb-2">
                  <ColorPicker
                    className="iconChoseColor"
                    value={colorAvatar}
                    onChange={(e) => onchangeColorAvatar(e)}
                  ></ColorPicker>{" "}
                  <span className="ml-1 mr-2">Cor do avatar</span>
                  <ColorPicker
                    className="iconChoseColor"
                    value={colorChat}
                    onChange={(e) => onchangeColorChat(e)}
                  ></ColorPicker>{" "}
                  <span className="ml-1">Cor do chat</span>
                </div>
              </div>
              <div className="blocTwo flex justify-content-center">
                <div
                  className="chatContentColor"
                  style={{ backgroundColor: colorChat }}
                >
                  <div
                    className="borderAvatarColorChat relative"
                    style={{ backgroundColor: `${colorAvatar}80` }}
                  >
                    <div
                      className="iconAvatarColor"
                      style={{ backgroundColor: colorAvatar }}
                    >
                      <img src={bot} alt="Cor do Chat" className="iconChat" />
                    </div>
                  </div>
                  <span className="text-white mt-2 ml-1">Olá tudo bem?</span>
                </div>
                <div>
                  <div
                    className="borderAvatarColor relative"
                    style={{ backgroundColor: `${colorAvatar}80` }}
                  >
                    <div
                      className="iconAvatarColor "
                      style={{
                        top: "22px",
                        left: "2rem",
                        backgroundColor: colorAvatar
                      }}
                    >
                      <img src={bot} alt="Cor do Chat" className="iconChat" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-content-center">
                <Button
                  label="Tudo Pronto!"
                  disabled={isSubmitting}
                  icon={
                    isSubmitting ? "pi pi-spin pi-spinner" : "pi pi-arrow-right"
                  }
                  iconPos="right"
                  style={{
                    border: "none",
                    background: user.plataform.mainColor
                      ? user.plataform.mainColor
                      : " #046af3"
                  }}
                />
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default CreateForm;
