import styled from "@emotion/styled";

export const ContainerLeftModal = styled.div`
  position: absolute;
  background-color: #fff;
  width: 59%;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
  padding-left: 2rem;

  .titleDatatable {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;
export const ContainerRightModal = styled.div`
  width: 41%;
  position: relative;
  height: 100%;
  color: white;
  padding: 0px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 1rem;

  .imageTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .text {
    font-size: 12px;
  }

  .link {
    display: flex;
    align-items: center;
    width: 100%;
    // cursor: pointer;
  }
`;

export const LeftHeaderWhiter = styled.div`
  width: 59%;
  position: absolute;
  height: 100%;
  top: 0px;
  border-radius: 20px;
  background-color: white;
  border-top-right-radius: 3rem;
  border-top-left-radius: 1rem;
`;

export const InputContainer = styled.div`
  width: 90%;
  margin: 2rem auto;
  position: relative;
`;

export const ButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  padding-right: 2rem;
`;
