import React from "react";
import { IoMdPlayCircle } from "react-icons/io";
import { girl } from "../../../assets";
import { AuthContextType, useAuth } from "../../../auth/auth-provider";

function SideTextDialog() {
  const { user } = useAuth() as AuthContextType;

  return (
    <div>
      <div style={{ color: "white", padding: "0px 30px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          {user.plataform.urlAvatar ? (
            <img src={user.plataform.urlAvatar} style={{ height: "120px" }} />
          ) : (
            <img src={girl} style={{ marginTop: "20px" }} />
          )}
        </div>

        <div>Qual a importância de um chatbot na sua empresa?</div>
        <br />
        <div style={{ fontSize: "12px" }}>
          As marcas mais bem sucedidas sabem como interagir com seus clientes,
          não importa onde estejam. Eles não contam com estratégias de marketing
          unidimensionais que simplesmente dependem de interações no site.
        </div>
        <div className="mt-2">
          <IoMdPlayCircle
            style={{ top: "8px", marginRight: "0.3rem" }}
            size={25}
            color="white"
            className="relative"
          />
          <span>Confira o vídeo</span>
        </div>
      </div>
    </div>
  );
}

export default SideTextDialog;
