/* eslint-disable no-nested-ternary */
import { css } from "@emotion/react";
import { botIconPage } from "../../../assets";

export const TopInfo: React.FC<{ totalCount: number }> = ({ totalCount }) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: 100%;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          width: 100%;
          gap: 10px;
        `}
      >
        <div
          style={{
            backgroundColor: "#fff",
            width: "2.5rem",
            height: "2.5rem",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <img
            css={css`
              height: 30px;
              width: 30px;
            `}
            src={botIconPage}
            alt="imbot-chat"
          />
        </div>

        <div
          css={css`
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
            color: #0a043c;
          `}
        >
          Meus robôs
        </div>

        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            padding: 7px 10px;
            border-radius: 6px;
            background: #eaeaea;
            color: #0a043c;
          `}
        >
          {totalCount + " Bots"}
        </div>
      </div>

      <div
        css={css`
          margin: 10px 0;
          color: #6e6b7b;
        `}
      >
        Gerencie os seus bots cadastrados
      </div>
    </div>
  );
};
