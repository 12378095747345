import styled from "@emotion/styled";

export const Container = styled.div`
  max-height: calc(100vh - 70px);
  overflow: auto;

  &&::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
    border-radius: 4px;
  }

  &&::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;
  }
`;

export const RightContent = styled.div`
  width: 58%;
  position: relative;
  height: 100%;
  top: 0px;
  content: none;
  background-color: white;
  padding: 1rem;
  padding-left: 3rem;
`;

export const ContentLeft = styled.div`
  background-color: white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 50px;
  width: 100%;
  height: 100%;
  overflow: auto;

  &&::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
    border-radius: 4px;
  }

  &&::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;
  }
`;
