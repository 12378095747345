import { Text } from "../../../../components/Text";
import {
  Card,
  Channels,
  Container,
  DivIcon,
  Inactive,
  Subtitle,
  Title
} from "./styles";
import {
  botSpaik,
  CanaisIntegrados as ChanelIntegratedImg,
  Fb3d,
  FbWhite,
  imbotRedWhiteIcon,
  Instagram,
  Messenger,
  Telegram,
  Webhook,
  whatsapp
} from "../../../../assets";
import { ChannelCheck } from "./ChannelCheck";
import { RefObject, useEffect, useState } from "react";
import { Toast } from "primereact/toast";
import { ChannelChatbotModal } from "./ChannelChatbotModal";
import { ChannelWebhookModal } from "./ChannelWebhookModal";
import { Button } from "primereact/button";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  setModalChatbot,
  setModalFbLoginOpen,
  setModalWebhook
} from "../../../../redux/slices/flowInteractions";
import { ModalFbLogin } from "./ModalFbLogin";
import { AuthContextType, useAuth } from "../../../../auth/auth-provider";

interface IChannel {
  channelName?: string;
  active?: boolean;
  image?: string;
  toastRef: RefObject<Toast>;
  token: string;
}
// interface IModalMetaLogin {
//   type: "FACEBOOK" | "INSTAGRAM";
// }
export const CanaisIntegrados: React.FC<IChannel> = ({ toastRef }) => {
  const dispatch = useAppDispatch();
  const [typeModal, setTypeModal] = useState("FACEBOOK");
  const { user } = useAuth() as AuthContextType;
  const openModalFb = (type: string) => {
    setTypeModal(type);
    dispatch(setModalFbLoginOpen(true));
  };
  const openModalInstagram = (type: string) => {
    setTypeModal(type);
    dispatch(setModalFbLoginOpen(true));
  };

  return (
    <div css={Card}>
      <ModalFbLogin type={typeModal as "FACEBOOK" | "INSTAGRAM"} />
      {/* A linha abaixo deixa o componente inativo */}
      {/* <div css={CardHover}/> */}
      <div css={Title}>
        <div css={DivIcon}>
          <img
            style={{ marginLeft: "-2px" }}
            src={ChanelIntegratedImg}
            alt="Canais Integrados Image"
          />
        </div>
        <Text s="20px">Canais Integrados</Text>
      </div>
      <div css={Channels}>
        <Text css={Subtitle}>Canais ativos</Text>
        <Container>
          <ChannelCheck
            checked={true}
            name="WhatsApp"
            toastRef={toastRef}
            img={whatsapp}
            channelType="WHATSAPP"
          />

          <div onClick={() => dispatch(setModalChatbot(true))}>
            <ChannelChatbotModal
              checked={true}
              name="LiveChat"
              img={
                user.plataform.id === 3 || user.plataform.id === 4
                  ? botSpaik
                  : imbotRedWhiteIcon
              }
            />
          </div>

          <div onClick={() => dispatch(setModalWebhook(true))}>
            <ChannelWebhookModal checked={true} name="Webhook" img={Webhook} />
          </div>
          {/* <Text className="mt-2" css={Subtitle}>
          Canais inativos
        </Text> */}
          {/* <ChannelCheck checked={false} name="Messenger" img={Messenger} /> */}
          {/* <Button onClick={() => {
            localStorage.setItem(
              "FbLogin",
              `true`
            );
          }} >SetLocalStorage</Button> */}
          {/* <ChannelCheck checked={false} name="Messenger" img={Messenger} /> */}
          <div onClick={() => openModalFb("FACEBOOK")}>
            <ChannelCheck
              checked={true}
              img={Fb3d}
              name="Facebook"
              channelType="FACEBOOK"
            />
          </div>

          <div onClick={() => openModalInstagram("INSTAGRAM")}>
            <ChannelCheck
              checked={true}
              img={Instagram}
              name="Instagram"
              channelType="INSTAGRAM"
            />
          </div>

          {/* <MyLink target="_blank"
          href="http://localhost:8080/facebook/Oauth#access_token=EAAjoUMhQoQkBO6jhDT6ApHwNGCrYoBtjluIhApXy7FFAtWU6hschDherjXZADfDAZBNpipjlK7yZBlOmamCv0BZCGzviPSW3mlxpDuumATt4vFgneYIc3vs8PzSCQZCSAU840VrgVenRuvZBHsihZCV5sQM2r4POezB6APJXXZBPNFuMfWlylb7y1us7vkGbyi9INoZCDfeUqHChVElCzrmDeR5Jy&data_access_expiration_time=1704110641&expires_in=6959&state=123"
          rel="noreferrer">Retorno</MyLink> */}
          {/* <ChannelCheck checked={false} name="Instagram" img={Instagram} /> */}
          <Inactive>
            <ChannelCheck checked={false} name="Telegram" img={Telegram} />
          </Inactive>
        </Container>
      </div>
    </div>
  );
};
