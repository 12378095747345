import { BotInterctionChat } from "../../../../assets";
import { HeaderDiv, IconChat } from "./styles";
import { AuthContextType, useAuth } from "../../../../auth/auth-provider";

export const HeaderChat: React.FC = () => {
  const { user } = useAuth() as AuthContextType;

  return (
    <div css={HeaderDiv}>
      <div css={IconChat}>
        {user.plataform.urlAvatar ? (
          <img src={user.plataform.urlAvatar} style={{ width: "25px" }} />
        ) : (
          <img src={BotInterctionChat} />
        )}
      </div>
      <div className="ml-2">Teste o seu chatbot ;)</div>
    </div>
  );
};
