import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  setCustomEntityUpdating,
  setEntityIdUpdate,
  setInsertEntity,
  toggleGCredentialsDialog,
  toggleOpenEntityDialog,
  clearEntities
} from "../../../../redux/slices/flowInteractions";
import { useLocation } from "react-router-dom";
import { ButtonAdd } from "../../../../assets";
import { Bell } from "../Bell";
import { Identification } from "../Identification";
import { WorkspaceSelector } from "../workspaceSelector";
import { Button, Container, ContainerLeft } from "./styles";
import { useEffect, useState } from "react";
import { AdicionarBar } from "../../../../components/adicionar-bar";
import { AuthContextType, useAuth } from "../../../../auth/auth-provider";
import { css } from "@emotion/react";
import CreateCampaign from "../../../../components/gestaoBot/components/CreateCampaign";

interface IHeader2 {
  extends?: number;
}
export const Header2: React.FC<IHeader2> = ({ extends: Ex }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { user, hasAnyPermission } = useAuth() as AuthContextType;
  const authContext = useAuth() as AuthContextType;
  const [visibleSidebar, setVisibleSidebar] = useState(false);
  const [showCreateCampaign, setShowCreateCampaign] = useState(false);
  const openGCredentialsDialog = useAppSelector(
    (state) => state.flowInteractions.dialogInsertGCredentials
  );
  const buttonCreateCampaign = useAppSelector(
    (state) => state.flowInteractions.buttonCreateCampaign
  );
  const allowCampaignCreation = user.allowCampaignCreation;

  const handleOnClick = () => {
    if (location.pathname === "/entities/") {
      dispatch(toggleOpenEntityDialog());
      dispatch(setInsertEntity(true));
      dispatch(setEntityIdUpdate(-1));
      dispatch(setCustomEntityUpdating(false));
    } else {
      setVisibleSidebar(true);
    }
  };

  const handleOnClickGerarChave = () => {
    dispatch(toggleGCredentialsDialog());
  };

  useEffect(() => {
    return () => {
      dispatch(clearEntities());
    };
  }, []);

  return (
    <>
      <Container>
        <ContainerLeft extends={Ex}>
          <Identification />

          {/* <Bell news={true} /> */}

          <WorkspaceSelector />
        </ContainerLeft>

        <div
          css={css`
            @media (max-width: 500px) {
              display: none;
            }
          `}
        >
          {location.pathname === "/gcredentials" ? (
            <Button
              onClick={handleOnClickGerarChave}
              style={{
                background: user.plataform.mainColor
                  ? user.plataform.mainColor
                  : "#046AF3"
              }}
            >
              <i className="pi pi-plus-circle" />
              Gerar Chave
            </Button>
          ) : (location.pathname === "/contacts" && user.admin) ||
            (location.pathname === "/contacts" &&
              hasAnyPermission("campaign_create")) ? (
            user.plataform.mainColor ? (
              <Button
                onClick={() => setShowCreateCampaign(true)}
                disabled={!buttonCreateCampaign}
                style={
                  !buttonCreateCampaign
                    ? {
                        display: allowCampaignCreation ? undefined : "none",
                        backgroundColor: "#b0b0b0",
                        cursor: "default",
                        boxShadow: "none"
                      }
                    : {
                        display: allowCampaignCreation ? undefined : "none",
                        background: user.plataform.mainColor
                      }
                }
              >
                <i className="pi pi-plus-circle" />
                Criar Campanha
              </Button>
            ) : (
              <Button
                onClick={() => setShowCreateCampaign(true)}
                disabled={!buttonCreateCampaign}
                style={
                  !buttonCreateCampaign
                    ? {
                        display: allowCampaignCreation ? undefined : "none",
                        backgroundColor: "#b0b0b0",
                        cursor: "default",
                        boxShadow: "none"
                      }
                    : {
                        display: allowCampaignCreation ? undefined : "none",
                        background: " #046af3"
                      }
                }
              >
                <i className="pi pi-plus-circle" />
                Criar Campanha
              </Button>
            )
          ) : location.pathname === "/contacts" ? null : (
            <Button
              onClick={handleOnClick}
              style={{
                background: user.plataform.mainColor
                  ? user.plataform.mainColor
                  : "#046af3"
              }}
            >
              <i className="pi pi-plus-circle" />
              Adicionar
            </Button>
          )}
        </div>
      </Container>
      <AdicionarBar
        visible={visibleSidebar}
        hide={() => setVisibleSidebar(false)}
      />
      <CreateCampaign
        visible={showCreateCampaign}
        hide={() => setShowCreateCampaign(false)}
      />
    </>
  );
};
