import { EntitiesPageComponent } from "../components/Pages/EntitiesPage";
import { Header2 } from "../components/header/components/Header2";

export const EntitiesPage: React.FC = () => {
  return (
    <>
      <div className="fixed left-0 w-screen z-4">
        <Header2 extends={5} />
      </div>
      <div style={{ backgroundColor: "#f5f5f5" }} className="mt-8 ">
        <EntitiesPageComponent />
      </div>
    </>
  );
};
