import { Clock } from "./../../../../assets/index";
import { Text } from "../../../../components/Text";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Active, Button, Card, Icon, Title } from "./styles";
import "./tableStyle.css";
import { LetterCircle } from "../LetterCircle";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  KeyboardEvent,
  MouseEventHandler,
  SetStateAction,
  useState
} from "react";
import { Button as ButtonPrime } from "primereact/button";
import { Ripple } from "primereact/ripple";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { classNames as classes } from "primereact/utils";
import { setBotIdActive } from "../../../../redux/slices/flowInteractions";
import { css } from "@emotion/react";

export const Versionamento: React.FC = () => {
  const redirect = useNavigate();
  const BotVersions = useAppSelector((state) => state.bot.versions);
  const dispatch = useAppDispatch();
  const { botId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageInputTooltip, setPageInputTooltip] = useState(
    "Pressione 'Enter' para ir para esta página."
  );
  const [first1, setFirst1] = useState(0);
  const [rows1, setRows1] = useState(10);
  // const [Version, setVersion] = useState([
  //     {
  //     name: "rogerio",
  //     responsible: "a",
  //     created: "a",
  //     updated: "a",
  //     version: "a",
  //     action: "a"
  //     },
  //     {
  //         name: "rogerio",
  //         responsible: "a",
  //         created: "a",
  //         updated: "a",
  //         version: "a",
  //         action: "a"
  //     }

  // ])
  const onCustomPage1 = (event: {
    first: SetStateAction<number>;
    rows: SetStateAction<number>;
    page: number;
  }) => {
    setFirst1(event.first);
    setRows1(event.rows);
    setCurrentPage(event.page + 1);
  };
  const onPageInputKeyDown = (
    event: KeyboardEvent<HTMLInputElement>,
    options: { totalPages: number; rows: number }
  ) => {
    if (event.key === "Enter") {
      const page = currentPage;
      if (page < 1 || page > options.totalPages) {
        setPageInputTooltip(`Valor deve ser entre 1 e ${options.totalPages}.`);
      } else {
        const first = currentPage ? options.rows * (page - 1) : 0;

        setFirst1(first);
        setPageInputTooltip("Pressione 'Enter' para ir para esta página.");
      }
    }
  };
  //   const onPageInputChange = (event: ChangeEvent<HTMLInputElement>) => {
  //     setCurrentPage(event.target.value);
  //   };
  const paginatorLeft = (
    <ButtonPrime type="button" icon="pi pi-refresh" className="p-button-text" />
  );
  const paginatorRight = (
    <ButtonPrime type="button" icon="pi pi-cloud" className="p-button-text" />
  );
  const template1 = {
    layout:
      "PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport",
    PrevPageLink: (options: {
      className: string | undefined;
      onClick: MouseEventHandler<HTMLButtonElement> | undefined;
      disabled: boolean | undefined;
    }) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-3">Anterior</span>
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options: {
      className: string | undefined;
      onClick: MouseEventHandler<HTMLButtonElement> | undefined;
      disabled: boolean | undefined;
    }) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-3">Próxima</span>
          <Ripple />
        </button>
      );
    },
    PageLinks: (options: {
      view: { startPage: number; endPage: any };
      page: number;
      totalPages: any;
      className: string | undefined;
      onClick: MouseEventHandler<HTMLButtonElement> | undefined;
    }) => {
      if (
        (options.view.startPage === options.page &&
          options.view.startPage !== 0) ||
        (options.view.endPage === options.page &&
          options.page + 1 !== options.totalPages)
      ) {
        const className = classes(options.className, { "p-disabled": true });

        return (
          <span className={className} style={{ userSelect: "none" }}>
            ...
          </span>
        );
      }

      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
        >
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
    RowsPerPageDropdown: (options: {
      totalRecords: any;
      value: any;
      onChange: ((e: DropdownChangeParams) => void) | undefined;
    }) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: "Todas", value: options.totalRecords }
      ];

      return (
        <Dropdown
          value={options.value}
          options={dropdownOptions}
          onChange={options.onChange}
        />
      );
    },
    CurrentPageReport: (options: any) => {
      return (
        <span
          className="mx-3"
          style={{ color: "var(--text-color)", userSelect: "none" }}
        >
          {/* Go to{" "}
          <InputText
            size={2}
            className="ml-1"
            value={currentPage}
            tooltip={pageInputTooltip}
            onKeyDown={(e) => onPageInputKeyDown(e, options)}
            onChange={(e) => onPageInputChange(e)}
          /> */}
        </span>
      );
    }
  };
  const activeBodyTemplate = (rowData: { active: boolean }) => {
    return <Active active={rowData.active} />;
  };
  const editingBodyTemplate = (rowData: { editingRelease: boolean }) => {
    return <Active active={rowData.editingRelease} />;
  };
  const createdAtBodyTemplate = (rowData: { createdAt: Date }) => {
    return new Date(rowData.createdAt).toLocaleString().replace(",", " -");
  };
  const updatedAtBodyTemplate = (rowData: { updatedAt: Date }) => {
    return new Date(rowData.updatedAt).toLocaleString().replace(",", " -");
  };
  const responsavelBodyTemplate = (rowData: { createdBy: string }) => {
    return (
      <div className="flex">
        <LetterCircle initial={rowData.createdBy} />
        <div className="ml-1">{rowData.createdBy}</div>
      </div>
    );
  };
  const actionBodyTemplate = (rowData: { version: number }) => {
    return (
      <button
        type="button"
        css={Button}
        onClick={() => {
          if (!isNaN(parseInt(botId as string))) {
            dispatch(setBotIdActive(parseInt(botId as string)));
          }
          redirect(`/drawflow/${botId}/${rowData.version}`);
        }}
      >
        Restaurar
      </button>
    );
  };

  const paginatorCustomTemplate = {
    layout: "RowsPerPageDropdown PrevPageLink PageLinks NextPageLink",
    RowsPerPageDropdown: (options: {
      value: number;
      onChange: ((e: DropdownChangeParams) => void) | undefined;
    }) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 30, value: 30 },
        { label: 50, value: 50 },
        { label: 100, value: 100 }
      ];

      return (
        <div
          style={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <span
            style={{
              minWidth: "fit-content"
            }}
          >
            Por página:
          </span>

          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
            css={css`
              .p-inputtext {
                color: #6e6b7b;
              }
            `}
          />
        </div>
      );
    }
  };
  return (
    <div css={Card}>
      <div css={Title}>
        <div css={Icon}>
          <img width={15} src={Clock} alt="" />
        </div>
        <Text s="14px">Versionamento do Script</Text>
      </div>
      <DataTable
        value={BotVersions}
        paginator={true}
        responsiveLayout="scroll"
        paginatorTemplate={paginatorCustomTemplate}
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
        rows={10}
        first={0}
        paginatorLeft={paginatorLeft}
        paginatorRight={paginatorRight}
      >
        <Column
          field="active"
          header="Ativo"
          body={activeBodyTemplate}
        ></Column>
        <Column
          field="editingRelease"
          header="Em edição"
          body={editingBodyTemplate}
        ></Column>
        <Column
          field="createdBy"
          header="Responsável"
          body={responsavelBodyTemplate}
        ></Column>
        <Column
          field="createdAt"
          header="Criado em"
          body={createdAtBodyTemplate}
        ></Column>
        <Column
          field="updatedAt"
          header="Atualizado em"
          body={updatedAtBodyTemplate}
        ></Column>
        <Column field="version" header="Versão"></Column>
        <Column
          field="version"
          header="Ação"
          body={actionBodyTemplate}
        ></Column>
      </DataTable>
    </div>
  );
};
