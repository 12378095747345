import { css } from "@emotion/react";
import { useRef, useState } from "react";
import Button from "../button";
import { AuthContextType, useAuth } from "../../auth/auth-provider";

export interface InputFileProps {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onAdicionarClick: React.MouseEventHandler<HTMLButtonElement>;
}

const InputFile = ({ onChange, onAdicionarClick }: InputFileProps) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState("");
  const { user } = useAuth() as AuthContextType;

  return (
    <div
      className="p-inputtext"
      css={css`
        align-items: center;
        display: flex;
        flex-direction: row;
      `}
    >
      <div
        css={css`
          flex: 1;
        `}
      >
        {fileName ? fileName : "Selecione um arquivo..."}
      </div>

      {fileName ? null : (
        <Button
          style={{
            background: user.plataform.mainColor
              ? user.plataform.mainColor
              : "#046af3",
            borderColor: user.plataform.mainColor
              ? user.plataform.mainColor
              : "#046af3"
          }}
          label="Upload"
          onClick={() => inputFileRef.current?.click()}
        />
      )}

      {fileName ? (
        <Button
          style={{
            background: user.plataform.mainColor
              ? user.plataform.mainColor
              : "#046af3",
            borderColor: user.plataform.mainColor
              ? user.plataform.mainColor
              : "#046af3"
          }}
          label="Adicionar"
          onClick={onAdicionarClick}
        />
      ) : null}

      <input
        ref={inputFileRef}
        type="file"
        css={css`
          display: none;
        `}
        accept="application/json"
        multiple={true}
        onChange={(e) => {
          if (e.target.files) {
            let fileName = "";
            const { files } = e.target;

            for (let i = 0; i < files.length; i++) {
              fileName += `${files[i].name} `;
            }

            setFileName(fileName);
          }

          onChange(e);
        }}
      />
    </div>
  );
};

export default InputFile;
