import styled from "@emotion/styled";

export const Container = styled.div<{ buttonColor: string }>`
  .p-button {
    background: ${(props) => props.buttonColor};
    border: none;

    &:hover {
      background: ${(props) => props.buttonColor};
    }
  }
`;
