/* eslint-disable no-negated-condition */
import React, { useEffect, useRef, useState } from "react";
import {
  botRed,
  A,
  Smile,
  Attachment,
  Link,
  MicBlue,
  ThreePointsBlue,
  Actions
} from "../../../../assets";
import { Button } from "primereact/button";
import { ContentInteraction, Flex, ImgIcon } from "./styles";
import { RobotTexts } from "./robotTexts";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { addRobotInteraction } from "../../../../redux/slices/nodes";
import { Dialog } from "primereact/dialog";
import { changedPhoneComponent } from "../../../../redux/slices/flowupdates";
import { Text } from "../../../../components/Text";
import { OptionsRobotActions } from "./Options";
import { OverlayInteractions } from "../overlayInteractions";
import { CustomButtonModal } from "./CustomButton";
import { SendFilesModal } from "./senfFiles";
import {
  setAudioModalOpen,
  setEmojiModalOpen,
  setSendFileModalOpen,
  setSendsendFileSelectorSOOpenOpen,
  setIdxEmoji
} from "../../../../redux/slices/flowInteractions";
import { Tooltip } from "primereact/tooltip";
import { AudiosModal } from "./AudiosModal";
import { EmojiModal } from "./EmojiModal";
import { useLocation, useParams } from "react-router-dom";
import { AuthContextType, useAuth } from "../../../../auth/auth-provider";

const InteracaoRobo: React.FC = () => {
  const nodes = useAppSelector((state) => state.nodes);
  const nodeSelected = nodes.find((node) => node.selected === true);
  const dispatch = useAppDispatch();
  const { user } = useAuth() as AuthContextType;
  const [visible, setVisible] = useState(false);
  const [showEmoji, setShowEmoji] = useState(true);
  const [interactions, setInteractions] = useState([]);
  const dragInteraction = useRef(null);
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);
  // const [clickOpenFileTransfer, setClickOpenFileTransfer] = useState(false);
  const clickOpenFileTransfer = useAppSelector(
    (state) => state.flowInteractions.sendFileSelectorSOOpen
  );
  const { pathname } = useLocation();
  const { templateId } = useParams();

  const isTemplate =
    pathname.includes("/drawflow/templates-view/") && Number(templateId) > 0;
  const overlayInteractionsShow = useAppSelector(
    (state) => state.flowInteractions.overlayInteractionsShow
  );
  // const variables = nodeSelected?.data?
  //   ?.map((robotInteraction) => {
  //     return robotInteraction?.text?.match(/@(\w+)/gim);
  //   })
  //   .filter((notNulls) => notNulls !== null);
  // const onBlurVariables = () => {
  //   if ((variables as RegExpMatchArray[]).length > 1) {
  //     setVisible(true);
  //   }
  // };

  const Accept = () => {
    const variables = nodeSelected?.data?.botInteractions?.map(
      (robotInteraction) => {
        let count = 0;
        if (robotInteraction?.text?.match(/@(\w+)/gimu)) {
          count++;
        }
        if (count > 1) {
          return robotInteraction?.text?.match(/^((?!@).)*$/gimu);
        }
        return robotInteraction;
      }
    );
  };
  const Reject = () => {
    setVisible(false);
  };
  const handleOpen = () => {
    dispatch(setSendsendFileSelectorSOOpenOpen(true));
    dispatch(setSendFileModalOpen(true));
  };

  useEffect(() => {
    if (nodeSelected) {
      setInteractions(nodeSelected.data.botInteractions || []);
    }
  }, [nodeSelected]);

  const handleDragStart = (index: any) => {
    dragInteraction.current = index;
    setDraggingIndex(index);
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  const handleDrop = (index: number) => {
    if (dragInteraction.current === null) return;

    const updatedInteractions = Array.from(interactions);
    const [movedInteraction] = updatedInteractions.splice(
      dragInteraction.current,
      1
    );
    updatedInteractions.splice(index, 0, movedInteraction);
    setInteractions(updatedInteractions);

    dispatch(
      addRobotInteraction({
        nodeId: nodeSelected?.id,
        interactions: updatedInteractions
      })
    );
    setDraggingIndex(null);
    dragInteraction.current = null;
  };

  const handleDragEnd = () => {
    setDraggingIndex(null);
  };

  return (
    <div className="w-full flex flex-column">
      {nodeSelected ? (
        <div
          className="flex bg-white justify-content-start flex-column"
          // style={{
          //   borderTop: "1px solid #EEE"
          // }}
        >
          <OverlayInteractions />
          <OptionsRobotActions />
          <CustomButtonModal />
          <AudiosModal />
          <EmojiModal heightEmojiPicker="320px" />
          <SendFilesModal fromClick={clickOpenFileTransfer} />
          <div
            style={{ border: "3px solid rgba(33, 150, 243, 0.3)" }}
            className="relative mt-2 p-2 w-full border-round-lg"
          >
            <div className="absolute -mt-5 ml-6 bg-white border-round-right-3xl flex">
              <div className="mr-2 flex align-items-center">
                <div className="w-3rem h-3rem -ml-4 shadow-7 text-center border-circle bg-white flex align-items-center justify-content-center">
                  <img
                    src={
                      user.plataform.urlLogo ? user.plataform.urlLogo : botRed
                    }
                    style={{ maxWidth: "25px", maxHeight: "25px" }}
                  />
                </div>

                <div
                  className="ml-1"
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "#046AF3"
                  }}
                >
                  Interação do robô
                </div>
                <div className="flex ml-1 border-circle bg-blue-100 h-2rem w-2rem align-items-center justify-content-center">
                  <div
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "#046AF3"
                    }}
                  >
                    ?
                  </div>
                </div>
              </div>
            </div>

            <div className="content mt-4 ml-2">
              {!nodeSelected?.data?.botInteractions?.length &&
              Boolean(nodeSelected) &&
              isTemplate === false ? (
                <Button
                  style={{ width: "50%", margin: "0 auto" }}
                  type="button"
                  onClick={() => {
                    dispatch(addRobotInteraction({ nodeId: nodeSelected?.id }));
                    console.log({ nodeId: nodeSelected?.id });
                    // dispatch(setVariablesModalOpen(true))
                    dispatch(changedPhoneComponent(true));
                  }}
                >
                  Adicionar Interação Robô
                </Button>
              ) : null}
              {/*Aqui colocar os elementos de interação*/}
              {/* <VariablesModal /> */}

              <ContentInteraction>
                {interactions.map((interaction, idx) => (
                  <div
                    key={`${interaction.id}`}
                    draggable
                    onDragStart={() => handleDragStart(idx)}
                    onDragOver={handleDragOver}
                    onDrop={() => handleDrop(idx)}
                    onDragEnd={handleDragEnd}
                    className={draggingIndex === idx ? "dragging" : ""}
                    onClick={() => {
                      dispatch(setIdxEmoji(interaction.id));
                    }}
                  >
                    <RobotTexts
                      nodeId={nodeSelected?.id}
                      robotInteraction={interaction}
                    />
                  </div>
                ))}
              </ContentInteraction>

              <div className="flex justify-content-between">
                <div className="flex gap-2">
                  {nodeSelected?.type !== "startNode" ? (
                    <>
                      <img src={Actions} alt="Actions-Icon" />
                      <Text lh="21px" color="#046AF3" s="0.875rem">
                        Ações
                      </Text>
                    </>
                  ) : null}
                </div>
                <div>
                  <div css={Flex}>
                    <img src={A} alt="Icon-text-edit" />

                    <ImgIcon
                      disabled={isTemplate}
                      onClick={() => {
                        dispatch(setEmojiModalOpen(true));
                      }}
                      className="tooltip-Smile"
                      data-pr-position="bottom"
                    >
                      <img src={Smile} alt="Icon-Smiles" />
                    </ImgIcon>
                    <Tooltip target=".tooltip-Smile">Enviar Emoji</Tooltip>

                    <ImgIcon
                      disabled={isTemplate}
                      onClick={handleOpen}
                      className="tooltip-Attachment"
                      data-pr-position="bottom"
                    >
                      <img src={Attachment} alt="Icon-Attachment" />
                    </ImgIcon>
                    <Tooltip target=".tooltip-Attachment">
                      Anexar Arquivo
                    </Tooltip>
                    <img src={Link} alt="Icon-Link" />
                    <ImgIcon
                      disabled={isTemplate}
                      onClick={() => dispatch(setAudioModalOpen(true))}
                      className="tooltip-Audio"
                      data-pr-position="bottom"
                    >
                      <img
                        src={MicBlue}
                        // onClick={() =>dispatch(setAudioModalOpen(true))}
                        alt="Icon-MicBlue"
                      />
                    </ImgIcon>
                    <Tooltip target=".tooltip-Audio">Gravar Áudio</Tooltip>

                    <img src={ThreePointsBlue} alt="Icon-ThreePointsBlue" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="bg-white"
            style={{
              border: "1px solid #EEE",
              marginTop: "1rem",
              marginBottom: "1rem"
            }}
          />
        </div>
      ) : null}

      <Dialog header="Erro" visible={visible} onHide={() => setVisible(false)}>
        <div className="flex ">
          <span className="pi pi-exclamation-triangle mr-2" />
          <p>Não é permitido mais de uma variável no mesmo node.</p>
        </div>
        <p>{`Favor excluir um dos "@"`}</p>
        <div className="flex mt-4 mb-4 flex-row-reverse">
          <Button label="OK" className="p-button-info" onClick={Accept} />
          <Button
            label="Cancelar"
            className="p-button-danger ml-2 mr-2"
            onClick={Reject}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default InteracaoRobo;
