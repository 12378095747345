import { Contacts } from "../../assets";
import { css } from "@emotion/react";
import { Column } from "primereact/column";
import { Button, DataTable } from "..";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
import * as comunCss from "../comum_css";
import { Avatar } from "primereact/avatar";
// import useCompany from "./use-company";
// import EditCompany from "../EditCompany";
import {
  ButtonCreate,
  Header,
  Content,
  ContainerPage,
  Container,
  ContainerLeft
} from "./styles";
// import { Identification } from "../header/components/Identification";
import useContacts from "./use-contacts";
import { useAuth } from "../../auth/auth-provider";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Sidebar } from "primereact/sidebar";
import { base64ToBlob } from "../../util/base64ToBlob";
import { blobToBase64 } from "../../util/blobToBase64";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { v } from "../../util";
import { useToast } from "../toast-provider";
import {
  setDataSelectedContacts,
  setButtonCreateCampaign
} from "../../redux/slices/flowInteractions";
import { Text } from "../../components/Text";
import { MyCalendar } from "../../components/MyCalendar";
import { startOfMonth, subYears } from "date-fns";
import { FilterMatchMode, SortOrder } from "primereact/api";
import { DataTableFilterMeta } from "primereact/datatable";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { Tooltip } from "primereact/tooltip";
import PhoneMask from "../../components/phone-mask";
interface IUserDataValidation {
  name: string;
  email: string;
  phone: string;
}

const UserAvatar = ({
  label,
  avatarFile,
  onChange
}: {
  label: string;
  avatarFile?: File;
  onChange: (value?: File) => void;
}) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [preview, setPreview] = useState<string>();

  useEffect(() => {
    if (!avatarFile) {
      setPreview(undefined);
      return;
    }

    let objectUrl = URL.createObjectURL(avatarFile);

    objectUrl = URL.createObjectURL(avatarFile);

    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [avatarFile]);

  return (
    <>
      <Avatar
        css={comunCss.avatarCss}
        label={label}
        className="mr-2"
        size="xlarge"
        shape="circle"
        onClick={() => inputFileRef.current?.click()}
        image={preview}
        style={{ left: "8rem" }}
      />
      <input
        type="file"
        css={css`
          display: none;
        `}
        ref={inputFileRef}
        onChange={(e) => {
          if (!e.target.files || e.target.files.length === 0) {
            onChange(undefined);
          } else {
            onChange(e.target?.files?.[0]);
          }
        }}
      />
    </>
  );
};

interface Filter {
  value: any;
  matchMode: string;
}
interface Filters {
  name?: Filter;
  createdAt?: Filter;
  email?: Filter;
  phone?: Filter;
  lastMessage?: Filter;
}

const ContactsList = () => {
  const { SearchContacts, ListContacts, UpdateContacts } = useContacts();

  const [tablePropsData, setTablePropsData] = useState({});
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(20);
  const [contacts, setContacts] = useState([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const effectRan = useRef(false);
  const [contactDetails, setContactDetails] = useState({
    name: "",
    email: "",
    phone: "",
    workspace: "",
    id: null,
    workspaceId: null
  });
  const dispatch = useAppDispatch();
  const valueFilters = useAppSelector((state) => state.workspaces.filters);
  const [visibleSidebar, setVisibleSidebar] = useState(false);
  const [characterAvatar, setCharacterAvatar] = useState("");
  const toast = useToast();
  const selectedWorkspacesIds = useAppSelector(
    (state) => state.workspaces.selectedWorkspaces
  );
  const [avatarFile, setAvatarFile] = useState<File>();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<IUserDataValidation>({
    defaultValues: {
      name: "",
      email: "",
      phone: ""
    },
    resolver: zodResolver(
      v.object({
        name: v.string().superRefine(v.nonempty()),
        email: v.string().superRefine(v.email()),
        phone: v.string().refine(
          (value) => {
            if (value.startsWith("55")) {
              return /^\d{12,13}$/.test(value);
            }
            return /^\d{10,11}$/.test(value);
          },
          { message: "Formato do telefone inválido" }
        )
      })
    )
  });

  useEffect(() => {
    reset({
      name: contactDetails.name || "",
      email: contactDetails.email || "",
      phone: contactDetails.phone || ""
    });
  }, [contactDetails, reset]);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    limit: 20,
    page: 1,
    sortField: "",
    filters: {
      name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      email: { value: null, matchMode: FilterMatchMode.CONTAINS },
      phone: { value: null, matchMode: FilterMatchMode.CONTAINS },
      createdAt: {
        value: [subYears(new Date(), 1), new Date()],
        matchMode: FilterMatchMode.DATE_AFTER
      },
      lastMessage: {
        value: null,
        matchMode: FilterMatchMode.DATE_AFTER
      }
    }
  });

  const onPage = (event: any) => {
    setRows(event.rows);
    setFirst(event.first);

    setLazyParams((prev) => ({
      ...prev,
      first: event.first,
      limit: event.rows
    }));
  };

  const useDebounce = (value: string, delay: number) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const timer = setTimeout(() => setDebouncedValue(value), delay);

      return () => {
        clearTimeout(timer);
      };
    }, [value, delay]);
    return debouncedValue;
  };

  const debouncedText = useDebounce(lazyParams.filters.name.value, 500);

  useEffect(() => {
    setLazyParams((prev) => ({
      ...prev,
      name: debouncedText
    }));
  }, [debouncedText]);

  const fetchContacts = async () => {
    if (selectedWorkspacesIds && selectedWorkspacesIds.length > 0) {
      const { createdAt, lastMessage } = lazyParams.filters;

      const extractPeriod = (value) => {
        if (value && value[0] instanceof Date && value[1] instanceof Date) {
          return {
            startAt: value[0].toISOString().split("T")[0],
            endAt: value[1].toISOString().split("T")[0]
          };
        }
        return { startAt: null, endAt: null };
      };

      const createdAtPeriod = extractPeriod(createdAt.value);
      const lastMessagePeriod = extractPeriod(lastMessage.value);

      const data = await ListContacts({
        workspaces: selectedWorkspacesIds,
        description: debouncedText,
        offset: lazyParams.first,
        limit: lazyParams.limit,
        email: lazyParams.filters.email.value,
        phone: lazyParams.filters.phone.value,
        createdAtPeriod,
        lastMessagePeriod,
        sortField: lazyParams.sortField,
        sortOrder: lazyParams.sortOrder
      });

      setContacts(data.result);
      setTotalRecords(data.total);
      if (data.image) {
        const type = data.image.substring(4, data.image.indexOf("base64"));

        const blob = base64ToBlob(
          data.image.replace(/data.+?base64/u, ""),
          type
        );

        const file = new File([blob], type.replace("/", "."), {
          lastModified: new Date().getTime(),
          type
        });

        setAvatarFile(file);
      }
    }
  };

  useEffect(() => {
    fetchContacts();
  }, [
    selectedWorkspacesIds,
    selectedContactId,
    globalFilterValue,
    lazyParams.first,
    lazyParams.limit,
    debouncedText,
    lazyParams.filters.phone,
    lazyParams.filters.email,
    lazyParams.filters.createdAt,
    lazyParams.filters.lastMessage,
    lazyParams.sortField,
    lazyParams.sortOrder
  ]);

  useEffect(() => {
    setLazyParams((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        name: {
          ...prev.filters.name,
          value: debouncedText
        }
      }
    }));
  }, [debouncedText]);

  const openSidebar = async (contact: any) => {
    const contactData = await SearchContacts(contact.id);
    setSelectedContactId(contact.id);
    setContactDetails(contactData);
  };

  const onCloseSidebar = () => {
    setSelectedContactId(null);
  };

  const getFirstNameLeter = (name: string) => {
    setCharacterAvatar(name[0].toUpperCase());
  };

  useEffect(() => {
    if (contactDetails.name) {
      setCharacterAvatar(contactDetails.name[0].toUpperCase());
    }
  }, [contactDetails.name]);

  const formatDate = (inputDate: string): string => {
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  const sendUpdate = async () => {
    if (selectedContactId) {
      try {
        const updatedContactDetails = {
          ...contactDetails,
          email:
            !contactDetails.email || contactDetails.email.trim() === ""
              ? null
              : contactDetails.email
        };
        await UpdateContacts(selectedContactId, updatedContactDetails);
        onCloseSidebar();
        setVisibleSidebar(false);
        toast?.current?.show({
          life: 5000,
          severity: "success",
          summary: "Sucesso",
          detail: "Contato atualizado com sucesso!"
        });
      } catch (error) {
        toast?.current?.show({
          life: 5000,
          severity: "error",
          summary: "Erro.",
          detail: "Erro ao atualizar o contato Por favor tente mais tarde."
        });
      }
    }
    setSelectedContactId(null);
  };

  useEffect(() => {
    dispatch(setDataSelectedContacts(selectedContacts));

    if (selectedContacts && selectedContacts.length > 0) {
      dispatch(setButtonCreateCampaign(true));
    } else {
      dispatch(setButtonCreateCampaign(false));
    }
  }, [selectedContacts]);

  const handleSelectionChange = (e: any) => {
    const selectedItems = e.value;

    setSelectedContacts((prevSelectedContacts) => {
      const contactsAnotherPage = prevSelectedContacts.filter(
        (selected) => !contacts.some((contact) => contact.id === selected.id)
      );

      const newSelectedContacts = [...contactsAnotherPage, ...selectedItems];

      return newSelectedContacts;
    });
  };

  const currentPageContacts = () => {
    const currentPageIds = contacts.map((contact) => contact.id);
    const selectedItemsPage = selectedContacts.filter((selected) =>
      currentPageIds.includes(selected.id)
    );

    return selectedItemsPage;
  };

  const applyFilters = (params: any) => {
    if (Object.keys(valueFilters).length > 0) {
      const reconvertedFilters: Filters = Object.keys(valueFilters).reduce(
        (acc, key) => {
          const filter = valueFilters[key as keyof Filters];
          if (filter?.value instanceof Date) {
            acc[key as keyof Filters] = {
              ...filter,
              value: filter.value.toISOString()
            };
          } else if (Array.isArray(filter?.value)) {
            acc[key as keyof Filters] = {
              ...filter,
              value: filter.value.map((v) =>
                v instanceof Date ? v.toISOString() : v
              )
            };
          } else {
            acc[key as keyof Filters] = filter;
          }
          return acc;
        },
        {} as Filters
      );

      return {
        ...params,
        filters: reconvertedFilters as any
      };
    }

    return params;
  };

  const onFilter = (event: any) => {
    event.first = 0;

    const convertedFilters = Object.keys(event.filters).reduce((acc, key) => {
      const filter = event.filters[key];

      if (key === "createdAt" && filter.value) {
        acc[key] = filter.value
          ? {
              ...filter,
              value: Array.isArray(filter.value)
                ? filter.value.map((v) => (v instanceof Date ? v : new Date(v)))
                : filter.value instanceof Date
                ? filter.value
                : new Date(filter.value)
            }
          : { ...filter, value: null };
      } else if (key === "lastMessage" && filter.value) {
        acc[key] = filter.value
          ? {
              ...filter,
              value: Array.isArray(filter.value)
                ? filter.value.map((v) => (v instanceof Date ? v : new Date(v)))
                : filter.value instanceof Date
                ? filter.value
                : new Date(filter.value)
            }
          : { ...filter, value: null };
      } else {
        acc[key] = filter.value
          ? {
              ...filter,
              value: Array.isArray(filter.value) ? filter.value : filter.value
            }
          : { ...filter, value: null };
      }

      return acc;
    }, {});

    const newParams = applyFilters({ ...event, filters: convertedFilters });
    setLazyParams(newParams);
  };

  const onSort = (event: any) => {
    let newSortOrder = 1;
    if (lazyParams.sortOrder === 1) {
      newSortOrder = -1;
    } else if (lazyParams.sortOrder === -1) {
      newSortOrder = 0;
    }

    setLazyParams((prevParams) => ({
      ...prevParams,
      sortField: event.sortField,
      sortOrder: newSortOrder,
      first: 0
    }));
  };

  const createdIn = (options: any) => (
    <div style={{ display: "flex", gap: "5px" }}>
      <MyCalendar
        style={{ width: "100%" }}
        value={lazyParams.filters.createdAt.value}
        selectionMode="range"
        onChange={(e) => {
          const dates = e.value;

          setLazyParams((prev) => ({
            ...prev,
            filters: {
              ...prev.filters,
              createdAt: {
                ...prev.filters.createdAt,
                value: dates
              }
            }
          }));

          // options.filterApplyCallback(dates);
        }}
        dateFormat="dd/mm/yy"
        placeholder="Período"
        mask="99/99/9999"
        locale="pt-br"
        readOnlyInput={true}
        inputStyle={{
          padding: "10px",
          borderRadius: "5px",
          backgroundColor: "#ffffff",
          cursor: "pointer"
        }}
      />

      <Button
        icon="pi pi-times"
        style={{
          color: "#7C757D"
        }}
        onClick={() => {
          setLazyParams((prev) => ({
            ...prev,
            filters: {
              ...prev.filters,
              createdAt: {
                ...prev.filters.createdAt,
                value: null
              }
            }
          }));
        }}
        className="p-button-text mt-2 tooltip-Button"
      />
      <Tooltip target=".tooltip-Button">Limpar datas</Tooltip>
    </div>
  );

  const dateLastMessage = (options: any) => (
    <div style={{ display: "flex", gap: "5px" }}>
      <MyCalendar
        style={{ width: "100%" }}
        value={lazyParams.filters.lastMessage.value}
        selectionMode="range"
        onChange={(e) => {
          const dates = e.value;

          setLazyParams((prev) => ({
            ...prev,
            filters: {
              ...prev.filters,
              lastMessage: {
                ...prev.filters.lastMessage,
                value: dates
              }
            }
          }));

          // options.filterApplyCallback(dates);
        }}
        dateFormat="dd/mm/yy"
        placeholder="Período"
        mask="99/99/9999"
        locale="pt-br"
        readOnlyInput={true}
        inputStyle={{
          padding: "10px",
          borderRadius: "5px",
          backgroundColor: "#ffffff",
          cursor: "pointer"
        }}
      />

      <Button
        icon="pi pi-times"
        style={{
          color: "#7C757D"
        }}
        onClick={() => {
          setLazyParams((prev) => ({
            ...prev,
            filters: {
              ...prev.filters,
              lastMessage: {
                ...prev.filters.lastMessage,
                value: null
              }
            }
          }));
        }}
        className="p-button-text mt-2 tooltip-Button"
      />
      <Tooltip target=".tooltip-Button">Limpar datas</Tooltip>
    </div>
  );

  const handleInputSearch = (e: any) => {
    setLazyParams((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        name: {
          ...prev.filters.name,
          value: e.target.value
        }
      }
    }));
  };

  const paginatorCustomTemplate = {
    layout: "RowsPerPageDropdown PrevPageLink PageLinks NextPageLink",
    RowsPerPageDropdown: (options: {
      value: number;
      onChange: ((e: DropdownChangeParams) => void) | undefined;
    }) => {
      const dropdownOptions = [
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: 100, value: 100 }
      ];

      return (
        <div
          style={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <span
            style={{
              minWidth: "fit-content"
            }}
          >
            Por página:
          </span>

          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
            css={css`
              .p-inputtext {
                color: #6e6b7b;
              }
            `}
          />
        </div>
      );
    }
  };

  return (
    <ContainerPage>
      <div>
        <div className="p-5 gap-2">
          <Header>
            <div
              css={comunCss.TitleContent}
              style={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              <img src={Contacts} width="25px" />
              Contatos
            </div>
          </Header>

          <Content>
            <div className="divTitle">Gerencie os contatos cadastrados.</div>

            <div className="divTitle">
              <div className="divFilter">
                <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText
                    value={lazyParams.filters.name.value || ""}
                    onChange={handleInputSearch}
                    id="name"
                    style={{
                      height: "100%",
                      width: "400px"
                    }}
                    placeholder="Buscar"
                  />
                </span>
              </div>

              <DataTable
                className="custom-datatable"
                value={contacts}
                paginator
                rows={rows}
                first={first}
                filterDelay={600}
                emptyMessage="Nenhum resultado encontrado"
                onSelectionChange={handleSelectionChange}
                selection={currentPageContacts()}
                filterDisplay="row"
                onFilter={onFilter}
                filters={lazyParams.filters}
                totalRecords={totalRecords}
                onSort={onSort}
                onPage={onPage}
                lazy={true}
                paginatorTemplate={paginatorCustomTemplate}
                removableSort={true}
                sortField={lazyParams.sortField}
                sortOrder={lazyParams.sortOrder}
              >
                <Column
                  selectionMode="multiple"
                  headerStyle={{ width: "60px" }}
                  alignHeader="center"
                  align="center"
                />

                <Column
                  field="name"
                  header="Nome"
                  headerStyle={{ width: "200px" }}
                  alignHeader="center"
                  filter={true}
                  showFilterMenu={false}
                  sortable={true}
                />

                <Column
                  field="email"
                  header="E-mail"
                  headerStyle={{ width: "230px" }}
                  alignHeader="center"
                  filter={true}
                  showFilterMenu={false}
                  sortable={true}
                />

                <Column
                  field="phone"
                  header="Telefone"
                  headerStyle={{ width: "200px" }}
                  alignHeader="center"
                  filter={true}
                  showFilterMenu={false}
                  sortable={true}
                />

                <Column
                  field="createdAt"
                  header="Criado em"
                  headerStyle={{ width: "200px" }}
                  alignHeader="center"
                  filter={true}
                  sortable={true}
                  showFilterMenu={false}
                  filterElement={createdIn}
                  body={(rowData) => (
                    <div className="w-full flex justify-content-center">
                      <Text color="#6E6B7B" weight={300} s="0.875rem">
                        {formatDate(rowData.createdAt)}
                      </Text>
                    </div>
                  )}
                />
                <Column
                  field="lastMessage"
                  header="Última Mensagem"
                  headerStyle={{ width: "200px" }}
                  alignHeader="center"
                  filter={true}
                  sortable={true}
                  showFilterMenu={false}
                  filterElement={dateLastMessage}
                  body={(rowData) => (
                    <div className="w-full flex justify-content-center">
                      <Text color="#6E6B7B" weight={300} s="0.875rem">
                        {rowData.lastMessage
                          ? formatDate(rowData.lastMessage)
                          : "Sem registros"}
                      </Text>
                    </div>
                  )}
                />

                <Column
                  header="Ações"
                  headerStyle={{ width: "120px" }}
                  align="center"
                  body={(rowData) => (
                    <>
                      <Button
                        label="Editar"
                        className="p-button-outlined"
                        onClick={() => openSidebar(rowData)}
                        css={comunCss.buttonCustom}
                      />
                    </>
                  )}
                  alignHeader="center"
                />
              </DataTable>
            </div>
          </Content>
        </div>
      </div>

      <Sidebar
        style={{ backgroundColor: "#f0f0f0" }}
        className="p-sidebar-sm"
        visible={selectedContactId !== null}
        position="right"
        dismissable={true}
        onHide={onCloseSidebar}
      >
        <div>
          <UserAvatar
            label={characterAvatar}
            avatarFile={avatarFile}
            onChange={(file) => setAvatarFile(file)}
          />
          <div className="-mt-5">
            <div
              css={comunCss.boxProfileHead}
              style={{ borderBottom: "solid 1px #E5E5E5" }}
            >
              <div
                css={comunCss.TitleContent}
                className="flex align-items-center justify-content-center"
              >
                {contactDetails.name}
              </div>
              <span
                css={comunCss.idColor}
                className="flex align-items-center justify-content-center text-base"
              >
                ID {contactDetails.id}
              </span>
            </div>
            <div>
              <span className="field mt-2 relative" style={{ top: "-10px" }}>
                <label
                  css={comunCss.inputLabelDefault}
                  style={{ background: "none" }}
                  htmlFor="userName"
                >
                  Nome
                </label>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <InputText
                      {...field}
                      className="w-full"
                      css={comunCss.noBorder}
                      style={{ background: "#F0F0F0" }}
                      id="userName"
                      value={contactDetails.name}
                      onChange={(e) => {
                        setContactDetails({
                          ...contactDetails,
                          name: e.target.value
                        });
                        field.onChange(e);
                      }}
                    />
                  )}
                />
              </span>
              {errors.name?.message ? (
                <div style={{ color: "#AA3939" }}>{errors.name.message}</div>
              ) : null}
            </div>
            <div>
              <span className="field mt-2 relative" style={{ top: "-10px" }}>
                <label
                  css={comunCss.inputLabelDefault}
                  style={{ background: "none" }}
                  htmlFor="userEmail"
                >
                  E-mail
                </label>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <InputText
                      {...field}
                      className="w-full"
                      css={comunCss.noBorder}
                      style={{ background: "#F0F0F0" }}
                      id="userEmail"
                      value={contactDetails.email}
                      onChange={(e) => {
                        setContactDetails({
                          ...contactDetails,
                          email: e.target.value
                        });
                        field.onChange(e);
                      }}
                    />
                  )}
                />
              </span>
              {errors.email?.message && (
                <div style={{ color: "#AA3939", marginTop: "5px" }}>
                  {errors.email.message}
                </div>
              )}

              <span className="field mt-2 relative" style={{ top: "-10px" }}>
                <label
                  css={comunCss.inputLabelDefault}
                  style={{ background: "none" }}
                  htmlFor="userPhone"
                >
                  Telefone
                </label>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <PhoneMask
                      {...field}
                      value={contactDetails.phone}
                      onChange={(e) => {
                        setContactDetails({
                          ...contactDetails,
                          phone: e.target.value
                        });
                        field.onChange(e);
                      }}
                      style={{
                        background: "#F0F0F0",
                        width: "100%",
                        borderRadius: "0px",
                        border: "1px solid #ced4da",
                        padding: "8px 12px",
                        color: "#6e6b7b",
                        fontSize: "12px"
                      }}
                    />
                  )}
                />
              </span>
              {errors.phone?.message && (
                <div style={{ color: "#AA3939", marginTop: "5px" }}>
                  {errors.phone.message}
                </div>
              )}
            </div>
          </div>
          <Button
            title="Atualizar Colaborador"
            label="SALVAR"
            onClick={handleSubmit(sendUpdate)}
            icon="pi pi-check-circle"
            css={comunCss.saveBtn}
            className="saveBTN p-button-raised p-button-lg mt-3 p-button-text w-full text-blue-800"
            permission={["contact_edit"]}
          />
        </div>
      </Sidebar>
    </ContainerPage>
  );
};

export default ContactsList;
