import { Header2 } from "../../../components/header/components/Header2";
import { Children, ReactNode } from "react";

interface ILayoutHeader2 {
  children: ReactNode;
}
export const LayoutHeader2: React.FC<ILayoutHeader2> = ({ children }) => {
  return (
    <>
      <Header2 />
      {children}
    </>
  );
};
