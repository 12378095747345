import { css } from "@emotion/react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { v } from "../../util";
import * as comunCss from "../../components/comum_css";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { TridimentionalWhatsIcon } from "../../assets";
import QrCodeServices from "../../services/omnichannelQrcode";
import { useToast } from "../../components/toast-provider";
import { AuthContextType, useAuth } from "../../auth/auth-provider";
import InputPhone from "../../components/input-fone";

export const LeftActiveBox: React.FC<{
  name: string;
  phone: string;
  emitDisconnect: () => void;
}> = ({ name, phone, emitDisconnect }) => {
  interface QRCodeInfoValidation {
    name: string;
    phone: string;
  }

  const { user } = useAuth() as AuthContextType;
  const toast = useToast();
  const countrySplitter = require("country-code-splitter");
  const splittedPhone = countrySplitter.getNumber(`+${phone}`);
  splittedPhone.code = splittedPhone.code.slice(1);

  const [nameField, setNameField] = useState(name);
  const [phoneField, setPhoneField] = useState(
    splittedPhone.code + splittedPhone.number
  );
  const [maskedPhone, setMaskedPhone] = useState([
    splittedPhone.code,
    splittedPhone.number
  ]);

  const onChangeName = (name: string) => setNameField(name);

  const handleInputChange = (values: string[]) => {
    const completePhone = values[0] + values[1];

    setMaskedPhone(values);

    setPhoneField(completePhone);
  };

  const { DisconectWhatsapp } = QrCodeServices();

  const disconectWhatsapp = async () => {
    await DisconectWhatsapp(user.id)
      .then(async (res) => {
        toast?.current?.show({
          life: 5000,
          severity: "success",
          summary: "Sucesso",
          detail: "Desconectado com sucesso!"
        });

        emitDisconnect();
      })
      .catch(() => {
        toast?.current?.show({
          life: 5000,
          severity: "error",
          summary: "Erro",
          detail: "Erro ao buscar dados. Por favor tente mais tarde!"
        });
      });
  };

  const {
    control,
    formState: { errors }
  } = useForm<QRCodeInfoValidation>({
    defaultValues: {
      name: "",
      phone: ""
    },
    values: {
      name: nameField,
      phone: phoneField
    },
    resolver: zodResolver(
      v.object({
        name: v.string().superRefine(v.nonempty()),
        phone: v.string().superRefine(v.nonempty())
      })
    )
  });

  return (
    <div
      className="text-white"
      css={css`
        height: 100%;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              width: 100%;
              margin: 20px 0;
            `}
          >
            <div className="mr-5">
              <img src={TridimentionalWhatsIcon} alt="3dWhats" />
            </div>

            <div>
              <div
                css={css`
                  font-size: 14px;
                  font-weight: 600;
                  color: #0a043c;
                `}
              >
                Todas as conversas em um único lugar!
              </div>
            </div>
          </div>

          <span className="field mb-0 relative" style={{ top: "-10px" }}>
            <label
              css={comunCss.inputLabelDefault}
              htmlFor="userName"
              className="block"
            >
              Responsável
            </label>

            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <InputText
                  {...field}
                  className="w-full"
                  css={comunCss.noBorder}
                  id="userName"
                  value={nameField}
                  onChange={(e) => onChangeName(e.target.value)}
                  disabled={true}
                />
              )}
            />

            {errors.name?.message ? (
              <div style={{ color: "#AA3939" }}>{errors.name.message}</div>
            ) : null}
          </span>

          <InputPhone
            id="phone-input"
            value={maskedPhone}
            onChange={handleInputChange}
            disabled={true}
          />

          <div
            css={css`
              display: flex;
              align-items: center;
              margin-top: 15px;
            `}
          >
            <div
              css={css`
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                min-height: 20px;
                min-width: 20px;
                margin-right: 10px;
                border-radius: 50%;
                background: #d4e2f3;
                color: #046af3;
              `}
            >
              ?
            </div>

            <div
              css={css`
                color: #4c5862;
              `}
            >
              Lembre-se de usar o celular uma vez a cada 14 dias para ficar
              sempre conectado.
            </div>
          </div>
        </div>

        <div
          css={css`
            display: flex;
            justify-content: space-around;
            width: 100%;
          `}
        >
          <Button
            className="p-button-danger p-button-outlined"
            css={css`
              display: flex;
              justify-content: center;
              font-weight: 600;
              width: 100%;
              border-radius: 8px;
            `}
            onClick={async () => {
              disconectWhatsapp();
            }}
          >
            Remover número
          </Button>
        </div>
      </div>
    </div>
  );
};
