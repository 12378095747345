import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  BarElement,
  InteractionItem,
  ChartData
} from "chart.js";
import { MultiSelect, MultiSelectProps } from "primereact/multiselect";
import { useEffect, useRef, useState } from "react";
import "./pageStyle.css";
import { Bar, Doughnut, Line, Pie, getElementAtEvent } from "react-chartjs-2";
import {
  BgGradient,
  Card,
  Container,
  ContainerIcon,
  ContainerImage,
  ContainerTransparent,
  DivImage,
  Img,
  SelectMultiple,
  VDivisor,
  MinutesCard,
  TableText,
  TitleLine,
  HalfChartContainer,
  SpanCollor,
  IconDiv,
  FullChartContainer,
  ButtonReload
} from "./styles";
import {
  Actions,
  AtendimentoHumano,
  BalloonIconWhite,
  CalendarWhite,
  Clock1,
  DashboardRed,
  Girl1,
  HistoryGraph,
  ReloadWhite,
  SmileWhite,
  bot
} from "../../../assets";
import { Text } from "../../../components/Text";
import BotsServices from "../../../services/bots";
import DashboardServices from "../../../services/dashboardService";
import { useAppSelector } from "../../../redux/hooks";
import { Dropdown, useToast } from "../../../components";
import { MyCalendar } from "../../../components/MyCalendar";
import { AuthContextType, useAuth } from "../../../auth/auth-provider";
import {
  compareAsc,
  eachDayOfInterval,
  eachMonthOfInterval,
  format,
  getMonth,
  getYear,
  parse
} from "date-fns";
import { TreeSelect, TreeSelectSelectionKeys } from "primereact/treeselect";
import { ListBox } from "primereact/listbox";
import styled from "@emotion/styled";

ChartJS.register(
  ArcElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
);

interface iBots {
  id: number;
  name: string;
}

interface iDatasets {
  label: string;
  data: number[];
  borderColor: string;
  backgroundColor: string;
  tension: number;
}
interface iDataLineChart {
  labels: string;
  datasets: iDatasets[];
}
interface ServicesByDayHistory {
  count: number;
  day: string;
}

interface ChannelsService {
  averageTime: number;
  channel: string;
  count: number;
}

interface BotVsHumanService {
  botServices: number;
  humanServices: number;
  month: number;
  year: number;
}

interface iDashboardData {
  botVsHumanServices: BotVsHumanService[];
  channelsServices: ChannelsService[];
  closedServices: number;
  humanServices: number;
  humanServicesMinutes: number;
  messages: number;
  openServices: number;
  servicesByDayHistory: ServicesByDayHistory[];
  targetsServices: ChannelsService[];
}

interface ILineChartByHour {
  count: number;
  hour: number;
}
export const DashboardPage: React.FC = () => {
  const { ListBotsByWorksPaces } = BotsServices();
  const { getDashboardByDates, getDashboardByHours } = DashboardServices();
  const [dataLineChartByHour, setDataLineChartByHour] =
    useState<ChartData<"bar", number[], unknown>>();
  const [dashboardData, setDashboardData] = useState<iDashboardData>(
    {} as iDashboardData
  );
  const [datesRange, setDatesRange] = useState<Date[]>([
    new Date(),
    new Date()
  ]);
  const { user, company } = useAuth() as AuthContextType;
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const noData =
    !dashboardData?.botVsHumanServices?.length &&
    !dashboardData?.channelsServices?.length &&
    dashboardData?.closedServices === 0 &&
    dashboardData?.humanServices === 0 &&
    dashboardData?.openServices === 0 &&
    !dashboardData?.servicesByDayHistory?.length &&
    !dashboardData?.targetsServices?.length;
  const [typeConsult, setTypeConsult] = useState([]);
  const [botsOptions, setBotsOptions] = useState<iBots[]>([]);
  const WorkspacesSelected = useAppSelector(
    (state) => state.workspaces.selectedWorkspaces
  );
  // Pie Config Data
  const data = {
    // labels: ["Whatsapp", "IM Chat", "Omnichannel", "Presencial"],
    labels: dashboardData?.channelsServices?.map((el) => el.channel),
    datasets: [
      {
        label: " Número de Atendimentos",
        // data: [35, 108, 67, 46],
        data: dashboardData?.channelsServices?.map((el) => el.count),
        backgroundColor: ["#40EF90", "#7367F0", "#F76D6E", "#FF9F43"],
        hoverOffset: 10,
        borderWidth: 5
      }
    ]
  };

  const getDashBoardData = async (bots: number[]) => {
    if (datesRange[0] === null && datesRange[1] === null) {
      toast?.current?.show({
        life: 3000,
        severity: "error",
        summary: "Dada inválida",
        detail:
          "Favor selecionar pelo menos uma data para pesquisa ou intervalo de dadas para buscar um período."
      });
    }

    if (!bots.length) {
      return;
    }

    try {
      setLoading(true);

      const startAt = datesRange[0] as Date;
      let endAt = startAt;

      if (datesRange[1] !== null) {
        endAt = datesRange[1] as Date;
      }

      const dashboardResults: iDashboardData = await getDashboardByDates({
        bots,
        startAt: startAt.toISOString().substring(0, 10),
        endAt: endAt.toISOString().substring(0, 10)
      });

      const days = eachDayOfInterval({ start: startAt, end: endAt });

      for (const day of days) {
        if (
          !dashboardResults.servicesByDayHistory.some(
            (b) => b.day === format(day, "yyyy-MM-dd")
          )
        ) {
          dashboardResults.servicesByDayHistory.push({
            count: 0,
            day: format(day, "yyyy-MM-dd")
          });
        }
      }

      dashboardResults.servicesByDayHistory.sort((a, b) =>
        compareAsc(
          parse(a.day, "yyyy-MM-dd", new Date()),
          parse(b.day, "yyyy-MM-dd", new Date())
        )
      );

      const months = eachMonthOfInterval({ start: startAt, end: endAt });

      for (const month of months) {
        if (
          !dashboardResults.botVsHumanServices.some(
            (b) => b.month === getMonth(month) + 1 && b.year === getYear(month)
          )
        ) {
          dashboardResults.botVsHumanServices.push({
            botServices: 0,
            humanServices: 0,
            month: getMonth(month) + 1,
            year: getYear(month)
          });
        }
      }

      dashboardResults.botVsHumanServices.sort((a, b) => {
        if (a.year === b.year) {
          return a.month - b.month;
        }

        return a.year - b.year;
      });

      toast?.current?.show({
        life: 3000,
        severity: "success",
        summary: "Ok.",
        detail: "Dados atualizados com sucesso."
      });

      setDashboardData(dashboardResults);
      setLoading(false);
    } catch (error) {
      toast?.current?.show({
        life: 3000,
        severity: "error",
        summary: "Não foi possível buscar os dados neste momento.",
        detail: "Por favor tente mais tarde."
      });
    } finally {
      setLoading(false);
    }
  };

  const getListBotsResults = async () => {
    setLoading(true);
    if (WorkspacesSelected?.length > 0 && !loading) {
      try {
        const results = await ListBotsByWorksPaces({
          workspaces: WorkspacesSelected,
          limit: 100,
          offset: 0,
          companyId: user?.admin ? company.id : user.companyId
        });
        const resultsIds = results?.result?.map((el: { id: number }) => el.id);
        setBotsOptions(results?.result);
        setTypeConsult(resultsIds);
        getDashBoardData(resultsIds);
        setLoading(false);
      } catch (error) {
        toast?.current?.show({
          life: 3000,
          severity: "error",
          summary: "Não foi possível buscar os dados neste momento.",
          detail: "Por favor tente mais tarde.",
          sticky: true
        });
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getListBotsResults();
  }, [WorkspacesSelected]);

  const dataDoughnut = {
    labels: dashboardData?.targetsServices?.map(
      (el) => el.channel || "Não informado"
    ),
    datasets: [
      {
        label: " Número de Atendimentos",
        data: dashboardData?.targetsServices?.map((el) => el.count || 0),
        backgroundColor: [
          "#F76D6E",
          "#7367F0",
          "#40EF90",
          "#FF9F43",
          "#36A2EB",
          "#FFCE56"
        ],
        hoverOffset: 10,
        borderWidth: 5,
        cutout: 80
      }
    ]
  };
  // Line Chart config
  const optionsLineChart = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const
      },
      title: {
        display: false,
        text: "Gráfico de linhas"
      }
    }
  };
  const dataLineChart = {
    labels: dashboardData?.servicesByDayHistory?.map((el) =>
      new Date(el.day).toLocaleDateString("pt-BR", { timeZone: "UTC" })
    ),
    datasets: [
      {
        label: "Número de Atendimentos",
        data: dashboardData?.servicesByDayHistory?.map((el) => el.count),
        borderColor: "rgb(75, 192, 192)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        tension: 0.4
      }
    ]
  };

  const chartRef = useRef<ChartJS>(null);

  const onClick = (event: React.MouseEvent<HTMLCanvasElement>) => {
    const { current: chart } = chartRef;
    event.preventDefault();
    if (!chart) {
      return;
    }
    printElementAtEvent(getElementAtEvent(chart, event));
  };

  const printElementAtEvent = async (element: InteractionItem[]) => {
    if (!element.length) {
      return;
    }
    const { datasetIndex, index } = element[0];
    const stringDate = dataLineChart.labels[index] as string;
    type DateElements = [string, string, string];
    const stringSplited = stringDate?.split("/") as DateElements;
    const dateFormatToConsult = new Date(
      Number(stringSplited[2]),
      Number(stringSplited[1]) - 1,
      Number(stringSplited[0])
    )
      .toISOString()
      .substring(0, 10);
    const resultDataLineChartByHour: ILineChartByHour[] =
      await getDashboardByHours({
        bots: typeConsult,
        day: dateFormatToConsult
      });
    const dataLineChartHours = {
      labels: resultDataLineChartByHour?.map((el) => el.hour),
      datasets: [
        {
          label: "Atendimentos/hora",
          data: resultDataLineChartByHour?.map((el) => el.count),
          borderColor: "rgb(75, 192, 192)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
          tension: 0.4
        }
      ]
    };
    //@ts-ignore
    setDataLineChartByHour(dataLineChartHours);
  };

  // stack chart
  const optionsStackBarChart = {
    plugins: {
      // title: {
      //   display: true,
      //   text: "Chart.js Bar Chart - Stacked"
      // }
    },
    responsive: true,
    scales: {
      x: {
        stacked: true
      },
      y: {
        stacked: true
      }
    }
  };
  const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1);
  const labelsStackChart = dashboardData?.botVsHumanServices?.map(
    (el) => `${el.month}/${el.year}`
  );
  const dataStack = {
    labels: labelsStackChart,
    datasets: [
      {
        label: "Atendimentos Robô",
        data: dashboardData?.botVsHumanServices?.map((el) => el.botServices),
        backgroundColor: "#2FD095"
      },
      {
        label: "Atendimento Humano",
        data: dashboardData?.botVsHumanServices?.map((el) => el.humanServices),
        backgroundColor: "#1787E0"
      }
    ]
  };
  const typeConsultTemplate = (option: MultiSelectProps) => {
    const nameOption = botsOptions?.filter((el) => el.id === option)[0]?.name;
    if (option) {
      return (
        <div className="flex gap-1">
          <img width="15rem" alt={nameOption} src={bot} />
          <Text weight={400} lh="1.75rem" color="#FFF">
            {nameOption}
          </Text>
        </div>
      );
    }

    return (
      <div className="flex gap-1">
        <img width="15rem" alt={nameOption} src={bot} className="flag" />
        <Text weight={400} lh="1.75rem" color="#FFF">
          Robôs de atendimento
        </Text>
      </div>
    );
  };
  const typeConsultOptionTemplate = (option: any) => {
    const isSelected = typeConsult && typeConsult.includes(option.id);
    return (
      <div
        style={{ backgroundColor: "transparent" }}
        className="flex w-full align-items-center gap-3"
      >
        <img
          width="15rem"
          alt={option.name}
          src={bot}
          className={`flag flag-${option.code}`}
        />
        <Text weight={400} lh="1.75rem" color={isSelected ? "#FFF" : "#000"}>
          {option.name}
        </Text>
      </div>
    );
  };

  return (
    <Container
      buttonColor={
        user.plataform.mainColor ? user.plataform.mainColor : "#418ff6"
      }
    >
      <BgGradient
        style={{
          background: user.plataform.mainColor
            ? user.plataform.mainColor
            : "linear-gradient(154.77deg, #046af3 9.18%, #95c2ff 90.33%)"
        }}
      />
      <ContainerTransparent>
        <div className="flex align-items-center mt-4 gap-3">
          <ContainerImage>
            <DivImage>
              <Img src={DashboardRed} alt="Bot Girl" />
            </DivImage>
          </ContainerImage>
          <Text s="1.25rem" weight={400} lh="1.75rem" color="#FFF">
            Dashboard
          </Text>
        </div>
        <div className="flex align-items-center justify-content-center">
          <SelectMultiple>
            <MultiSelect
              appendTo="self"
              optionLabel="name"
              optionValue="id"
              style={{
                alignItems: "center",
                backgroundColor: "transparent",
                border: "none",
                outline: 0,
                maxHeight: "80%",
                width: "21rem"
              }}
              selectedItemTemplate={typeConsultTemplate}
              itemTemplate={typeConsultOptionTemplate}
              value={typeConsult}
              options={botsOptions}
              onChange={(e) => setTypeConsult(e.value)}
              placeholder="Selecione"
              filter={true}
            />

            <VDivisor />

            <MyCalendar
              className="custom-calendar-maincolor"
              id="range"
              appendTo="self"
              value={datesRange}
              onChange={(e) => setDatesRange(e.value as Date[])}
              selectionMode="range"
              showIcon={true}
              readOnlyInput={true}
              dateFormat="dd/mm/yy"
              placeholder="Período"
              mask="99/99/9999"
              locale="pt-br"
              icon={<img width="15rem" src={CalendarWhite} />}
              iconPos="right"
              style={{
                width: "16rem"
              }}
            />
            <VDivisor />
            <div className="flex w-2rem mr-1rem align-items-center justify-content-center">
              <ButtonReload
                rotateIcon={loading}
                onClick={() => getDashBoardData(typeConsult)}
                style={{ marginRight: "0.9rem" }}
                src={ReloadWhite}
                alt="Reload-Button-White"
              />
            </div>
          </SelectMultiple>
        </div>
        <div
          className="flex align-items-start justify-content-center flex-wrap"
          style={{ columnGap: "3rem", rowGap: "1rem" }}
        >
          <Card>
            <ContainerIcon>
              <img width="60%" src={bot} />
            </ContainerIcon>
            <Text
              style={{ textAlign: "left" }}
              color="#FFF"
              s="0.8125rem"
              lh="1.125rem"
              weight={300}
            >
              Em andamento (aberto)
            </Text>
            <Text
              style={{ textAlign: "left" }}
              color="#FFF"
              s="1.25rem"
              lh="1.75rem"
              weight={600}
            >
              {dashboardData?.openServices}
            </Text>
          </Card>
          <Card>
            <ContainerIcon>
              <img src={SmileWhite} />
            </ContainerIcon>
            <Text
              style={{ textAlign: "left" }}
              color="#FFF"
              s="0.8125rem"
              lh="1.125rem"
              weight={300}
            >
              Atendimentos finalizados
            </Text>
            <Text
              style={{ textAlign: "left" }}
              color="#FFF"
              s="1.25rem"
              lh="1.75rem"
              weight={600}
            >
              {dashboardData?.closedServices}
            </Text>
          </Card>
          <Card>
            <ContainerIcon>
              <img src={BalloonIconWhite} />
            </ContainerIcon>

            <Text
              style={{ textAlign: "left" }}
              color="#FFF"
              s="0.8125rem"
              lh="1.125rem"
              weight={300}
            >
              Mensagens trocadas
            </Text>
            <Text
              style={{ textAlign: "left" }}
              color="#FFF"
              s="1.25rem"
              lh="1.75rem"
              weight={600}
            >
              {dashboardData?.messages ? dashboardData?.messages : "0"}
            </Text>
          </Card>
          <Card>
            <ContainerIcon>
              <img src={AtendimentoHumano} />
            </ContainerIcon>

            <Text
              style={{ textAlign: "left" }}
              color="#FFF"
              s="0.8125rem"
              lh="1.125rem"
              weight={300}
            >
              Atendimento Humano
            </Text>
            <Text
              style={{ textAlign: "left" }}
              color="#FFF"
              s="1.25rem"
              lh="1.75rem"
              weight={600}
            >
              {dashboardData?.humanServices}
            </Text>
          </Card>
        </div>
        <MinutesCard>
          {user.plataform.urlAvatar ? (
            <img src={user.plataform.urlAvatar} style={{ height: "85px" }} />
          ) : (
            <img src={Girl1} />
          )}

          {noData ? (
            <Text color="#0A043C" s="1.25rem" lh="1.75rem" weight={600}>
              Sem dados
            </Text>
          ) : (
            <>
              <Text color="#0A043C" s="1.25rem" lh="1.75rem" weight={600}>
                {dashboardData?.humanServicesMinutes}min.
              </Text>
              <div>
                <Text color="#0A043C" s="1rem">
                  Atendimento Humano
                </Text>
                <Text color="#046AF3" s="0.875rem">
                  economizados por robô
                </Text>
              </div>
            </>
          )}
        </MinutesCard>
        {noData ? (
          <Text color="#0A043C" s="1.25rem" lh="1.75rem" weight={600}>
            Sem dados
          </Text>
        ) : (
          <div className="flex gap-2">
            {dashboardData?.channelsServices &&
            dashboardData.channelsServices.length > 0 ? (
              <HalfChartContainer>
                <TitleLine>
                  <div className="flex gap-2 align-items-center">
                    <IconDiv>
                      <img src={Clock1} />
                    </IconDiv>
                    <Text
                      color="#0A043C"
                      s="1.125rem"
                      weight={400}
                      lh="1.25rem"
                    >
                      Canais de atendimento
                    </Text>
                  </div>
                  <span>?</span>
                </TitleLine>
                <div className="w-20rem h-18rem mx-auto">
                  <Pie data={data} />
                </div>
                <TableText>
                  <div className="flex flex-row w-full">
                    <Text
                      style={{ width: "6rem", textAlign: "left" }}
                      s="1.125rem"
                      lh="1.687rem"
                    >
                      Omnichannel
                    </Text>
                    <Text style={{ width: "6rem" }} s="1.125rem" lh="1.687rem">
                      Quantidade
                    </Text>
                    <Text
                      style={{ width: "8rem", textAlign: "right" }}
                      s="1.125rem"
                      lh="1.687rem"
                    >
                      Tempo médio
                    </Text>
                  </div>
                  {dashboardData.channelsServices.map((el, idx) => (
                    <div key={idx}>
                      <div className="flex align-items-center gap-2">
                        <SpanCollor
                          color={data.datasets[0].backgroundColor[idx]}
                        />
                        <Text
                          style={{ width: "6rem", textAlign: "left" }}
                          lh="2rem"
                        >
                          {el?.channel}
                        </Text>
                      </div>
                      <Text style={{ width: "6rem" }} lh="2rem">
                        {el.count}
                      </Text>
                      <Text
                        style={{ width: "8rem", textAlign: "right" }}
                        lh="2rem"
                      >
                        {el.averageTime}min.
                      </Text>
                    </div>
                  ))}
                </TableText>
              </HalfChartContainer>
            ) : (
              <HalfChartContainer>
                <TitleLine>
                  <div className="flex gap-2 align-items-center">
                    <IconDiv>
                      <img src={Clock1} />
                    </IconDiv>
                    <Text
                      color="#0A043C"
                      s="1.125rem"
                      weight={400}
                      lh="1.25rem"
                    >
                      Canais de atendimento
                    </Text>
                  </div>
                  <span>?</span>
                </TitleLine>
                <div
                  className="w-20rem h-18rem mx-auto"
                  style={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <Text s="1.125rem" lh="1.687rem" color="#0A043C">
                    Sem dados disponíveis no período selecionado
                  </Text>
                </div>
                <TableText>
                  <div className="flex flex-row w-full">
                    <Text
                      style={{ width: "6rem", textAlign: "left" }}
                      s="1.125rem"
                      lh="1.687rem"
                    >
                      Omnichannel
                    </Text>
                    <Text style={{ width: "6rem" }} s="1.125rem" lh="1.687rem">
                      Quantidade
                    </Text>
                    <Text
                      style={{ width: "8rem", textAlign: "right" }}
                      s="1.125rem"
                      lh="1.687rem"
                    >
                      Tempo médio
                    </Text>
                  </div>
                  <div>
                    <div className="flex align-items-center gap-2">
                      <Text
                        style={{ width: "6rem", textAlign: "left" }}
                        lh="2rem"
                      >
                        Sem dados
                      </Text>
                    </div>
                    <Text style={{ width: "6rem" }} lh="2rem">
                      0
                    </Text>
                    <Text
                      style={{ width: "8rem", textAlign: "right" }}
                      lh="2rem"
                    >
                      0 min.
                    </Text>
                  </div>
                </TableText>
              </HalfChartContainer>
            )}

            {dashboardData?.targetsServices &&
            dashboardData.targetsServices.length > 0 ? (
              <HalfChartContainer>
                <TitleLine>
                  <div className="flex gap-2">
                    <img src={Actions} />
                    <Text
                      color="#0A043C"
                      s="1.125rem"
                      weight={400}
                      lh="1.25rem"
                    >
                      Objetivo do atendimento
                    </Text>
                  </div>
                </TitleLine>
                <div className="w-20rem h-18rem mx-auto">
                  <Doughnut data={dataDoughnut} />
                </div>
                <TableText>
                  <div className="flex flex-row w-full">
                    <Text
                      style={{ width: "6rem", textAlign: "left" }}
                      s="1.125rem"
                      lh="1.687rem"
                    >
                      Omnichannel
                    </Text>
                    <Text style={{ width: "6rem" }} s="1.125rem" lh="1.687rem">
                      Quantidade
                    </Text>
                    <Text
                      style={{ width: "8rem", textAlign: "right" }}
                      s="1.125rem"
                      lh="1.687rem"
                    >
                      Tempo médio
                    </Text>
                  </div>
                  {dashboardData.targetsServices.map((el, idx) => (
                    <div key={idx}>
                      <div className="flex align-items-center gap-2">
                        <SpanCollor
                          color={dataDoughnut.datasets[0].backgroundColor[idx]}
                        />
                        <Text
                          style={{ width: "6rem", textAlign: "left" }}
                          lh="2rem"
                        >
                          {el.channel || "Não informado"}
                        </Text>
                      </div>
                      <Text style={{ width: "6rem" }} lh="2rem">
                        {el.count}
                      </Text>
                      <Text
                        style={{ width: "8rem", textAlign: "right" }}
                        lh="2rem"
                      >
                        {el.averageTime}min.
                      </Text>
                    </div>
                  ))}
                </TableText>
              </HalfChartContainer>
            ) : (
              <HalfChartContainer>
                <TitleLine>
                  <div className="flex gap-2">
                    <img src={Actions} />
                    <Text
                      color="#0A043C"
                      s="1.125rem"
                      weight={400}
                      lh="1.25rem"
                    >
                      Objetivo do atendimento
                    </Text>
                  </div>
                </TitleLine>
                <div
                  className="w-20rem h-18rem mx-auto"
                  style={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <Text s="1.125rem" lh="1.687rem" color="#0A043C">
                    Sem dados disponíveis no período selecionado
                  </Text>
                </div>
                <TableText>
                  <div className="flex flex-row w-full">
                    <Text
                      style={{ width: "6rem", textAlign: "left" }}
                      s="1.125rem"
                      lh="1.687rem"
                    >
                      Omnichannel
                    </Text>
                    <Text style={{ width: "6rem" }} s="1.125rem" lh="1.687rem">
                      Quantidade
                    </Text>
                    <Text
                      style={{ width: "8rem", textAlign: "right" }}
                      s="1.125rem"
                      lh="1.687rem"
                    >
                      Tempo médio
                    </Text>
                  </div>
                  <div>
                    <div className="flex align-items-center gap-2">
                      <Text
                        style={{ width: "6rem", textAlign: "left" }}
                        lh="2rem"
                      >
                        Sem dados
                      </Text>
                    </div>
                    <Text style={{ width: "6rem" }} lh="2rem">
                      0
                    </Text>
                    <Text
                      style={{ width: "8rem", textAlign: "right" }}
                      lh="2rem"
                    >
                      0 min.
                    </Text>
                  </div>
                </TableText>
              </HalfChartContainer>
            )}
          </div>
        )}
        {dashboardData?.servicesByDayHistory?.length ? (
          <FullChartContainer>
            <TitleLine>
              <div className="flex gap-2">
                <img src={HistoryGraph} />
                <Text color="#0A043C" s="1.125rem" weight={400} lh="1.25rem">
                  Histórico de atendimento
                </Text>
              </div>
            </TitleLine>
            <div style={{ width: "60rem" }} className="h-30rem mx-auto">
              <Line
                options={optionsLineChart}
                onClick={onClick}
                //@ts-ignore
                ref={chartRef}
                data={dataLineChart}
              />
            </div>
          </FullChartContainer>
        ) : null}
        {dataLineChartByHour?.datasets[0]?.data?.length ? (
          <FullChartContainer>
            <div style={{ width: "60rem" }} className="mx-auto h-30rem">
              <Bar options={optionsLineChart} data={dataLineChartByHour} />
            </div>
          </FullChartContainer>
        ) : null}

        <FullChartContainer>
          <TitleLine>
            <div className="flex gap-2">
              <Text color="#0A043C" s="1.125rem" weight={400} lh="1.25rem">
                Atendimento Robô x Humano
              </Text>
            </div>
          </TitleLine>
          <div style={{ width: "60rem" }} className="mx-auto h-30rem">
            <Bar options={optionsStackBarChart} data={dataStack} />
          </div>
        </FullChartContainer>
      </ContainerTransparent>
    </Container>
  );
};
