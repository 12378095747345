import styled from "@emotion/styled";

export const ContainerLeftModal = styled.div`
  position: absolute;
  background-color: #fff;
  height: 694px;
  top: 0rem;
  width: calc(50% + 9%);
  position: relative;
  height: 100%;
  background-color: white;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ContainerRightModal = styled.div`
  width: calc(50% - 9%);
  position: relative;
  height: 100%;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MyLink = styled.a`
  align-items: center;
  justify-content: center;
  padding: 10px 14px 10px 10px;
  text-decoration: none;
  background: #1877f2;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  gap: 0.5rem;
  letter-spacing: -0.02em;
  margin-bottom: 0.3rem;
  border: 1px solid #d2d2d2;
  box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
  border-radius: 4px;
  :hover {
    cursor: pointer;
    color: #fff;
    background-color: #7887ab;
    transition: 0.2s ease-in-out;
  }
  padding: 0.7rem;
  border: 2px solid #acd0f6;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  display: flex;
  color: #fff;
  margin-top: 1.1rem;
  height: 2.5rem;
  width: 12.9375rem;
`;

export const ContainerLogged = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13.125rem;
  height: 13.125rem;
  background-color: #fff;
  border-radius: 1.25rem;
`;
export const Green = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 11.1875rem;
  height: 11.1875rem;
  background-color: rgba(0, 230, 119, 0.1);
  border-radius: 50%;
`;
export const Green2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 50%;
  width: 9.0625rem;
  height: 9.0625rem;
  background-color: rgba(0, 230, 119, 0.2);
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-color: transparent;
  opacity: 1;
  /* border: 1px solid #02CDAD; */
`;

export const PhotoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #02cdad;
  border-radius: 50%;
  background-color: transparent;
  color: #0a043c;
  width: 3.125rem;
  height: 3.125rem;
  padding: 0.125rem;
  overflow: hidden;
`;

export const Tag = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.6875rem;
  height: 1rem;
  border-radius: 1.25rem;
  background-color: #fff;
  color: #04a456;
  margin-bottom: 1rem;
`;
interface ILoadingRotateIcon {
  rotateIcon: boolean;
}
export const LoadingRotateIcon = styled.img<ILoadingRotateIcon>`
  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  -webkit-animation: ${(props) =>
    props.rotateIcon ? "2s linear infinite" : ""};
  -moz-animation: ${(props) =>
    props.rotateIcon ? "rotating 2s linear infinite" : ""};
  -ms-animation: ${(props) =>
    props.rotateIcon ? "rotating 2s linear infinite" : ""};
  -o-animation: ${(props) =>
    props.rotateIcon ? "rotating 2s linear infinite" : ""};
  animation: ${(props) =>
    props.rotateIcon ? "rotating 2s linear infinite" : ""};
  &:hover {
    cursor: pointer;
    transition: all ease-in-out 250ms;
  }
`;

interface ILoadingDiv {
  show: boolean;
}

export const LoadingDiv = styled.div<ILoadingDiv>`
  display: ${(props) => (props.show ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  background-color: rgba(127, 126, 126, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 12;
  top: 0;
  left: 0;
`;
export const BackgrounIcon = styled.div`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 12;
`;
